import React from "react";
import { Link } from "react-router-dom";

const AllRoutes = () => {
  return (
    <nav className="bg-gray-800 p-4">
      <ul className="flex flex-wrap justify-center space-x-4">
        <li>
          <Link
            to="/"
            className="text-white hover:text-gray-300 transition duration-300"
          >
            Home
          </Link>
        </li>
        <li>
          <Link
            to="/search"
            className="text-white hover:text-gray-300 transition duration-300"
          >
            Watchlist
          </Link>
        </li>
        <li>
          <Link
            to="/terminal"
            className="text-white hover:text-gray-300 transition duration-300"
          >
            Chart
          </Link>
        </li>
        <li>
          <Link
            to="/trade"
            className="text-white hover:text-gray-300 transition duration-300"
          >
            Trade
          </Link>
        </li>
        <li>
          <Link
            to="/about"
            className="text-white hover:text-gray-300 transition duration-300"
          >
            About
          </Link>
        </li>
        <li>
          <Link
            to="/login"
            className="text-white hover:text-gray-300 transition duration-300"
          >
            Log In
          </Link>
        </li>
        <li>
          <Link
            to="/signup"
            className="text-white hover:text-gray-300 transition duration-300"
          >
            Sign Up
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default AllRoutes;
