import React, { useState } from "react";
import styles from "../closedPositions/ExitPosition.module.css";
import { formatTimeToSeconds, mapTypeToString } from "../../../helpers";
import TradeCard from "../tradeCard/TradeCard";
import { useSelector } from "react-redux";

const DealsBox = () => {
  const [showSubContainerIndex, setShowSubContainerIndex] = useState(null);
  const { filterDealData } = useSelector((state) => state.filterDataScreen);

  const handleOnClick = (index) => {
    setShowSubContainerIndex(index === showSubContainerIndex ? null : index);
  };

  return (
    <div>
      {!filterDealData && <p className={styles.error}>Loading...</p>}
      {filterDealData && (
        <TradeCard data={calcDealsAggregate(filterDealData)} />
      )}

      {filterDealData?.length < 1 && (
        <p className={styles.error}>No deals available!</p>
      )}

      <div className={styles.scroll}>
        {filterDealData?.map((data, index) => (
          <div className={styles.container} key={data._id}>
            <div className={styles.main} onClick={() => handleOnClick(index)}>
              <div className={styles.flexBox}>
                <div className={styles.gap}>
                  <span>{data.symbol}</span>
                  <span
                    className={data.type === 1 ? styles.rising : styles.falling}
                  >
                    {mapTypeToString(data.type)},
                  </span>
                  <span
                    className={data.type === 1 ? styles.rising : styles.falling}
                  >
                    {data.action === 1 ? "in" : "out"}
                  </span>
                </div>
                <div>{formatTimeToSeconds(data.createdAt)}</div>
              </div>
              <div className={styles.flexBox}>
                <div className={styles.gap}>
                  <span>{data.volume}</span>
                  <span>at </span>
                  <span>{data.price}</span>
                </div>
                <div
                  className={data.profit > 0 ? styles.rising : styles.falling}
                >
                  {data.profit.toFixed(2)}
                </div>
              </div>
            </div>
            {showSubContainerIndex === index && (
              <div className={styles.subContainer}>
                <div className={styles.flexBox}>
                  <div className={styles.gap}>
                    <span>Deal:</span>
                    <span>{data.ticket}</span>
                  </div>
                  <div className={styles.gap}>
                    <span>Swap:</span>
                    <span>{data.swap ? data.swap.toFixed(2) : data.swap}</span>
                  </div>
                </div>
                <div className={styles.flexBox}>
                  <div className={styles.gap}>
                    <span>Order:</span>
                    <span>{data.order}</span>
                  </div>
                  <div className={styles.gap}>
                    <span>Commission:</span>
                    <span>{-data.comission}</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DealsBox;

const calcDealsAggregate = (data) => {
  let profit = 0;
  let swap = 0;
  let commission = 0;

  for (let i = 0; i < data.length; i++) {
    profit += Number(data[i]?.profit);
    swap += Number(data[i]?.swap);
    commission -= Number(data[i]?.comission);
  }

  return {
    Profit: profit.toFixed(2),
    Swap: swap.toFixed(2),
    Commission: commission.toFixed(2),
  };
};
