import React from "react";
import SignUpForm from "./components/auth/signup/SignUp";
import Login from "./components/auth/login/LogIn";
import Home from "./components/home/Home";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SkyCapLogin from "./components/auth/login/SkyCapLogin";

import { useQuery } from "@tanstack/react-query";
import {
  getClosedPositions,
  getPositions,
  getSymbols,
  getUser,
  getUserDeals,
  getUserOrders,
  getWatchlistData,
} from "./api/queryFunctions";
import { setUserCredentials } from "./helpers";
import AboutSection from "./components/about/About";
import SearchPage from "./pages/SearchPage";
import TradeFormPage from "./pages/TradePage";
import WatchlistPage from "./pages/WatchlistPage";
import ChartPage from "./pages/ChartPage";
import AllRoutes from "./pages";
import RemoveSymbolsPage from "./pages/RemoveSymbolsPage";
import MobileHistory from "./components/history/mobile/MobileHistory";
import Account from "./components/account/Account";
import MobileTrade from "./components/home/walletplusPositions/MobileTrade";

import JournalPage from "./pages/JournalPage";
import DepositPage from "./pages/DepositPage";
import WithdrawPage from "./pages/WithdrawPage";
import ManualQuotes from "./components/manualQuotes/ManualQuotes";
import UserList from "./components/account/UserList";


const App = () => {
  

  if (JSON.parse(localStorage.getItem("AUTH-CRED")) !== null) {
    setUserCredentials(JSON.parse(localStorage.getItem("AUTH-CRED")));
    localStorage.removeItem("AUTH-CRED");
  }

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUpForm />} />
        <Route path="/deposit" element={<DepositPage />} />
        <Route path="/withdraw" element={<WithdrawPage />} />

        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutSection />} />
        <Route path="/search" element={<SearchPage />} />
        <Route path="/trade" element={<TradeFormPage />} />
        <Route path="/watchlist" element={<WatchlistPage />} />
        <Route path="/terminal" element={<ChartPage />} />
        <Route path="/all" element={<AllRoutes />} />
        <Route path="/remove-symbols" element={<RemoveSymbolsPage />} />
        <Route path="/history" element={<MobileHistory />} />
        <Route path="/account" element={<Account />} />
        <Route path="/trades" element={<MobileTrade />} />
        <Route path="/journal" element={<JournalPage />} />
        <Route path="/slogin" element={<SkyCapLogin />} />
        <Route path="/users" element={<UserList />} />
        <Route path="/mq" element={<ManualQuotes />} />
      </Routes>
      {/* {window.innerWidth < 768 && <BottomTab />} */}
    </Router>
  );
};

export default App;
