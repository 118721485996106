import React, { useState } from "react";
import TradeForm from "../components/trade/tradeForm/TradeForm";
import { IoIosArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { RiExchangeDollarLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { changeSymbol } from "../store/chartSlice";

const TradeFormPage = () => {
  const queryClient = useQueryClient();
  const watchlistData = queryClient.getQueryData(["getWatchlistData"]);
  const [showSymbols, setShowSymbols] = useState(false);
  const { symbol: sym } = useSelector((state) => state.chart);
  const [symbol, setSymbol] = useState(sym);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const handleOnClickBack = () => {
    navigate("/");
  };

  const handleSymbolChange = (symbol) => {
    dispatch(changeSymbol(symbol));
    setSymbol(symbol);
    setShowSymbols(false);
  };
  return (
    <div className=" ">
      <div className="flex items-center justify-between mb-2 fixed top-0 z-10 bg-white w-full p-3 border-b border-gray-100">
        <div className="flex gap-2 items-center ">
          <IoIosArrowRoundBack size={30} onClick={handleOnClickBack} />
          <div className="flex flex-col items-start ml-2  ">
            <span className="pt-1  text-base font-semibold">New Order</span>
            <span className="text-xs text-blue-500 font-semibold">
              {" "}
              {symbol}
            </span>
          </div>
        </div>
        <RiExchangeDollarLine
          size={25}
          onClick={() => setShowSymbols(!showSymbols)}
        />
      </div>
      {showSymbols && (
        <div>
          <div
            id="dropdownDivider"
            className="absolute right-2  z-10 bg-white divide-y divide-gray-600 rounded-lg shadow w-32 dark:bg-gray-700 dark:divide-gray-600"
          >
            {watchlistData?.length ? (
              <ul
                className="py-2 text-sm text-gray-700 dark:text-gray-200"
                aria-labelledby="dropdownDividerButton"
              >
                <li
                  className="border-b border-gray-300 last:border-b-0 dark:border-gray-600"
                  onClick={() => setShowSymbols(!showSymbols)}
                ></li>
                {watchlistData.map((s) => (
                  <li
                    key={s.symbol}
                    className="border-b border-gray-300 last:border-b-0 dark:border-gray-600"
                  >
                    <button
                      onClick={() => handleSymbolChange(s.symbol)}
                      className="w-full text-left px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      {s.symbol}
                    </button>
                  </li>
                ))}
              </ul>
            ) : (
              <div className="py-2 px-4 text-sm text-gray-700 dark:text-gray-200">
                No symbols found
              </div>
            )}
          </div>
        </div>
      )}
      <div className="mt-16 ">
        <TradeForm />
      </div>
    </div>
  );
};

export default TradeFormPage;
