import React, { memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  filterSymbol,
  filterNameSelect,
  filterDealSymbol,
  filterOrderSymbol,
  setSymbolPosition,
  setSymbolDeal,
  setSymbolOrder,
} from "../../../store/filterDataMobScreenSlice/FilterHistoryScreen";
import { useQueryClient } from "@tanstack/react-query";

const as = "All Symbols";

const FilterSymbol = ({ togglehandler }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const exitPositionData = queryClient.getQueryData(["closedPositions"]);
  const positionData = queryClient.getQueryData(["deals"]);
  const userOrdersData = queryClient.getQueryData(["orders"]);

  const mode = useSelector((state) => state.mode.mode);
  const symbolList = useSelector((state) => state.filterDataScreen.symbolList);

  const handleSymbolFilter = (symbol) => {
    dispatch(filterNameSelect(symbol));

    if (symbol === "allSymbol") {
      dispatch(filterSymbol(exitPositionData));
      dispatch(setSymbolPosition(as));
      dispatch(setSymbolDeal(as));
      dispatch(setSymbolOrder(as));
      dispatch(filterDealSymbol(positionData));
      dispatch(filterOrderSymbol(userOrdersData));
    } else if (symbol !== "") {
      if (mode === "closedPosition") {
        dispatch(
          filterSymbol(exitPositionData.filter((res) => res.symbol === symbol))
        );
        dispatch(setSymbolPosition(symbol));
      }
      if (mode === "deals") {
        dispatch(
          filterDealSymbol(positionData.filter((res) => res.symbol === symbol))
        );
        dispatch(setSymbolDeal(symbol));
      }

      if (mode === "orders") {
        dispatch(
          filterOrderSymbol(
            userOrdersData.filter((res) => res.symbol === symbol)
          )
        );
        dispatch(setSymbolOrder(symbol));
      }
    }
  };

  return (
    <div>
      {
        <div
          id="dropdownDivider"
          className="absolute right-0 mt-2 z-10 bg-white divide-y divide-gray-600 rounded-lg shadow w-32 dark:bg-gray-700 dark:divide-gray-600"
        >
          {symbolList?.length ? (
            <ul
              className="py-2 text-sm text-gray-700 dark:text-gray-200"
              aria-labelledby="dropdownDividerButton"
            >
              <li
                className="border-b border-gray-300 last:border-b-0 dark:border-gray-600"
                onClick={togglehandler}
              >
                <button
                  onClick={() => handleSymbolFilter("allSymbol")}
                  className="w-full text-left px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                >
                  All Symbols
                </button>
              </li>
              {symbolList.map((sym) => (
                <li
                  key={sym}
                  className="border-b border-gray-300 last:border-b-0 dark:border-gray-600"
                  onClick={togglehandler}
                >
                  <button
                    onClick={() => handleSymbolFilter(sym)}
                    className="w-full text-left px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                  >
                    {sym}
                  </button>
                </li>
              ))}
            </ul>
          ) : (
            <div className="py-2 px-4 text-sm text-gray-700 dark:text-gray-200">
              No symbols found
            </div>
          )}
        </div>
      }
    </div>
  );
};

export default memo(FilterSymbol);
