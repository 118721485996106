import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FaPlus, FaMinus } from "react-icons/fa";
import { IoCheckmarkDoneCircleSharp } from "react-icons/io5";
import { CgDanger } from "react-icons/cg";
import socket from "../../../socket";
import { refreshComponent } from "../../../store/modeSlice";
import {
  countDecimalPlaces,
  divideByDecimalPlaces,
  getAuthToken,
  mapTypeToString,
} from "../../../helpers";
import { changeShowTradeForm } from "../../../store/watchlistSlice";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { changeTab } from "../../../store/mobileViewSlice";

const InputWithButtons = React.memo(
  ({ name, value, onChange, onDecrease, onIncrease, placeholder, onFocus }) => (
    <div className="flex items-center border border-gray-300 rounded bg-white">
      <button
        type="button"
        className="px-2 py-1 text-gray-500"
        onClick={onDecrease}
      >
        <FaMinus size={12} />
      </button>
      <input
        type="number"
        name={name}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        placeholder={placeholder}
        className="w-full p-2 text-center border-none outline-none"
      />
      <button
        type="button"
        className="px-2 py-1 text-gray-500"
        onClick={onIncrease}
      >
        <FaPlus size={12} />
      </button>
    </div>
  )
);

const SelectInput = React.memo(({ name, value, onChange, options }) => (
  <select
    name={name}
    value={value}
    onChange={onChange}
    className="w-full p-2 border border-gray-300 rounded bg-white outline-none"
  >
    {options.map((option) => (
      <option key={option.value} value={option.value}>
        {option.label}
      </option>
    ))}
  </select>
));

const TradeResponse = React.memo(({ response, onClose, symbolDigits }) => (
  <div className="bg-white rounded-lg shadow-lg overflow-hidden">
    {response?.valid ? (
      <div className="p-6">
        <div className="flex items-center justify-center mb-4">
          <IoCheckmarkDoneCircleSharp className="text-green-500" size={24} />
          <h2 className="ml-2 text-base font-bold text-gray-800">
            Trade Confirmed
          </h2>
        </div>
        <div className="space-y-2 text-center">
          <InfoRow label="Symbol" value={response?.symbol} />
          <InfoRow
            label="Price"
            value={`${response?.price?.toFixed(symbolDigits[response.symbol])}`}
          />
          <InfoRow label="Ticket" value={`#${response?.ticket}`} />
          <InfoRow label="Type" value={mapTypeToString(response?.type)} />
          <InfoRow label="Volume" value={response?.volume} />
        </div>
      </div>
    ) : (
      <div className="p-6">
        <div className="flex items-center justify-center mb-4">
          <CgDanger className="text-red-500" size={24} />
          <h2 className="ml-2 text-xl font-bold text-gray-800">Trade Failed</h2>
        </div>
        <p className="text-center font-semibold text-gray-600">
          {response?.message}
        </p>
      </div>
    )}
    <div className="px-6 py-4 bg-gray-50 border-t border-gray-200">
      <button
        className="w-full bg-blue-600 text-white rounded px-4 py-2 font-semibold hover:bg-blue-700 transition duration-300"
        onClick={onClose}
      >
        Close
      </button>
    </div>
  </div>
));

const InfoRow = React.memo(({ label, value }) => (
  <div className="flex justify-between items-center text-sm">
    <span className="text-gray-600">{label}:</span>
    <span className="font-semibold text-gray-800">{value}</span>
  </div>
));

const TradeForm = () => {
  const { ask, bid } = useSelector((state) => state.chart.bidAsk);
  const symbolDigits = useSelector((state) => state.symbolDigitCount);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { symbol } = useSelector((state) => state.chart);
  const dispatch = useDispatch();
  const [focusedField, setFocusedField] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isBuyDisabled, setIsBuyDisabled] = useState(false);
  const [isSellDisabled, setIsSellDisabled] = useState(false);
  const [showResponse, setShowResponse] = useState(false);
  const [response, setResponse] = useState(null);
  const [formData, setFormData] = useState({
    executionType: "1",
    volume: 0.01,
    stopLoss: "",
    takeProfit: "",
    comment: "",
    price: "",
    limit: "",
    expiration: "gtc",
    date: "",
  });
  const [isProcessing, setIsProcessing] = useState(false);

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setIsDisabled(false);
  }, []);

  const handleFocus = useCallback((e) => {
    setFocusedField(e.target.name);
  }, []);

  const handleDecrease = useCallback((name) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: (
        parseFloat(prevData[name]) - divideByDecimalPlaces(prevData[name])
      ).toFixed(countDecimalPlaces(prevData[name])),
    }));
  }, []);

  const handleIncrease = useCallback((name) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: (
        parseFloat(prevData[name]) + divideByDecimalPlaces(prevData[name])
      ).toFixed(countDecimalPlaces(prevData[name])),
    }));
  }, []);

  const createOrder = useCallback(
    (type) => ({
      symbol,
      type,
      qty: formData.volume,
      exc_type: formData.executionType == "1" ? 0 : Number(formData.executionType),
      price: formData.price || "",
      limit: formData.limit || "",
      stop_loss: formData.stopLoss || "",
      take_profit: formData.takeProfit || "",
      expiration:
        formData.executionType === "6" || formData.executionType === "7"
          ? formData.date
          : "",
      token: getAuthToken(),
    }),
    [formData, symbol]
  );

  const handleSubmit = useCallback(
    (type) => {
      setIsProcessing(true);
      const order = createOrder(type === "buy" ? 1 : 0);
  
      socket.emit("newOrder", JSON.stringify(order));
  
      let timeoutId = setTimeout(() => {
        dispatch(changeTab("wallet"));
        setIsProcessing(false);
        if(window.innerWidth < 768) {
          navigate("/");
        }
      }, 2000);
  
      socket.once("getOrder", (newDataPoint) => {
        clearTimeout(timeoutId);
        const getOrder = newDataPoint?.getOrder;
        setResponse({
          symbol: getOrder?.position?.symbol,
          price: getOrder?.position?.price,
          ticket: getOrder?.position?.ticket,
          type: getOrder?.position?.type,
          volume: getOrder?.position?.volume,
          valid: getOrder?.valid,
          message: getOrder?.message,
        });
        setIsProcessing(false);
        setShowResponse(true);
        if (getOrder.valid) {
          queryClient.setQueryData(["positions"], oldData => ({...oldData, positions: [ ...oldData.positions , getOrder?.position]}));
          queryClient.setQueryData(["orders"], oldData => [...(oldData || []), getOrder?.order]);
          queryClient.setQueryData(["deals"], oldData => [...(oldData || []), getOrder?.deal]);
        }
  
        if (getOrder?.valid) {
          toast.success(`Placed: ${getOrder?.message}`);
        } else {
          toast.error(`Placed: ${getOrder?.message}`);
        }
      });
    },
    [createOrder, queryClient, dispatch]
  );
  

  const handlePlaceOrder = useCallback(() => {
    setIsProcessing(true);
    const order = createOrder(Number(formData.executionType));
   
    socket.emit(
      "newOrder",
      JSON.stringify({
        ...order,
        reason: window.innerWidth < 768 ? "Mobile" : "Web",
      })
    );

    let timeoutId = setTimeout(() => {
      dispatch(changeTab("wallet"));
      setIsProcessing(false);
      if(window.innerWidth < 768) {
        navigate("/");
      }
    }, 2000);
    
    socket.once("getOrder", (newDataPoint) => {
      clearTimeout(timeoutId);
      const getOrder = newDataPoint?.getOrder;
      setResponse({
        symbol: getOrder?.position?.symbol,
        price: getOrder?.position?.price,
        ticket: getOrder?.position?.ticket,
        type: getOrder?.position?.type,
        volume: getOrder?.position?.volume,
        valid: getOrder?.valid,
        message: getOrder?.message,
      });
      setShowResponse(true);
      setIsProcessing(false);
     

      if (getOrder?.valid) {
        queryClient.setQueryData(["positions"], oldData => ({...oldData, positions: [ ...oldData.positions , getOrder?.position]}));
        queryClient.setQueryData(["orders"], oldData => [...oldData, getOrder?.order])
        queryClient.setQueryData(["deals"], oldData => [...oldData, getOrder?.deal])
       
      }

      if (getOrder.valid) {
        
        toast.success(`Placed: ${getOrder?.message}`);
      } else {
        toast.error(`Placed: ${getOrder?.message}`);
      }
    });
  }, [createOrder, formData.executionType, queryClient]);

  const validateForm = useCallback(() => {
    const {
      executionType: exc_type,
      price,
      limit,
      stopLoss,
      takeProfit,
    } = formData;

    let isValid = true;
    let isBuyValid = true;
    let isSellValid = true;

    const conditions = {
      1: () => {
        if (stopLoss && parseFloat(stopLoss) >= bid) isBuyValid = false;
        if (takeProfit && parseFloat(takeProfit) <= ask) isBuyValid = false;
        if (stopLoss && parseFloat(stopLoss) <= ask) isSellValid = false;
        if (takeProfit && parseFloat(takeProfit) >= bid) isSellValid = false;
      },
      2: () => {
        if (stopLoss > 0 && stopLoss > bid) isValid = false;
        if (takeProfit > 0 && takeProfit < bid) isValid = false;
        if (price > bid) isValid = false;
      },
      3: () => {
        if (stopLoss > 0 && stopLoss < bid) isValid = false;
        if (takeProfit > 0 && takeProfit > bid) isValid = false;
        if (price < bid) isValid = false;
      },
      4: () => {
        if (stopLoss > 0 && stopLoss > bid) isValid = false;
        if (takeProfit > 0 && takeProfit < bid) isValid = false;
        if (price < bid) isValid = false;
      },
      5: () => {
        if (stopLoss > 0 && stopLoss < bid) isValid = false;
        if (takeProfit > 0 && takeProfit > bid) isValid = false;
        if (price > bid) isValid = false;
      },
      6: () => {
        if (stopLoss > 0 && stopLoss > bid) isValid = false;
        if (takeProfit > 0 && takeProfit < bid) isValid = false;
        if (price < bid) isValid = false;
        if (limit > price) isValid = false;
      },
      7: () => {
        if (stopLoss > 0 && stopLoss < bid) isValid = false;
        if (takeProfit > 0 && takeProfit > bid) isValid = false;
        if (price > bid) isValid = false;
        if (limit < price) isValid = false;
      },
    };

    if (conditions[exc_type]) {
      conditions[exc_type]();
    }

    if (exc_type === "1") {
      setIsBuyDisabled(!isBuyValid);
      setIsSellDisabled(!isSellValid);
    } else {
      setIsDisabled(!isValid);
    }
  }, [formData, bid]);

  useEffect(() => {
    if (focusedField) {
      setFormData((prevData) => ({
        ...prevData,
        [focusedField]: bid,
      }));
      setFocusedField(null);
    }
  }, [symbol, focusedField]);
  useEffect(() => {
    dispatch(refreshComponent());
  }, [dispatch]);

  useEffect(() => {
    validateForm();
  }, [validateForm]);

  const executionTypeOptions = useMemo(
    () => [
      { value: "1", label: "Instant" },
      { value: "2", label: "Buy Limit" },
      { value: "3", label: "Sell Limit" },
      { value: "4", label: "Buy Stop" },
      { value: "5", label: "Sell Stop" },
      { value: "6", label: "Buy Stop Limit" },
      { value: "7", label: "Sell Stop Limit" },
    ],
    []
  );

  const expirationOptions = useMemo(
    () => [
      { value: "gtc", label: "GTC" },
      { value: "today", label: "Today" },
      { value: "specified", label: "Specified" },
      { value: "specified-day", label: "Specified Day" },
    ],
    []
  );

  const renderTradeForm = () => (
    <form className="space-y-4 text-sm">
      <div className="p-2 bg-blue-50 rounded-sm font-bold flex justify-between text-zinc-800">
        <span>{symbol}</span>
      </div>
      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block text-xs font-medium text-gray-700 mb-1">
            Execution Type
          </label>
          <SelectInput
            name="executionType"
            value={formData.executionType}
            onChange={handleChange}
            options={executionTypeOptions}
          />
        </div>
        <div>
          <label className="block text-xs font-medium text-gray-700 mb-1">
            Volume{" "}
            <span className="text-blue-500">
              ({(formData.volume * 100000).toFixed(2)})
            </span>
          </label>
          <InputWithButtons
            name="volume"
            value={formData.volume}
            onChange={handleChange}
            onDecrease={() => handleDecrease("volume")}
            onIncrease={() => handleIncrease("volume")}
            placeholder="Volume"
          />
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block text-xs font-medium text-gray-700 mb-1">
            Stop Loss{" "}
            <span className="text-gray-500">({formData.stopLoss})</span>
          </label>
          <InputWithButtons
            name="stopLoss"
            value={formData.stopLoss}
            onChange={handleChange}
            onFocus={handleFocus}
            onDecrease={() => handleDecrease("stopLoss")}
            onIncrease={() => handleIncrease("stopLoss")}
            placeholder="Set S/L"
          />
        </div>
        <div>
          <label className="block text-xs font-medium text-gray-700 mb-1">
            Take Profit{" "}
            <span className="text-gray-500">({formData.takeProfit})</span>
          </label>
          <InputWithButtons
            name="takeProfit"
            value={formData.takeProfit}
            onChange={handleChange}
            onFocus={handleFocus}
            onDecrease={() => handleDecrease("takeProfit")}
            onIncrease={() => handleIncrease("takeProfit")}
            placeholder="Set T/P"
          />
        </div>
      </div>

      {formData.executionType !== "1" && (
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-xs font-medium text-gray-700 mb-1">
              Price <span className="text-gray-500">({formData.price})</span>
            </label>
            <InputWithButtons
              name="price"
              value={formData.price}
              onChange={handleChange}
              onFocus={handleFocus}
              onDecrease={() => handleDecrease("price")}
              onIncrease={() => handleIncrease("price")}
              placeholder="Price"
            />
          </div>
          {(formData.executionType === "6" ||
            formData.executionType === "7") && (
            <div>
              <label className="block text-xs font-medium text-gray-700 mb-1">
                Limit <span className="text-gray-500">({formData.limit})</span>
              </label>
              <InputWithButtons
                name="limit"
                value={formData.limit}
                onChange={handleChange}
                onFocus={handleFocus}
                onDecrease={() => handleDecrease("limit")}
                onIncrease={() => handleIncrease("limit")}
                placeholder="Set Limit"
              />
            </div>
          )}
        </div>
      )}

      {formData.executionType !== "1" && (
        <>
          <div>
            <label className="block text-xs font-medium text-gray-700 mb-1">
              Expiration
            </label>
            <SelectInput
              name="expiration"
              value={formData.expiration}
              onChange={handleChange}
              options={expirationOptions}
            />
          </div>
          {(formData.expiration === "specified" ||
            formData.expiration === "specified-day") && (
            <div>
              <label className="block text-xs font-medium text-gray-700 mb-1">
                Date
              </label>
              <input
                type="date"
                name="date"
                value={formData.date}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 rounded bg-white outline-none"
              />
            </div>
          )}
        </>
      )}

      <div>
        <label className="block text-xs font-medium text-gray-700 mb-1">
          Comment
        </label>
        <textarea
          rows="2"
          name="comment"
          value={formData.comment}
          onChange={handleChange}
          placeholder="Comment"
          className="w-full p-2 border border-gray-300 rounded bg-white resize-none outline-none"
        />
      </div>

      <div className="flex justify-between items-center text-sm text-blue-700 p-3 bg-blue-50 rounded">
        <div>Ask: {ask}</div>
        <div>Bid: {bid}</div>
      </div>

      {formData.executionType === "1" ? (
        <div className="grid grid-cols-2 gap-4">
          <button
            type="button"
            className="p-2 rounded cursor-pointer bg-green-500 text-white font-semibold outline-none hover:bg-green-600 transition duration-300 disabled:bg-gray-400 disabled:cursor-not-allowed"
            disabled={isBuyDisabled || isProcessing || !ask}
            onClick={() => handleSubmit("buy")}
          >
            Buy
          </button>
          <button
            type="button"
            className="p-2 rounded cursor-pointer bg-red-500 text-white font-semibold outline-none hover:bg-red-600 transition duration-300 disabled:bg-gray-400 disabled:cursor-not-allowed"
            disabled={isSellDisabled || isProcessing || !bid}
            onClick={() => handleSubmit("sell")}
          >
            Sell
          </button>
        </div>
      ) : (
        <button
          type="button"
          onClick={handlePlaceOrder}
          className={`w-full bg-blue-500 rounded p-2 text-white font-semibold transition duration-300 
            ${
              isDisabled
                ? "bg-gray-400 cursor-not-allowed"
                : "hover:bg-blue-600"
            }`}
          disabled={isDisabled || isProcessing || !bid}
        >
          Place Order
        </button>
      )}
    </form>
  );

  return (
    <div className="bg-white p-4 rounded-lg shadow-md">
      {showResponse ? (
        <TradeResponse
          response={response ?? "Market Closed Today"}
          onClose={() => {
            dispatch(changeShowTradeForm(false));
            dispatch(changeTab("wallet"))
            if (window.innerWidth < 768) {
              navigate("/");
            }
          }}
          symbolDigits={symbolDigits}
        />
      ) : (
        renderTradeForm()
      )}
    </div>
  );
};

export default TradeForm;
