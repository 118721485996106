import React from "react";
import TradingViewChart from "../components/ATVC/TVC";

const App = () => {
  return (
    <div className="h-full   bg-gray-100 ">
      <TradingViewChart />
    </div>
  );
};

export default App;
