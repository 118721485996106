import React, { useState } from "react";
import WatchList from "../components/watchlist_search/watchlist/WatchList";
import SideBarMenu from "../components/navbars/menuSidebar/SideBarMob";
import { IoMdMenu } from "react-icons/io";
import { AiOutlinePlus } from "react-icons/ai";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { Link } from "react-router-dom";
import SymbolDelete from "../components/watchlist_search/symbolDelete/SymbolDelete";

const WatchlistPage = () => {
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const [isSymbolDeleteVisible, setIsSymbolDeleteVisible] = useState(false);

  const handleSideMenuBar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  const toggleSymbolDelete = () => {
    setIsSymbolDeleteVisible(!isSymbolDeleteVisible);
  };

  return (
    <div className="">
      <SideBarMenu
        isVisible={isSidebarVisible}
        setIsVisible={setIsSidebarVisible}
      />
      <div className="flex justify-between pb-3 pt-5 fixed top-0 left-0 w-full bg-white z-10">
        <div className="ml-6 flex" onClick={handleSideMenuBar}>
          <IoMdMenu size={25} />
          <div className="flex flex-col ml-2">
            <div className="text-sm">Quotes</div>
          </div>
        </div>
        <div className="flex flex-row">
          <div className="relative inline-block text-left">
            <Link to="/search" className="ml-6 flex cursor-pointer">
              <AiOutlinePlus size={25} />
            </Link>
          </div>
          <div className="relative inline-block text-left">
            <div
              className="ml-6 flex cursor-pointer pr-5"
              onClick={toggleSymbolDelete}
            >
              <MdOutlineModeEditOutline size={25} />
            </div>
          </div>
        </div>
      </div>
      <WatchList />

      {isSymbolDeleteVisible && (
        <div className="absolute top-0 w-full bg-white z-10 shadow-sm transition-all duration-300 ease-in-out">
          <SymbolDelete handleClose={toggleSymbolDelete} />
        </div>
      )}
    </div>
  );
};

export default WatchlistPage;
