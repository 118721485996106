import React from "react";
import styles from "./VerticalNavbar.module.css";
import { changeMode } from "../../../store/modeSlice";
import { useDispatch } from "react-redux";
import { RxLockClosed } from "react-icons/rx";
import { IoReorderThreeOutline } from "react-icons/io5";
import { FaIdeal } from "react-icons/fa";
import { BsHourglassTop } from "react-icons/bs";
import { FaGripLinesVertical } from "react-icons/fa6";

import { Tooltip } from "react-tooltip";

const VerticalNavbar = () => {
  const dispatch = useDispatch();

  const items = [
    {
      name: "Running Positions",
      icon: <FaGripLinesVertical size={20} />,
      tooltipId: "runningPositions",
      tooltipContent: "Running Positions",
      tooltipPlace: "right",
      handler: () => {
        dispatch(changeMode("runningPositions"));
      },
    },
    {
      name: "Pending Positions",
      icon: <BsHourglassTop size={20} />,
      tooltipId: "pendingPositions",
      tooltipContent: "Pending Positions",
      tooltipPlace: "right",
      handler: () => {
        dispatch(changeMode("pendingPositions"));
      },
    },
    {
      name: "Closed Positions",
      icon: <RxLockClosed size={20} />,
      tooltipId: "closedPositions",
      tooltipContent: "Closed Positions",
      tooltipPlace: "right",
      handler: () => {
        dispatch(changeMode("closedPositions"));
      },
    },
    {
      name: "Orders",
      icon: <IoReorderThreeOutline size={20} />,
      tooltipId: "orders",
      tooltipContent: "Orders",
      tooltipPlace: "right",
      handler: () => {
        dispatch(changeMode("orders"));
      },
    },
    {
      name: "Deals",
      icon: <FaIdeal size={20} />,
      tooltipId: "deals",
      tooltipContent: "Deals",
      tooltipPlace: "right",
      handler: () => {
        dispatch(changeMode("deals"));
      },
    },
  ];

  return (
    <div
      className={`${styles.verticalNavbar} transition-all duration-300 ease-in-out w-14 border-t-[5px] border-gray-100 bg-white h-full`}
    >
      {window.innerWidth > 768 ? (
        <>
          {window.innerWidth > 768 ? (
            <>
              <Tooltip id="closedPositions" />
              <Tooltip id="orders" />
              <Tooltip id="deals" />
              <Tooltip id="runningPositions" />
              <Tooltip id="pendingPositions" />
            </>
          ) : null}
        </>
      ) : null}

      {items.map((item, index) => (
        <div
          key={index}
          className="p-2 pl-4 pr-4 flex items-center gap-2 w-full transition duration-200 ease-in-out cursor-pointer hover:bg-blueviolet hover:text-white hover:bg-violet-700 rounded-sm"
          onClick={item.handler}
          data-tooltip-id={item?.tooltipId}
          data-tooltip-content={item?.tooltipContent}
          data-tooltip-place={item?.tooltipPlace}
        >
          <span>{item?.icon}</span>
          {/* <span>{item.name}</span> */}
        </div>
      ))}
    </div>
  );
};

export default VerticalNavbar;
