// /* eslint-disable react-hooks/exhaustive-deps */
// import React, { useEffect, useState, useMemo } from "react";
// import { useSelector } from "react-redux";
// import socket from "../../socket";
// import { useQueryClient } from "@tanstack/react-query";

// const WalletInfo = () => {
//   const queryClient = useQueryClient();
//   const walletRefresh = useSelector((state) => state.app.walletRefresh);

//   const initialData = queryClient.getQueryData(["user"]);
//   const pos = queryClient.getQueryData(["positions"]);
//   const activePositions = pos?.positions?.filter((pos) => pos.status === 1);

//   const wallet = {
//     id: initialData?.user?._id,
//     name: initialData?.user?.name,
//     email: initialData?.user?.email,
//     balance: Number(initialData?.user?.balance).toFixed(2) ?? "0.00",
//     margin: parseFloat(pos?.totalMargin).toFixed(2),
//     credit: Number(pos?.credit).toFixed(2),
//     equity: parseFloat(Number(pos?.equity) + Number(pos?.credit)).toFixed(2),
//     freeMargin: parseFloat(Number(pos?.freeMargin) + Number(pos?.credit)).toFixed(2),
//     level: parseFloat(pos?.level).toFixed(2),
//     change: parseFloat(pos?.totalProfit).toFixed(2),
//   };

//   const [data, setData] = useState(wallet);

//   useEffect(() => {
//     setData(wallet);
//   }, [initialData]);

//   useEffect(() => {
//     if (!wallet.id) return;

//     let isPosDataEmitting = false;

//     const handlePosData = (newData) => {
//       isPosDataEmitting = true;
//       if (activePositions?.length && newData) {
//         setData({
//           ...wallet,
//           balance: Number(newData.balance).toFixed(2),
//           equity: Number(newData.equity).toFixed(2),
//           credit: Number(newData.credit).toFixed(2),
//           margin: Number(newData.margin).toFixed(2),
//           freeMargin: Number(newData.freeMargin).toFixed(2),
//           level: Number(newData.level).toFixed(2),
//           change: Number(newData.runningProfit).toFixed(2),
//         });
//       } else {
//         setData({...wallet });
//       }
//     };

//     socket.emit("userpositions", JSON.stringify({ userId: wallet.id }));
//     socket.on("posData", handlePosData);

   
//     const timeoutId = setTimeout(() => {
//       if (!isPosDataEmitting) {
//         setData({
//           ...wallet,
//           margin: parseFloat(pos.totalMargin).toFixed(2),
//           equity: parseFloat(Number(pos.equity) + Number(wallet.credit)).toFixed(2),
//           freeMargin: parseFloat(Number(pos.freeMargin) + Number(wallet.credit)).toFixed(2),
//           level: parseFloat(pos.level).toFixed(2),
//           change: parseFloat(pos.totalProfit).toFixed(2),
//         });
//       }
//     }, 5000);

//     return () => {
//       socket.off("posData", handlePosData);
//       clearTimeout(timeoutId);
//     };
//   }, [wallet, walletRefresh, queryClient, pos]);

//   const renderWalletItem = (label, value) => (
//     <div
//       key={label}
//       className="flex justify-between gap-2 md:text-xs text-base font-bold"
//     >
//       <span>{label}:</span>
//       <span>{value}</span>
//     </div>
//   );

//   const walletItems = [
//     { label: "Balance", value: data.balance },
//     { label: "Equity", value: data.equity },
//     { label: "Credit", value: data.credit },
//     { label: "Margin", value: data.margin },
//     { label: "Free Margin", value: data.freeMargin },
//     { label: "Level", value: data.level },
//   ];

//   return (
//     <div className="bottom-0 md:pl-5 w-full md:bg-white flex flex-col md:flex-row gap-5 overflow-x-hidden border-t-2 border-gray-200 md:font-medium md:text-blue-600 md:absolute p-5 md:p-1 md:m-0 bg-gray-50 md:shadow-none text-zinc-700">
//       {walletItems.map((item) => renderWalletItem(item.label, item.value))}
//       <div
//         className={`text-center font-bold md:text-xs  ${
//           Number(data.change) < 0 ? "text-red-600" : "text-green-700"
//         }`}
//       >
//         {data.change ? `${data.change } USD` : `0.00 USD`}
//       </div>
//     </div>
//   );
// };

// export default WalletInfo;
import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import socket from "../../socket";
import { useQueryClient } from "@tanstack/react-query";

const WALLET_ITEMS = [
  { label: "Balance", key: "balance" },
  { label: "Equity", key: "equity" },
  { label: "Credit", key: "credit" },
  { label: "Margin", key: "margin" },
  { label: "Free Margin", key: "freeMargin" },
  { label: "Level", key: "level" },
];

const formatNumber = (num) => (num !== undefined ? Number(num).toFixed(2) : "0.00");

const WalletInfo = () => {
  const queryClient = useQueryClient();
  const walletRefresh = useSelector((state) => state.app.walletRefresh);
  
  const initialData = queryClient.getQueryData(["user"]);
  const positions = queryClient.getQueryData(["positions"]);
  
  const activePositions = positions?.positions?.filter((pos) => pos.status === 1);
  
  const profit = useMemo(() => {
    return activePositions?.reduce((acc, pos) => acc + Number(pos.profit), 0) || 0;
  }, [activePositions]);

  const wallet = useMemo(() => {
    const balance = Number(initialData?.user?.balance) || 0;
    const credit = Number(initialData?.user?.credit) || 0;
    
    return {
      id: initialData?.user?._id,
      balance: formatNumber(balance),
      equity: formatNumber(balance + credit + profit),
      credit: formatNumber(credit),
      margin: activePositions && activePositions.length > 0 ? formatNumber(positions?.totalMargin) : "0.00",
      freeMargin: formatNumber(balance + credit + profit - positions?.totalMargin),
      level: activePositions && activePositions.length > 0 ? formatNumber(positions?.level) : "0.00" ,
      change: formatNumber(profit),
    };
  }, [initialData, profit]);

  const [data, setData] = useState(wallet);

  const updateData = (newData) => {
    setData((prevData) => ({
      ...prevData,
      ...newData,
    }));
  };

  useEffect(() => {
    updateData(wallet);
  }, [wallet]);

  const handlePosData = useCallback((newData) => {
    const newWalletData = {
      balance: formatNumber(newData.balance),
      equity: formatNumber(newData.equity),
      credit: formatNumber(newData.credit),
      margin: formatNumber(newData.margin),
      freeMargin: formatNumber(newData.freeMargin),
      level: formatNumber(newData.level),
      change: formatNumber(newData.runningProfit),
    };

    if (activePositions?.length) {
      updateData(newWalletData);
    } else {
      updateData(wallet);
    }
  }, [activePositions, wallet]);

  useEffect(() => {
    if (!wallet.id) return;

    socket.emit("userpositions", JSON.stringify({ userId: wallet.id }));
    socket.on("posData", handlePosData);

    return () => {
      socket.off("posData", handlePosData);
    };
  }, [wallet.id, handlePosData]);

  const renderWalletItem = ({ label, key }) => (
    <div key={key} className="flex justify-between gap-2 md:text-xs text-base font-bold">
      <span>{label}:</span>
      <span>{isNaN(data[key]) ? '0.00' : data[key]}</span>
    </div>
  );

  return (
    <div className="bottom-0 md:pl-5 w-full md:bg-white flex flex-col md:flex-row gap-5 overflow-x-hidden border-t-2 border-gray-200 md:font-medium md:text-blue-600 md:absolute p-5 md:p-1 md:m-0 bg-gray-50 md:shadow-none text-zinc-700">
      {WALLET_ITEMS.map(renderWalletItem)}
      <div className={`text-center font-bold md:text-xs ${Number(data.change) < 0 ? "text-red-600" : "text-green-700"}`}>
        {data.change ? `${data.change} USD` : "___"}
      </div>
    </div>
  );
};

export default WalletInfo;
