import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  MdArrowUpward,
  MdArrowDownward,
  MdSend,
  MdUpdate,
} from "react-icons/md";
import socket from "../../socket";

import { getSymbols } from '../../api/queryFunctions';
import { useQuery } from "@tanstack/react-query";
import client_socket from "../../socket/client_socket";

const getFormattedTime = () => {
  const now = new Date();
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const seconds = String(now.getSeconds()).padStart(2, "0");
  const milliseconds = now.getTime();
  return `${hours}:${minutes}:${seconds}:${milliseconds}`;
};

const ManualQuotes = () => {
  const { data: symbols = [] } = useQuery({
    queryKey: ['symbols'],
    queryFn: getSymbols
  });

  const digits = useMemo(() => {
    return symbols.reduce((acc, symbol) => {
      acc[symbol.Symbol] = symbol.digits;
      return acc;
    }, {});
  }, [symbols]);

  // Rest of the component code...

  const [formData, setFormData] = useState({
    symbol: "",
    bid: "",
    ask: "",
    last: "",
    volume: "",
  });
  const [error, setError] = useState("");
  const [isDataUpdated, setIsDataUpdated] = useState(false);
  const [focusedField, setFocusedField] = useState(null);

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (name === "symbol") {
      setIsDataUpdated(false);
    }
  }, []);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (!formData.symbol || !formData.bid || !formData.ask) {
        setError("Please fill in all required fields");
        return;
      }

      const sendData = `Q,${formData.symbol}.FXCM,${
        formData.last
      },1,${getFormattedTime()},1,${formData.volume},${formData.bid},,${
        formData.ask
      },,${formData.volume},${formData.bid},${formData.volume},${
        formData.bid
      },${formData.volume},${formData.bid},${formData.volume},${
        formData.bid
      },Cba,01`;

      socket.emit("pipfeeder", sendData);
      setError("");
      console.log("Form submitted:", sendData);
    },
    [formData]
  );

  const handleUpdate = useCallback(() => {
    setIsDataUpdated(false);
  }, []);

  const handleUpDown = useCallback(
    (direction) => {
      if (!focusedField || !formData[focusedField]) return;

      const currentValue = parseFloat(formData[focusedField]);
      const symbolDigits = digits[formData.symbol] || 5;
      const step = 1 / Math.pow(10, symbolDigits);
      const newValue =
        direction === "up" ? currentValue + step : currentValue - step;

      setFormData((prev) => ({
        ...prev,
        [focusedField]: newValue.toFixed(symbolDigits),
      }));
    },
    [focusedField, formData, digits]
  );

  const handleUp = () => handleUpDown("up");
  const handleDown = () => handleUpDown("down");

  useEffect(() => {
    if (!formData.symbol) return;

    const handleSocketMessage = (data) => {
      const { symbol, bid, ask, ltp, volume } = data.newMessage;
      if (formData.symbol === symbol && !isDataUpdated) {
        const symbolDigits = digits[formData.symbol];
        setFormData((prev) => ({
          ...prev,
          bid: Number(bid).toFixed(symbolDigits),
          ask: Number(ask).toFixed(symbolDigits),
          last: Number(ltp).toFixed(symbolDigits),
          volume,
        }));
        setIsDataUpdated(true);
      }
    };

    client_socket.on("abc", handleSocketMessage);

    return () => client_socket.off("abc", handleSocketMessage);
  }, [formData.symbol, isDataUpdated, digits]);

  const symbolOptions = useMemo(
    () => [
      <option key="default" value="">
        Select Currency
      </option>,
      ...symbols.map((symbol, index) => (
        <option key={index} value={symbol.Symbol}>
          {symbol.Symbol}
        </option>
      )),
    ],
    [symbols]
  );

  return (
    <div className="max-w-md mx-auto bg-white shadow-md rounded-lg overflow-hidden">
      <div className="px-4 py-2 bg-gray-100 border-b">
        <h2 className="text-lg font-semibold text-gray-800">Quotes</h2>
      </div>
      <form onSubmit={handleSubmit} className="p-4">
        <div className="mb-4">
          <label
            htmlFor="symbol"
            className="block text-sm font-medium text-gray-700"
          >
            Symbol:
          </label>
          <select
            id="symbol"
            name="symbol"
            value={formData.symbol}
            onChange={handleChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 outline-none"
          >
            {symbolOptions}
          </select>
        </div>
        <div className="grid grid-cols-2 gap-4">
          {["bid", "ask", "last", "volume"].map((field, index) => (
            <div
              key={field}
              className={`mb-4 ${
                index % 2 === 0 ? "col-span-1" : "col-span-1"
              }`}
            >
              <label
                htmlFor={field}
                className="block text-sm font-medium text-gray-700 capitalize"
              >
                {field}:
              </label>
              <input
                type="text"
                id={field}
                name={field}
                value={formData[field]}
                onChange={handleChange}
                onFocus={() => setFocusedField(field)}
                className="mt-1 block w-full rounded-sm border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 outline-none"
              />
            </div>
          ))}
        </div>
        {error && (
          <p className="text-red-500 text-sm mb-4 text-center">{error}</p>
        )}
        <div className="flex justify-between items-center mb-4">
          <div className="flex space-x-2">
            <button
              type="button"
              onClick={handleDown}
              className="px-2 py-1 bg-red-500 text-white rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 flex items-center"
            >
              <MdArrowDownward className="mr-2" size={18} />
              Down
            </button>
            <button
              type="button"
              onClick={handleUp}
              className="px-2 py-1 bg-green-500 text-white rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 flex items-center"
            >
              <MdArrowUpward className="mr-2" size={18} />
              Up
            </button>

            <button
              type="button"
              onClick={handleUpdate}
              className="px-2 py-1 bg-yellow-500 text-white rounded-md hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-opacity-50 flex items-center"
            >
              <MdUpdate className="mr-2" size={18} />
              Update
            </button>
          </div>
          <button
            type="submit"
            className="px-4 py-2 bg-indigo-500 text-white rounded-md hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50 flex items-center text-lg"
          >
            <MdSend className="mr-2" size={24} />
            Send
          </button>
        </div>
        <div className="flex justify-center"></div>
      </form>
    </div>
  );
};
export default ManualQuotes;
