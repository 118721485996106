import React from "react";
import { ImCross } from "react-icons/im";
import { FiPlus, FiBarChart2, FiTrash2, FiInfo } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { changeSymbol } from "../../../store/chartSlice";

const OptionButton = ({ icon, label, onClick }) => (
  <button
    className="flex items-center justify-between bg-gray-50 hover:bg-gray-100 text-gray-800 font-medium py-3 px-4 rounded-lg transition-colors duration-200"
    onClick={onClick}
  >
    <span className="flex items-center">
      {React.cloneElement(icon, { className: "mr-3", size: 20 })}
      {label}
    </span>
    <span className="text-gray-400">›</span>
  </button>
);

const MobileOptions = ({
  currency,
  handleMobOptions,
  handleNewChart,
  handleHide,
  handleSpecification,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <div className="w-full bg-white p-6 rounded-t-2xl shadow-lg h-2/3 flex flex-col transition-all duration-300 ease-in-out pb-20">
      <div className="flex justify-between items-center mb-6">
        <h6 className="text-gray-800 text-xl font-semibold">
          {currency.symbol}
        </h6>
        <button
          type="button"
          className="text-gray-400 hover:bg-gray-100 hover:text-gray-900 rounded-full p-2 transition-colors duration-200"
          onClick={handleMobOptions}
        >
          <ImCross size={16} />
        </button>
      </div>
      <p className="text-gray-600 mb-6">{currency?.description}</p>
      <div className="flex flex-col gap-4 flex-grow">
        <OptionButton
          icon={<FiPlus />}
          label="New Order"
          onClick={() => {
            dispatch(changeSymbol(currency?.symbol));
            navigate("/trade");
          }}
        />
        <OptionButton
          icon={<FiBarChart2 />}
          label="View Chart"
          onClick={handleNewChart}
        />
        <OptionButton icon={<FiTrash2 />} label="Remove" onClick={handleHide} />
        <OptionButton
          icon={<FiInfo />}
          label="Specifications"
          onClick={handleSpecification}
        />
      </div>
    </div>
  );
};

export default MobileOptions;
