import axios from "axios";
import apiClient, { socketClient } from "./apiClient";

export const getWatchlistData = async () => {
  const response = await apiClient.get("get-watchlist-data");
  return response.data.message;
};

export const deleteSymbol = async (postData) => {
  const response = await apiClient.post("delete-symbol", postData);
  return response;
};

export const addSymbol = async (postData) => {
  const response = await apiClient.post("add-symbol", postData);
  return response;
};

export const getUser = async () => {
  const response = await apiClient.get("user");
  return response.data;
};

export const getSymbols = async () => {
  const response = await apiClient.get("get-group-symbols");
  return response.data.symbolData;
};

export const getPositions = async () => {
  const response = await apiClient.get("get-positions");
  return response.data;
};

export const getClosedPositions = async () => {
  const response = await apiClient.get("get-closed-positions");
  return response.data;
};

export const getUserOrders = async () => {
  const response = await apiClient.get("get-user-orders");
  return response.data;
};

export const getUserDeals = async () => {
  const response = await apiClient.get("get-user-deals");
  return response.data;
};

export const getChartData = async (input) => {
  const { symbol, time, from, to } = input;

  const response = await apiClient.get(
    `get-data/${symbol}/${time}?from=${from}&to=${to}`
  );

  const data = response.data;

  const sortedData = data.sort((a, b) => new Date(a.date) - new Date(b.date));

  const ohlcData = sortedData.map((item) => ({
    time: Math.floor(new Date(item.date).getTime() / 1000),
    open: item.open,
    high: item.high,
    low: item.low,
    close: item.close,
  }));

  const volumeData = sortedData.map((item, index) => {
    const previousClose = index > 0 ? sortedData[index - 1].close : item.close;
    const color =
      item.close > previousClose
        ? "rgba(38, 166, 154, 0.3)"
        : "rgba(239, 83, 80, 0.3)";
    return {
      time: Math.floor(new Date(item.date).getTime() / 1000),
      value: item.volume,
      color: color,
    };
  });

  return { ohlcData: ohlcData, volumeData: volumeData };
};

export const getSpecificationSymbol = async (symbolName) => {
  const response = await apiClient.get(
    `get-specification?symbol=${symbolName}`
  );
  return response;
};

export const postPartaillyClosePosition = async (data) => {
  const response = await apiClient.post("partially-close-position", data);
  return response;
};

export const postLogin = async ({ userId, password }) => {
  const response = await apiClient.post("login", {
    email: userId,
    password: password,
  });
  return response;
};

export const signUp = async (data) => {
  const response = await apiClient.post("signup",data);
  return response;
};

export const updatePosition = async (data) => {
  const response = await apiClient.post("update-position", data);
  return response;
};

export const getCsvData = async () => {
  const response = await apiClient.get(
    `get-csv-data/${"BTCUSD"}/${1}?from=${0}&to=${1}`
  );

  return response.data;
};

export const getTransactions = async (id) => {
  const response = await apiClient.get(`get-transactions?userId=${id}`);
  return response.data;
};

export const getHistory = async (symbol, resolution, from, to, countback) => {
  const response = await axios.get(
    `${socketClient}history?symbol=${symbol}&resolution=${resolution}&from=${from}&to=${to}&countback=${countback}`
  );
  return response.data;
};

export const getJournal = async (id) => {
  const response = await apiClient.get(`journal/${id}`);
  return response.data;
};

export const deleteUserPendingPosition = async (id) => {
  const response = await apiClient.post(`delete-user-pending-position`, {
    id: id,
  });
  return response.data;
};
