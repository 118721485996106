import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SearchWatchListSection from "../watchlist_search/SearchWatchListSection";
import Detail from "../history/detail/Detail";
import WalletInfo from "../account/WalletInfo";
import TVCPage from "../../pages/ChartPage";
import { changeMode } from "../../store/modeSlice";

const DesktopLayout = () => {
  const dispatch = useDispatch();
  const { mode, show: showDetail } = useSelector((state) => state.mode);
  const isWatchlistVisible = useSelector((state) => state.watchlist.show);
  const [watchlistWidth, setWatchlistWidth] = useState(30);
  const [dragging, setDragging] = useState(false);
  const chartHeight = useSelector((state) => state.resizable.chartHeight);
  const isDetailVisible = useSelector((state) => state.mode.show);

  const handlePointerDown = (e) => {
    setDragging(true);
    e.target.setPointerCapture(e.pointerId);
  };

  const handlePointerUp = (e) => {
    setDragging(false);
    e.target.releasePointerCapture(e.pointerId);
  };


useEffect(() => {
 dispatch(changeMode("runningPositions"))
}, [dispatch])
  useEffect(() => {
    const handlePointerMove = (e) => {
      if (!dragging) return;

      const newWidth = (e.clientX / window.innerWidth) * 100;
      setWatchlistWidth(Math.max(25, Math.min(70, newWidth)));
    };

    if (dragging) {
      document.addEventListener("pointermove", handlePointerMove);
      document.addEventListener("pointerup", handlePointerUp);
    } else {
      document.removeEventListener("pointermove", handlePointerMove);
      document.removeEventListener("pointerup", handlePointerUp);
    }

    return () => {
      document.removeEventListener("pointermove", handlePointerMove);
      document.removeEventListener("pointerup", handlePointerUp);
    };
  }, [dragging]);

  return (
    <div className="flex h-screen">
      <div className="flex flex-col  w-full">
        <div
          className={`flex ${
            !showDetail ? "h-full" : "h-4/5"
          } transition-all duration-300 ease-in-out`}
        >
          <div
            className="flex-start"
            style={{
              width: isWatchlistVisible ? `${100 - watchlistWidth}vw` : "100vw",
              height: isDetailVisible ? `${chartHeight}vh` : "",
            }}
          >
            <TVCPage />
          </div>
          <div
            className={`w-1 ${
              "h-full"
            } cursor-col-resize overflow-hidden bg-slate-100 z-0 ${
              dragging ? "sticky" : ""
            }`}
            onPointerDown={handlePointerDown}
          />
          {isWatchlistVisible && (
            <div className="flex-end" style={{ width: `${watchlistWidth}vw` }}>
              <SearchWatchListSection width={watchlistWidth} />
            </div>
          )}
        </div>
        {showDetail && (
          <div className="flex-1 w-full fixed bottom-0">
            <Detail mode={mode} />
          </div>
        )}
        <div className="w-full fixed bottom-0">
          <WalletInfo />
        </div>
      </div>
    </div>
  );
};

export default DesktopLayout;
