import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const symbolSlice = createSlice({
  name: "symbols",
  initialState,
  reducers: {
    resetState: () => initialState, 
    setSymbols: (state, action) => {
      return action.payload; 
    }
  },
});

export const { resetState: resetSymbols, setSymbols } = symbolSlice.actions;
export default symbolSlice.reducer;
