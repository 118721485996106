import { createSlice } from "@reduxjs/toolkit";

const initialState = {
};

const symbolDigitCountSlice = createSlice({
  name: "symbolDigitCount",
  initialState,
  reducers: {
   
    setSymbolDigitCount :(state, action) => {
      return action.payload;
    },

   
}
});


const {setSymbolDigitCount} = symbolDigitCountSlice.actions;
export {setSymbolDigitCount};
export default symbolDigitCountSlice.reducer;