import { getHistory, getSymbols } from "../../api/queryFunctions.js";
import { subscribeOnStream, unsubscribeFromStream } from "./streaming.js";
import { store } from "../../store/index.js";
import { changeSymbol } from "../../store/chartSlice.js";
import { changeShowTradeForm } from "../../store/watchlistSlice.js";
import { setSymbolDigitCount } from "../../store/symbolDigitCountSlice.js";
import { setSymbols } from "../../store/symbolsSlice.js";

const configurationData = {
  supported_resolutions: [
    "1",
    "5",
    "15",
    "30",
    "60",
    "240",
    "D",
    "W",
    "M",
  ],
  symbols_types: [
    { value: "Crypto Currencies", name: "Crypto Currencies" },
    { value: "Forex", name: "Forex" },
    { value: "Shares", name: "Shares" },
    { value: "energy", name: "energy" },
    { value: "Indices", name: "Indices" },
    { value: "Metals", name: "Metals" },
  ],
};

const lastBarsCache = new Map();
let cachedSymbols = null;

async function getCachedSymbols() {
  if (!cachedSymbols) {
    const data = await getSymbols();
   
    cachedSymbols = data.map((symbol) => ({
      symbol: symbol.Symbol,
      full_name: symbol.Symbol,
      description: symbol.description || "",
      exchange: symbol.category || "",
      type: (symbol.category || "").toLowerCase(),
      digits: Number(symbol.digits) || 5,
    }));

    const symbolDigitCountObject = cachedSymbols.reduce((acc, symbol) => {
      acc[symbol.symbol] = symbol.digits;
      return acc;
    }, {});
   
    store.dispatch(setSymbols(data));
   

    store.dispatch(setSymbolDigitCount(symbolDigitCountObject));
  }
  return cachedSymbols;
}

const datafeed = {  
  onReady: (callback) => {
    // console.log("[onReady]: Method call");
    setTimeout(() => callback(configurationData));
  },

  searchSymbols: async (
    userInput,
    exchange,
    symbolType,
    onResultReadyCallback
  ) => {
    // console.log("[searchSymbols]: Method call");
    const symbols = await getCachedSymbols();
    const lowercaseInput = userInput.toLowerCase();
    const lowercaseSymbolType = symbolType.toLowerCase();
    store.dispatch(changeShowTradeForm(false));

    const filteredSymbols = symbols.filter(
      (symbol) =>
        symbol.symbol.toLowerCase().includes(lowercaseInput) &&
        (!symbolType || symbol.type === lowercaseSymbolType)
    );

    onResultReadyCallback(filteredSymbols);
  },

  resolveSymbol: async (
    symbolName,
    onSymbolResolvedCallback,
    onResolveErrorCallback
  ) => {
    // console.log("[resolveSymbol]: Method call", symbolName);
    const symbols = await getCachedSymbols();
    const symbolItem = symbols.find(({ symbol }) => symbol === symbolName);
    // console.log(symbolItem);

   

    if (!symbolItem) {
      // console.log("[resolveSymbol]: Cannot resolve symbol", symbolName);
      onResolveErrorCallback("Cannot resolve symbol");
      return;
    }

    const symbolInfo = {
      ...symbolItem,
      ticker: symbolItem.symbol,
      name: symbolItem.symbol,
      session: symbolItem.type === "crypto currencies" ? "24x7" : "24x5",
      timezone: "Etc/UTC",
      minmov: 1,
      pricescale: 100,
      has_intraday: true,
      has_daily: true,
      has_weekly_and_monthly: true,
      // intraday_multipliers: ["1", "2"],
      visible_plots_set: "ohlc",
      supported_resolutions: configurationData.supported_resolutions,
      volume_precision: 2,
      data_status: "streaming",
      digits: symbolItem.digits,
    };

    // console.log("[resolveSymbol]: Symbol resolved", symbolName);
    onSymbolResolvedCallback(symbolInfo);
  },

  getBars: async (
    symbolInfo,
    resolution,
    periodParams,
    onHistoryCallback,
    onErrorCallback
  ) => {
    const { from, to, firstDataRequest, countBack } = periodParams;
    store.dispatch(changeSymbol(symbolInfo.ticker));

    // console.log(
    //   "[getBars]: Method call",
    //   symbolInfo,
    //   resolution,
    //   from,
    //   to,
    //   firstDataRequest,
    //   countBack
    // );

    try {

      const data = await getHistory(
        symbolInfo.ticker,
        resolution,
        from,
        to,
        countBack
      );

      if (!data || data.s !== "ok" || data.data.length === 0) {
        if (data && data.nextTime) {
          onHistoryCallback([], { noData: true, nextTime: data.nextTime });
        } else {
          onHistoryCallback([], { noData: true });
        }
        return;
      }

      const bars = data.data;
      // console.log(bars[bars.length - 1]);

      if (firstDataRequest) {
        // console.log(
        //   "[getBars]: Setting last bar in cache:",
        //   bars[bars.length - 1]
        // );
        lastBarsCache.set(symbolInfo.name, { ...bars[bars.length - 1] });
       
      }

      // console.log(`[getBars]: returned ${bars.length} bar(s)`);
      onHistoryCallback(bars, { noData: false });
    } catch (error) {
      // console.log("[getBars]: Get error", error);
      onErrorCallback(error);
    }
  },

  subscribeBars: (
    symbolInfo,
    resolution,
    onRealtimeCallback,
    subscriberUID,
    onResetCacheNeededCallback
  ) => {
   
  
    subscribeOnStream(
      symbolInfo,
      resolution,
      onRealtimeCallback,
      subscriberUID,
      onResetCacheNeededCallback,
      lastBarsCache.get(symbolInfo.name)
    );
  },

  unsubscribeBars: (subscriberUID) => {
    // console.log(
    //   "[unsubscribeBars]: Method call with subscriberUID:",
    //   subscriberUID
    // );
    unsubscribeFromStream(subscriberUID);
  },
};

export default datafeed;





