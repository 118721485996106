import { configureStore } from '@reduxjs/toolkit'
import userReducer from "./userSlice"
import symbolsReducer from "./symbolsSlice"
import watchlistReducer from './watchlistSlice'
import modeSliceReducer from './modeSlice'
import mobileViewReducer from './mobileViewSlice'
import chartSliceReducer from './chartSlice'
import appSliceReducer from './appSlice'
import onLoadSlice from './onLoadSlice'
import indicatorsSlice from './indicators/indicatorsSlice'

import chartSettingsReducer from './chartSettings'
import resizableSlice from './resizableSlice'
import filterDataHistoryScreen from "./filterDataMobScreenSlice/FilterHistoryScreen"
import symbolDigitCountSlice from './symbolDigitCountSlice'


export const store = configureStore({
  reducer: {
    onLoad: onLoadSlice,
    app: appSliceReducer,
    user: userReducer,
    symbols: symbolsReducer,
    watchlist: watchlistReducer,
    mode: modeSliceReducer,
    mobileView: mobileViewReducer,
    chart: chartSliceReducer,
    indicators: indicatorsSlice,
    chartSettings: chartSettingsReducer,
    resizable: resizableSlice,
    filterDataScreen: filterDataHistoryScreen,
    symbolDigitCount: symbolDigitCountSlice,
  },


})