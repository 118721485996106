import React from "react";
import { MdArrowDropUp, MdArrowDropDown } from "react-icons/md";
import { useSelector } from "react-redux";

const DesktopView = ({
  currency,
  handleContextMenu,
  setSelectedItem,
  handleMobOptions,
  index,

}) => {
 
  return (
    <div
    className="grid grid-cols-4 p-2 cursor-pointer hover:bg-slate-100 text-sm md:text-xs md:border-b border-gray-100"
    onContextMenu={(e) => handleContextMenu(e, currency)}
    onMouseOver={() => setSelectedItem(currency)}
    onClick={() => handleMobOptions(index)}
  >
    <span className="font-semibold text-zinc-800 ml-[-10px]">
      <div className="flex items-center">
        {currency.askChange === "rising" ? (
          <MdArrowDropUp size={24} className="text-green-700" />
        ) : (
          <MdArrowDropDown size={24} className="text-red-500" />
        )}
        {currency.symbol}
      </div>
    </span>

    <span
      className={
        currency.changePercent >= 0 ? "text-green-700" : "text-red-500"
      }
    >
      {currency?.changePercent?.toFixed(2)}%
    </span>
    <span
      className={
        currency.bidChange === "rising" ? "text-green-700" : "text-red-500"
      }
    >
      {currency.bid.toFixed(Number(currency.digits))}
    </span>
    <span
      className={
        currency.askChange === "rising" ? "text-green-700" : "text-red-500"
      }
    >
       {currency.ask.toFixed(Number(currency.digits))}
    </span>
  </div>
  )
}

export default DesktopView;
