import React, { useState, useEffect } from "react";
import MobileTrade from "./walletplusPositions/MobileTrade";
import MobileHistory from "../history/mobile/MobileHistory";
import Account from "../account/Account";
import EconomicCalender from "../navbars/menuSidebar/sideBarMenuComponent/EconomicCalender";
import TradeForm from "../trade/tradeForm/TradeForm";
import SymbolDelete from "../watchlist_search/symbolDelete/SymbolDelete";
import AddSymbol from "../mobileDeviceIconToggle/trade/AddSymbol";
import WatchList from "../watchlist_search/watchlist/WatchList";
import TVCPage from "../../pages/ChartPage";
import NewsWidget from "../news/newsWidget";

const MobileContent = ({ showComponent, isDropdownOpen }) => {
  const [loading, setLoading] = useState({
    wallet: true,
    watchlist: true,
    chart: true,
    detail: true,
    account: true,
    calender: true,
    tradeForm: true,
    symbolDelete: true,
    news: true,
  });

  useEffect(() => {
    const loadData = async () => {
      setTimeout(() => {
        setLoading((prevState) => ({
          ...prevState,
          wallet: false,
          watchlist: false,
          chart: false,
          detail: false,
          account: false,
          calender: false,
          tradeForm: false,
          symbolDelete: false,
          news: false,
        }));
      }, 100);
    };

    loadData();
  }, []);

  const LoadingSpinner = () => (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      {/* <div className="w-12 h-12 border-t-2 border-blue-500 border-solid rounded-full animate-spin"></div>
      <p className="mt-4 text-lg font-semibold text-gray-700">Loading...</p> */}
    </div>
  );

  return (
    <div>
      <div style={{ display: showComponent.wallet ? "block" : "none" }}>
        {loading.wallet ? <LoadingSpinner /> : <MobileTrade />}
      </div>
      <div style={{ display: showComponent.watchlist ? "block" : "none" }}>
        {loading.watchlist ? <LoadingSpinner /> : <WatchList />}
      </div>
      <div style={{ display: showComponent.chart ? "block" : "none" }}>
        {loading.chart ? <LoadingSpinner /> : <TVCPage />}
      </div>
      <div style={{ display: showComponent.detail ? "block" : "none" }}>
        {loading.detail ? <LoadingSpinner /> : <MobileHistory />}
      </div>
      <div style={{ display: showComponent.account ? "block" : "none" }}>
        {loading.account ? <LoadingSpinner /> : <Account />}
      </div>
      <div style={{ display: showComponent.calender ? "block" : "none" }}>
        {loading.calender ? <LoadingSpinner /> : <EconomicCalender />}
      </div>
      <div style={{ display: showComponent.tradeForm ? "block" : "none" }}>
        {loading.tradeForm ? <LoadingSpinner /> : <TradeForm />}
        {isDropdownOpen && !loading.tradeForm && <AddSymbol />}
      </div>
      <div style={{ display: showComponent.symbolDelete ? "block" : "none" }}>
        {loading.symbolDelete ? <LoadingSpinner /> : <SymbolDelete />}
      </div>
      <div style={{ display: showComponent.news ? "block" : "none" }}>
        {loading.news ? <LoadingSpinner /> : <NewsWidget />}
      </div>
    </div>
  );
};

export default MobileContent;
