import { useQueryClient } from "@tanstack/react-query";
import React from "react";

const DataCard = ({ server, text, ip, timestamp }) => (
  <div className="bg-white border border-gray-200 rounded-lg p-4">
    <div className="flex flex-col justify-between  mb-2">
      <h3 className="text-lg font-medium text-gray-800">{server}</h3>
      <span className="text-xs text-gray-500">
        {new Date(timestamp).toLocaleString()}
      </span>
    </div>
    <p className="text-gray-600 mb-2 line-clamp-2">{text}</p>
    <div className="flex items-center text-sm text-gray-500">
      <svg
        className="w-4 h-4 mr-1"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
        />
      </svg>
      {ip}
    </div>
  </div>
);

const DataGrid = () => {
  const queryClient = useQueryClient();
  const data = queryClient.getQueryData(["journal"]);

  if (!data || !data.message || data.message.length === 0) {
    return (
      <p className="text-center text-gray-500">No journal entries found.</p>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {data.message.map((item, index) => (
          <DataCard
            key={index}
            server={item.server}
            text={item.text}
            ip={item.ipAddress}
            timestamp={item.createdAt}
          />
        ))}
      </div>
    </div>
  );
};

export default DataGrid;
