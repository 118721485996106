import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  watchlist: true,
  wallet: false,
  chart: false,
  account: false,
  detail: false,
  calender: false,
  tradeForm: false,
  symbolDelete: false,
  searchBarMob: false,
  about: false,
  news: false,
  bulkOperations: false
}

const mobileViewSlice = createSlice({
  name: "mobileView",
  initialState,
  reducers: {
    changeTab: (state, action) => {
      const tabName = action.payload;
      Object.keys(state).forEach((tab) => {
        if (tab !== tabName) {
          state[tab] = false;
        }
      });
      state[tabName] = true;
    },
    setShowBulkOperations: (state, action) => {
      state.bulkOperations = !state.bulkOperations;
    },
    resetState: (state) => {
      return initialState
    }
  },
});

export const { changeTab, resetState: resetMobileViewState, setShowBulkOperations } = mobileViewSlice.actions;

export default mobileViewSlice.reducer;
