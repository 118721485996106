import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IoClose, IoCheckmarkCircle, IoWarning } from "react-icons/io5";
import {
  FaDollarSign,
  FaPercent,
  FaExchangeAlt,
  FaPlus,
  FaMinus,
} from "react-icons/fa";
import { RiPriceTag3Line } from "react-icons/ri";
import { updatePosition } from "../../../api/queryFunctions";
import { refreshComponent } from "../../../store/modeSlice";
import {
  countDecimalPlaces,
  divideByDecimalPlaces,
  stringToMapType,
} from "../../../helpers";
import client_socket from "../../../socket/client_socket";
import { FaP } from "react-icons/fa6";

const SOCKET_EVENT = "newMessage";
const SELL_TYPES = [0, 3, 5, 7];

const InfoItem = React.memo(({ label, value, icon: Icon }) => (
  <div className="flex items-center space-x-2 bg-gray-50 p-2 rounded-md">
    <Icon className="text-blue-500" />
    <div>
      <span className="text-xs text-gray-500">{label}</span>
      <p className="font-semibold">{value}</p>
    </div>
  </div>
));

const InputField = React.memo(
  ({
    label,
    name,
    value,
    onChange,
    onFocus,
    onDecrease,
    onIncrease,
    icon: Icon,
  }) => (
    <div className="mb-4">
      <label className="block text-sm font-medium text-gray-700 mb-1">
        {label}
      </label>
      <div className="flex items-center">
        <div className="relative flex-grow">
          <input
            type="number"
            name={name}
            placeholder={`Set ${label}`}
            value={value}
            onChange={onChange}
            onFocus={onFocus}
            className="w-full p-2 pl-8 border border-gray-300 rounded-l-md text-sm outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
          <Icon className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400" />
        </div>
        <button
          className="p-2 border border-gray-300 text-sm outline-none hover:bg-gray-100 transition-colors duration-300"
          onClick={(e) => {
            e.preventDefault();
            onDecrease();
          }}
        >
          <FaMinus size={20} className="p-1" />
        </button>
        <button
          className="p-2 border border-gray-300 text-sm outline-none hover:bg-gray-100 transition-colors duration-300 rounded-r-md"
          onClick={(e) => {
            e.preventDefault();
            onIncrease();
          }}
        >
          <FaPlus size={20} className="p-1" />
        </button>
      </div>
    </div>
  )
);

const ModifyPositionForm = React.memo(({ closeHandler, posData }) => {
  const { symbol, positionId, type, ticket, price, stopLoss, takeProfit } =
    posData;
  const mode = useSelector((state) => state.mode.mode);
  const [success, setSuccess] = useState(false);
  const [isModifyButtonLoading, setIsModifyButtonLoading] = useState(false);
  const [focusedField, setFocusedField] = useState(null);
  const [socketCalled, setSocketCalled] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [formData, setFormData] = useState({
    stopLoss: stopLoss || "",
    takeProfit: takeProfit || "",
    livePrice: "",
    tradePrice: price || "",
  });

  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const formRef = useRef(null);

  const { mutate: postUpdatePosition, data: res } = useMutation({
    mutationKey: "updateposition",
    mutationFn: (data) => updatePosition(data),
    onSuccess: (data) => {
      queryClient.setQueryData(["positions"], (oldData) => {
        return {
          ...oldData,
          positions: oldData.positions.map((p) =>
            p._id === data?.data?.updatedPosition._id
              ? data?.data?.updatedPosition
              : p
          ),
        };
      });

      setSuccess(true);
    },
    onSettled: () => {
      setIsModifyButtonLoading(false);
    },
  });

  const validateForm = useCallback(() => {
    const { stopLoss, takeProfit, tradePrice, livePrice } = formData;
    let isValid = true;

    const isSellType = SELL_TYPES.includes(stringToMapType(type));

    if (mode === "pendingPositions") {
      if (stopLoss && tradePrice) {
        isValid = isSellType
          ? parseFloat(tradePrice) < parseFloat(stopLoss)
          : parseFloat(tradePrice) > parseFloat(stopLoss);
      }
      if (takeProfit && tradePrice) {
        isValid = isSellType
          ? parseFloat(tradePrice) > parseFloat(takeProfit)
          : parseFloat(tradePrice) < parseFloat(takeProfit);
      }
    } else {
      if (stopLoss && livePrice) {
        isValid = isSellType
          ? parseFloat(livePrice) < parseFloat(stopLoss)
          : parseFloat(livePrice) > parseFloat(stopLoss);
      }
      if (takeProfit && livePrice) {
        isValid = isSellType
          ? parseFloat(livePrice) > parseFloat(takeProfit)
          : parseFloat(livePrice) < parseFloat(takeProfit);
      }
    }

    const hasAnyValue = stopLoss || takeProfit || tradePrice;
    setDisabled(!(hasAnyValue && isValid));
  }, [formData, mode, type]);

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }, []);

  const handleFocus = useCallback((e) => {
    setFocusedField(e.target.name);
    setSocketCalled(false);
  }, []);

  const handleDecrease = useCallback((name) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: (
        parseFloat(prevData[name]) - divideByDecimalPlaces(prevData[name])
      ).toFixed(countDecimalPlaces(prevData[name])),
    }));
  }, []);

  const handleIncrease = useCallback((name) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: (
        parseFloat(prevData[name]) + divideByDecimalPlaces(prevData[name])
      ).toFixed(countDecimalPlaces(prevData[name])),
    }));
  }, []);

  const handleModifyPosition = useCallback(
    (e) => {
      e.preventDefault();
      setIsModifyButtonLoading(true);

      const { stopLoss, takeProfit, tradePrice } = formData;

      const updateData = {
        positionId: positionId,
        price: tradePrice || price,
        stopLoss: stopLoss || "",
        takeProfit: takeProfit || "",
      };

      postUpdatePosition(updateData);
    },
    [formData, positionId, price, postUpdatePosition]
  );

  useEffect(() => {
    validateForm();
  }, [formData, validateForm]);

  useEffect(() => {
    dispatch(refreshComponent());
  }, [dispatch]);

  useEffect(() => {
    const socketListener = (datapoint) => {
      if (datapoint.newMessage.symbol === symbol) {
        setFormData((prev) => ({
          ...prev,
          livePrice: datapoint.newMessage.bid,
        }));
        if (focusedField && !socketCalled && !formData[focusedField]) {
          setSocketCalled(true);
          setFormData((prevData) => ({
            ...prevData,
            [focusedField]: datapoint.newMessage.bid,
          }));
        }
      }
    };

    client_socket.on(SOCKET_EVENT, socketListener);

    return () => {
      client_socket.off(SOCKET_EVENT, socketListener);
    };
  }, [symbol, focusedField, socketCalled, formData]);

  const memoizedInfoItems = useMemo(
    () => (
      <div className="grid grid-cols-2 gap-4">
        <InfoItem label="Type" value={type} icon={FaExchangeAlt} />
        <InfoItem label="Ticket" value={`#${ticket}`} icon={RiPriceTag3Line} />
        <InfoItem label="Price" value={price} icon={FaDollarSign} />
        <InfoItem
          label="Current"
          value={formData.livePrice}
          icon={FaDollarSign}
        />
      </div>
    ),
    [type, ticket, price, formData.livePrice]
  );

  return (
    <div className="w-full max-w-md mx-auto bg-white rounded-lg shadow-lg overflow-hidden">
      <div className="p-6 bg-gray-50">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold text-gray-800">{symbol}</h2>
          <button
            onClick={closeHandler}
            className="text-gray-500 hover:text-gray-700 transition-colors duration-300"
          >
            <IoClose size={24} />
          </button>
        </div>
        {memoizedInfoItems}
      </div>

      {!success ? (
        <form ref={formRef} className="p-6">
          {mode === "pendingPositions" && (
            <InputField
              label="Price"
              name="tradePrice"
              value={formData.tradePrice}
              onChange={handleChange}
              onFocus={handleFocus}
              onDecrease={() => handleDecrease("tradePrice")}
              onIncrease={() => handleIncrease("tradePrice")}
              icon={FaDollarSign}
            />
          )}
          <InputField
            label="Stop Loss"
            name="stopLoss"
            value={formData.stopLoss}
            onChange={handleChange}
            onFocus={handleFocus}
            onDecrease={() => handleDecrease("stopLoss")}
            onIncrease={() => handleIncrease("stopLoss")}
            icon={FaDollarSign}
          />
          <InputField
            label="Take Profit"
            name="takeProfit"
            value={formData.takeProfit}
            onChange={handleChange}
            onFocus={handleFocus}
            onDecrease={() => handleDecrease("takeProfit")}
            onIncrease={() => handleIncrease("takeProfit")}
            icon={FaDollarSign}
          />

          {res?.data?.message && (
            <div className="mt-4 p-2 bg-green-100 text-green-700 text-sm rounded-md flex items-center">
              <IoCheckmarkCircle className="mr-2" />
              {res.data.message}
            </div>
          )}

          <div className="mt-6 flex justify-between">
            <button
              className="px-4 py-2 bg-blue-500 text-white text-sm font-medium rounded-md hover:bg-blue-600 transition-colors duration-300 disabled:bg-gray-400 disabled:cursor-not-allowed focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
              disabled={disabled || isModifyButtonLoading}
              onClick={handleModifyPosition}
            >
              {isModifyButtonLoading ? "Modifying..." : "Modify Position"}
            </button>

            <button
              className="px-4 py-2 bg-gray-200 text-gray-700 text-sm font-medium rounded-md hover:bg-gray-300 transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
              type="button"
              onClick={closeHandler}
            >
              Cancel
            </button>
          </div>
        </form>
      ) : (
        <div className="p-6 flex flex-col items-center">
          <IoCheckmarkCircle className="text-green-500 text-5xl mb-4" />
          <p className="text-lg font-semibold mb-4 text-center">
            {res?.data?.message}
          </p>
          <button
            className="px-4 py-2 bg-blue-500 text-white text-sm font-medium rounded-md hover:bg-blue-600 transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
            onClick={closeHandler}
          >
            Close
          </button>
        </div>
      )}
    </div>
  );
});

export default ModifyPositionForm;
