import { setBidAsk } from "../../store/chartSlice.js";
import { store } from "../../store/index.js";
import client_socket from "../../socket/client_socket.js";


const selectCurrentSymbol = (state) => state.chart.symbol;const channelToSubscription = new Map();

function getCurrentSymbol() {
  return selectCurrentSymbol(store.getState());
}

// Add this variable to store the unsubscribe function
let unsubscribe;


// Subscribe to store changes
unsubscribe = store.subscribe(() => {
  const previousSymbol = getCurrentSymbol();
  const currentSymbol = selectCurrentSymbol(store.getState());
  
  if (previousSymbol !== currentSymbol) {
    console.log('Symbol changed:', currentSymbol);
    // You can add any additional logic here that needs to run when the symbol changes
  }
});

client_socket.on("connect", () => {
  // console.log("[client_socket] Connected");
});

client_socket.on("disconnect", (reason) => {
  // console.log("[client_socket] Disconnected:", reason);
});

client_socket.on("error", (error) => {
  // console.log("[client_socket] Error:", error);
});

function adjustTimeForChartLibrary(incomingTime, lastProcessedTime) {
  const incomingMs = new Date(incomingTime).getTime();
  const lastProcessedMs = new Date(lastProcessedTime).getTime();

  if (incomingMs <= lastProcessedMs) {
    return new Date(lastProcessedMs + 1).toISOString();
  }

  return incomingTime;
}



client_socket.on("newMessage", (data) => {
  if (!data || !data.newMessage || !data.results) {
    return;
  }

  const { newMessage, results } = data;


  const { symbol, volume } = newMessage;
  const { open, high, low, close, createdAt } = results;


  const channelString = symbol;
  const subscriptionItem = channelToSubscription.get(channelString);
  const currentSymbol = getCurrentSymbol(); 

  if(newMessage.symbol === currentSymbol) {
    store.dispatch(setBidAsk({ask: newMessage.ask, bid: newMessage.bid}));
  }

  // Check if the chart has the same symbol that is coming from the client_socket
  if (subscriptionItem === undefined) {
    // console.log(`Adding handler to existing subscription for ${channelString}. Resolution: ${subscriptionItem.resolution}`);

    // console.log(`No subscription found for symbol: ${symbol}`);
    return;
  }

  // console.log(`Received data for subscribed symbol: ${symbol}`);

  const { lastDailyBar, resolution } = subscriptionItem;

  // Adjust the incoming time
  const adjustedCreatedAt = adjustTimeForChartLibrary(
    createdAt,
    lastDailyBar.time
  );
  const tradeTime = new Date(adjustedCreatedAt).getTime();

  const nextDailyBarTime = getNextDailyBarTime(lastDailyBar.time, resolution); 

  let bar;
  if (tradeTime >= nextDailyBarTime) {
    // Create a new bar
    bar = {
      time: tradeTime,
      open: open,
      high: high,
      low: low,
      close: close,
      volume: volume,
    };
  } else {
    // Update the existing bar
    bar = {
      ...lastDailyBar,
      high: Math.max(lastDailyBar.high, high),
      low: Math.min(lastDailyBar.low, low),
      close: close,
      volume: lastDailyBar.volume ,
      time: tradeTime,
    };
  }
  subscriptionItem.lastDailyBar = bar;
  // console.log("Updated bar for symbol:", symbol, bar);

  // Send data to every subscriber of that symbol
  subscriptionItem.handlers.forEach((handler) => handler(bar));
});


function getNextDailyBarTime(barTime, resolution) {
  const resolutionMs = getResolutionInMilliseconds(resolution);
  const nextBarTime =
    Math.floor(barTime / resolutionMs) * resolutionMs + resolutionMs;
  return Math.max(nextBarTime, barTime + 1); // Ensure the new time is always greater
}

function getResolutionInMilliseconds(resolution) {
  const numericResolution = parseInt(resolution);
  if (resolution.includes("D")) {
    return numericResolution * 24 * 60 * 60 * 1000;
  } else if (resolution.includes("W")) {
    return numericResolution * 7 * 24 * 60 * 60 * 1000;
  } else if (resolution.includes("M")) {
    return numericResolution * 30 * 24 * 60 * 60 * 1000;
  } else {
    return numericResolution * 60 * 1000; // For minute-based resolutions
  }
}

export function subscribeOnStream(
  symbolInfo,
  resolution,
  onRealtimeCallback,
  subscriberUID,
  onResetCacheNeededCallback,
  lastDailyBar
) {
  const channelString = symbolInfo.symbol;
  const handler = onRealtimeCallback;
  const subscriptionItem = channelToSubscription.get(channelString);

  if (subscriptionItem) {
    // Already subscribed to the channel, use the existing subscription
    // console.log(subscriptionItem.resolution)
    subscriptionItem.handlers.push(handler);
    return;
  }

  channelToSubscription.set(channelString, {
    subscriberUID,
    resolution,
    lastDailyBar,
    handlers: [handler],
  });

  // console.log(
  //   "[subscribeBars]: Subscribe to streaming. Channel:",
  //   channelString
  // );
  // console.log(`[subscribeBars]: Subscribe to streaming. Channel: ${channelString}, Resolution: ${resolution}`);

  client_socket.emit("SubAdd", { subs: [channelString] });
}

export function unsubscribeFromStream(subscriberUID) {
  for (const channelString of channelToSubscription.keys()) {
    const subscriptionItem = channelToSubscription.get(channelString);
    const handlerIndex = subscriptionItem.handlers.findIndex(
      (handler) => handler.id === subscriberUID
    );

    if (handlerIndex !== -1) {
      subscriptionItem.handlers.splice(handlerIndex, 1);

      if (subscriptionItem.handlers.length === 0) {
        console.log(
          "[unsubscribeBars]: Unsubscribe from streaming. Channel:",
          channelString
        );
        client_socket.emit("SubRemove", { subs: [channelString] });
        channelToSubscription.delete(channelString);
      }
      break;
    }
  }
}

export function cleanup() {
  if (unsubscribe) {
    unsubscribe();
  }
}

