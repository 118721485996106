import React from "react";
import { IoIosArrowRoundBack } from "react-icons/io";
import SearchBar from "../components/watchlist_search/search/SearchBar";
import { useNavigate } from "react-router-dom";

const SearchPage = () => {
  const navigate = useNavigate();
  const handleOnClickBack = () => {
    navigate("/");
  };
  return (
    <div className=" ">
      <div className="flex items-center gap-2 mb-2 fixed top-0 z-10 bg-white w-full p-3 border-b border-gray-100">
        <IoIosArrowRoundBack size={30} onClick={handleOnClickBack} />
        <span className="pt-1 ml-2 text-base font-semibold">Add Symbol</span>
      </div>
      <div className="mt-16 ">
        <SearchBar />
      </div>
    </div>
  );
};

export default SearchPage;
