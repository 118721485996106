import React, { useEffect, useState } from "react";
import styles from "./Detail.module.css";
import {
  sortData,
  filterDataByDate,
} from "../../../helpers/SortingAndFiltering";
import {
  mapDealsData,
  mapOrderData,
  dealsField,
  orderFields,
  positionFields,
  mapClosedPosition,
  closedPositionField,
  mapPendingPositions,
  mapRunningPositions,
  getCurrentDateFormatted,
} from "../../../helpers";
import socket from "../../../socket";
import { useSelector, useDispatch } from "react-redux";
import ModifyPositionForm from "../../trade/modifyPosition/ModifyPositionForm";
import { AiFillEdit } from "react-icons/ai";
import { setChartHeight } from "../../../store/resizableSlice";
import { useQueryClient } from "@tanstack/react-query";
import PPC from "../../trade/partialPositionClose/PPC";
import VerticalNavbar from "../../navbars/verticalNavbar/VerticalNavbar";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import { setShowBulkOperations } from "../../../store/mobileViewSlice";
import BulkOperations from "../../BulkOperations/BulkOperations";
import useNewMessageSocket from "../../../socket/newMessageSocket";

const Detail = ({ mode }) => {
  const { newMessageData: abcData } = useNewMessageSocket();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const positions = queryClient.getQueryData(["positions"])?.positions || [];
  const deals = queryClient.getQueryData(["deals"]) || [];
  const orders = queryClient.getQueryData(["orders"]) || [];
  const closedPositions = queryClient.getQueryData(["closedPositions"]) || [];
  const userId = queryClient.getQueryData(["user"])?.user?._id;


  const rp = positions.filter((position) => position.status === 1);
  const pp = positions.filter((position) => position.status === 0);


  const chartHeight = useSelector((state) => state.resizable.chartHeight);
  const isDetailVisible = useSelector((state) => state.mode.show);
  const isBulkOperationsVisible = useSelector(
    (state) => state.mobileView.bulkOperations
  );

  const [finalData, setFinalData] = useState([]);
  const [modify, setModify] = useState(false);
  const [sortBy, setSortBy] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showPPC, setShowPPC] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState(null);

  const handlePointerDown = (e) => {
    setDragging(true);
    e.target.setPointerCapture(e.pointerId);
  };

  const handlePointerUp = (e) => {
    setDragging(false);
    e.target.releasePointerCapture(e.pointerId);
  };

  const handlePointerMove = (e) => {
    if (!dragging) return;
    const newHeight = Math.max(
      40,
      Math.min(85, 85 - (e.clientY / window.innerHeight) * 45)
    );
    dispatch(setChartHeight(100 - newHeight));
  };

  useEffect(() => {
    if (dragging) {
      document.addEventListener("pointermove", handlePointerMove);
      document.addEventListener("pointerup", handlePointerUp);
    } else {
      document.removeEventListener("pointermove", handlePointerMove);
      document.removeEventListener("pointerup", handlePointerUp);
    }

    return () => {
      document.removeEventListener("pointermove", handlePointerMove);
      document.removeEventListener("pointerup", handlePointerUp);
    };
  }, [dragging]);

  const handleExit = async (rowData) => {
    setShowPPC(true);
    setModify(false);
    setSelectedPosition(rowData);
  };

  const handleModifyClick = (rowData) => {
    // console.log("rowData", rowData);
    setSelectedPosition(rowData);
    setShowPPC(false);
    setModify(true);
  };

  const sortedData = sortData(finalData, sortBy);
  const filteredData = filterDataByDate(sortedData, startDate, endDate);

  useEffect(() => {
    if (userId) {
      socket.emit("userpositions", JSON.stringify({ userId: userId }));
    }
  }, [userId]);

  useEffect(() => {
    const mapData = () => {
      switch (mode) {
        case "runningPositions":
          setFinalData(mapRunningPositions(rp));
          break;
        case "deals":
          setFinalData(mapDealsData(deals));
          break;
        case "orders":
          setFinalData(mapOrderData(orders));
          break;
        case "closedPositions":
          setFinalData(mapClosedPosition(closedPositions));
          break;
        case "pendingPositions":
          setFinalData(mapPendingPositions(pp));
          break;
        default:
          break;
      }
    };

    mapData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, closedPositions, deals, orders, positions]);

  useEffect(() => {
    if (mode === "runningPositions") {
     
      const handlePosProfit = (updatedData) => {
        setFinalData((prevData) => {
          return prevData?.map((data) => {
            if (data.id === updatedData.positionId) {
              return {
                ...data,
                profit:
                  updatedData.status === 1
                    ? updatedData.profit?.toFixed(2)
                    : data.profit,
                currentPrice: updatedData.currentPrice,
              };
            }
            return data;
          });
        });
      };
      
      socket.on("posProfit", handlePosProfit);
      return () => {
        socket.off("posProfit", handlePosProfit);
      
      };
    }
  }, [mode, userId, queryClient]);

  // useEffect(() => {
  //   socket.on("closePosition", () => {
      
  //     queryClient.invalidateQueries([
  //       "closedPositions",
  //       "positions",
  //       "deals",
  //       "orders",
  //     ]);
  //   });
  //   return () => {
  //     socket.off("closePosition");
  //   };
  // }, [queryClient])

  useEffect(() => {
    if (mode === "pendingPositions" && abcData?.newMessage) {
      setFinalData((prevData) => {
        return prevData?.map((data) => {
          if (data.symbol === abcData.newMessage.symbol) {
            return {
              ...data,
              currentPrice: [1, 2, 4, 6].includes(data.type)
                ? abcData.newMessage.bid
                : abcData.newMessage.ask,
            };
          }
          return data;
        });
      });
    }
  }, [abcData, mode]);

  const renderTable = (fields, data) => (
    <div
      className="font-semibold text-xs text-gray-500 ml-12  border-b border-gray-200"
      style={{
        height: isDetailVisible ? `${100 - chartHeight}vh` : "",
      }}
    >
      <div
        className={`w-full h-1 cursor-row-resize bg-slate-100 ${
          dragging ? "sticky" : ""
        }`}
        onPointerDown={handlePointerDown}
      />

      <div className={`${styles.sortAndFilter}`}>
        <PiDotsThreeOutlineVerticalFill
          size={18}
          onClick={() => dispatch(setShowBulkOperations())}
        />
        {isBulkOperationsVisible && <BulkOperations />}
       <div className="flex items-center gap-2">
       <select onChange={(e) => setSortBy(e.target.value)}>
          <option value="">Sort By</option>
          <option value="symbol">Symbol</option>
          <option value="ticket">Ticket</option>
          <option value="type">Type</option>
          <option value="volume">Volume</option>
          <option value="openTime">Open Time</option>
          <option value="closeTime">Close Time</option>
          <option value="profit">Profit</option>
        </select>
        <div>
          <input
            type="date"
            placeholder="From Date"
            defaultValue="2020-01-01"
            onChange={(e) => setStartDate(e.target.value)}
          />
          <input
            type="date"
            placeholder="To Date"
            defaultValue={getCurrentDateFormatted()}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>
       </div>
      </div>

      <div className={styles.scrollTable}>
        <table className={styles.table}>
          <thead>
            <tr>
              {fields.map((field, index) =>
                index === 0 ? null : <th key={index}>{field}</th>
              )}
            </tr>
          </thead>
          <tbody>
            {filteredData?.map((rowData, rowIndex) => (
              <tr
                key={rowIndex}
                className="border-b border-slate-200 hover:text-blue-600 hover:bg-slate-100"
              >
                {Object.keys(rowData).map((key, cellIndex) =>
                  cellIndex === 0 ? null : (
                    <td key={cellIndex}>{rowData[key]}</td>
                  )
                )}
                {(mode === "runningPositions" ||
                  mode === "pendingPositions") && (
                  <td>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "20px",
                      }}
                    >
                      <span
                        className={styles.redCross}
                        onClick={() => handleExit(rowData)}
                      >
                        &#10060;
                      </span>
                      <span>
                        <AiFillEdit
                          size={16}
                          className="text-blue-700 hover:text-blue-500 hover:cursor-pointer"
                          onClick={() => handleModifyClick(rowData)}
                        />

                        {modify && selectedPosition && (
                          <div className={styles.modifyOverlay}>
                            <ModifyPositionForm
                              closeHandler={() => {
                                setModify(false);
                                setSelectedPosition(null);
                              }}
                              posData={{
                                symbol: selectedPosition.symbol,
                                positionId: selectedPosition.id,
                                type: selectedPosition.type,
                                ticket: selectedPosition.ticket,
                                price: selectedPosition.price,
                                stopLoss: selectedPosition.stopLoss,
                                takeProfit: selectedPosition.takeProfit,
                                closed: selectedPosition.closed,
                              }}
                            />
                          </div>
                        )}

                        {showPPC && (
                          <div className={styles.modifyOverlay}>
                            <PPC
                              data={selectedPosition}
                              onClose={() => setShowPPC(false)}
                            />
                          </div>
                        )}
                      </span>
                    </div>
                  </td>
                )}
              </tr>
            ))}
            {filteredData?.length > 0 && (
              <tr className="font-bold  text-blue-500">
                {fields.map((field, index) => {
                  if (index === 0) return null;
                  if (["SWAP", "COMMISSION", "P/L", "PROFIT"].includes(field)) {
                    const sum = (filteredData || []).reduce((acc, row) => {
                      const value =
                        parseFloat(row[Object.keys(row)[index]]) || 0;
                      return acc + value;
                    }, 0);
                    return <td key={index}>{sum.toFixed(2)}</td>;
                  }
                  if (index === 1) return <td key={index}>TOTAL</td>;
                  return <td key={index}></td>;
                })}
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );

  let tableComponent;
  switch (mode) {
    case "runningPositions":
    case "pendingPositions":
      tableComponent = renderTable(positionFields, finalData);
      break;
    case "orders":
      tableComponent = renderTable(orderFields, finalData);
      break;
    case "deals":
      tableComponent = renderTable(dealsField, finalData);
      break;
    case "closedPositions":
      tableComponent = renderTable(closedPositionField, finalData);
      break;
    default:
      tableComponent = <p className="text-center">Refresh</p>;
  }

  return (
    <div>
      <VerticalNavbar />
      {tableComponent}
    </div>
  );
};

export default Detail;
