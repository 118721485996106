import React from "react";
import { IoMdClose } from "react-icons/io";
import { useQuery } from "@tanstack/react-query";
import { getSpecificationSymbol } from "../../../api/queryFunctions";

const SpecificationField = ({ label, value }) => (
  <div className="flex flex-col space-y-1 p-2  bg-gray-50 rounded-lg">
    <span className=" font-medium text-gray-500">{label}</span>
    <span className="  text-gray-800">{value || "N/A"}</span>
  </div>
);

const Overlay = ({ closeHandler, symbolName }) => {
  const { data, isLoading, error } = useQuery({
    queryKey: ["getSymbolSpecification", symbolName],
    queryFn: () => getSpecificationSymbol(symbolName),
  });

  const specificationData = data?.data?.message || {};

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 p-4">
      <div className="bg-white shadow-xl rounded-2xl w-full max-w-4xl max-h-[90vh] overflow-hidden">
        <div className="flex justify-between items-center p-6 border-b border-gray-200">
          <h2 className="text-2xl font-bold text-gray-800">
            Symbol Specification
          </h2>
          <button
            onClick={closeHandler}
            className="text-gray-400 hover:text-gray-600 transition-colors"
            aria-label="Close"
          >
            <IoMdClose size={24} />
          </button>
        </div>

        <div className="p-6 overflow-y-auto max-h-[calc(90vh-88px)]">
          {isLoading && (
            <p className="text-center text-lg text-gray-600">Loading...</p>
          )}
          {error && (
            <p className="text-center text-lg text-red-500">
              Error loading data
            </p>
          )}
          {data && (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
              <SpecificationField
                label="Symbol"
                value={specificationData.symbol}
              />
              <SpecificationField
                label="Description"
                value={specificationData.description}
              />
              <SpecificationField
                label="Digits"
                value={specificationData.digits}
              />
              <SpecificationField
                label="MinVolume"
                value={specificationData.minVolume}
              />
              <SpecificationField
                label="MaxVolume"
                value={specificationData.maxVolume}
              />
              <SpecificationField
                label="StepVolume"
                value={specificationData.stepVolume}
              />
              <SpecificationField
                label="ContractSize"
                value={specificationData.contractSize}
              />
              <SpecificationField
                label="Spread"
                value={specificationData.spread}
              />
              <SpecificationField
                label="Source"
                value={specificationData.source}
              />
              <SpecificationField
                label="StopsLevel"
                value={specificationData.stopsLevel}
              />
              <SpecificationField
                label="MarginCurrency"
                value={specificationData.marginCurrency}
              />
              <SpecificationField
                label="ProfitCurrency"
                value={specificationData.profitCurrency}
              />
              <SpecificationField
                label="Calculation"
                value={specificationData.calculation}
              />
              <SpecificationField
                label="ChartMode"
                value={specificationData.chartMode}
              />
              <SpecificationField
                label="Trade"
                value={specificationData.trade}
              />
              <SpecificationField label="Gtc" value={specificationData.gtc} />
              <SpecificationField
                label="Fillings"
                value={specificationData.fillings}
              />
              <SpecificationField
                label="Order"
                value={specificationData.order}
              />
              <SpecificationField
                label="Expiration"
                value={specificationData.expiration}
              />
              <SpecificationField
                label="SwapType"
                value={specificationData.swapType}
              />
              <SpecificationField
                label="SwapShort"
                value={specificationData.swapShort}
              />
              <SpecificationField
                label="SwapLong"
                value={specificationData.swapLong}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Overlay;
