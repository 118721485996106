import { io } from "socket.io-client";
import { apiUrl } from "../api/apiClient";

const socket = io(apiUrl, {
  transports: ["websocket"],
  withCredentials: false,
  reconnection: true,
});


export default socket;


