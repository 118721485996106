import { createSlice } from "@reduxjs/toolkit";

// const lineStyleValues = {
//   solid: 0,
//   dotted: 1,
//   dashed: 2,
//   largeDashed: 3,
//   sparseDotted: 4,
// };
// lineWidth range - 1 to 4.
// linetype - simple: 0, steps: 1, curved: 2
// lastPriceAnimation - disabled: 0, continuous: 1, onDataUpdate: 2

const initialState = {
  visibility: false,
  singleMAVisibility: true,
  activeIndicator: "",
  indicatorAsNewChart: false,
  names: {
    ema: {
      name: "ema",
      visibility: false,
      title: "Exponential Moving Average",
    },
    sma: {
      name: "sma",
      visibility: false,
      title: "Simple Moving Average",
    },

    ichimokuCloud: {
      name: "ichimokuCloud",
      visibility: false,
      title: "Ichimoku Cloud",
    },
    apo: {
      name: "apo",
      visibility: false,
      title: "Absolute Price Oscillator"
    },
    aroon: {
      name: "aroon",
      visibilty: false,
      title: "Aroon"
    },
    bop: {
      name: "bop",
      visibilty: false,
      title: "Balance of Power"
    }
    ,
    cfo: {
      name: "cfo",
      visibilty: false,
      title: "Chande Forecast Oscillator"
    },
    cci: {
      name: "cci",
      visibilty: false,
      title: "Community Channel Index"
    },
    dema: {
      name: "dema",
      visibility: false,
      title: "Double Exponential Moving Average"
    },
    mi: {
      name: "mi",
      visibilty: false,
      title: "Mass Index"
    },
    macd: {
      name: "macd",
      visibilty: false,
      title: "Moving Average Convergence Divergence"
    },
    psar: {
      name: "psar",
      visibilty: false,
      title: "Parabolic SAR"
    },

    qstick: {
      name: "qstick",
      visibilty: false,
      title: "Qstick"
    },
    tema: {
      name: "tema",
      visibilty: false,
      title: "Triple Exponential Moving Average"
    },
    trima: {
      name: "trima",
      visibilty: false,
      title: "Triangular Moving Average "
    },
    trix: {
      name: "trix",
      visibilty: false,
      title: "Triple Exponential Average"
    },
    vwma: {
      name: "vwma",
      visibilty: false,
      title: "Volume Weighted Moving Average"
    },

    vi: {
      name: "vi",
      visibilty: false,
      title: "Vortex Indicator"
    },
    ao: {
      name: "ao",
      visibilty: false,
      title: "Awesome Oscillator "
    },
    cmo: {
      name: "cmo",
      visibilty: false,
      title: "Chaikin Oscillator"
    },
    roc: {
      name: "roc",
      visibilty: false,
      title: "Rate of Change"
    },
    rsi: {
      name: "rsi",
      visibilty: false,
      title: "Relative Strength Index"
    },
    atr: {
      name: "atr",
      visibilty: false,
      title: "Average True Range"
    },
    kc: {
      name: "kc",
      visibility: false,
      title: "Keltner Channel "
    }
    ,
    dc: {
      name: "dc",
      visibilty: false,
      title: "Donchian Channel"
    },
    bb: {
      name: "bb",
      visibilty: false,
      title: "Bollinger Bands"
    }
  },

};

const indicatorsSlice = createSlice({
  name: "indicators",
  initialState,
  reducers: {

    setIndicatorAsNewChart: (state, action) => {
      state.indicatorAsNewChart = action.payload
    },

    setActiveMovingAverage: (state, action) => {
      state.activeMovingAverage = action.payload;
    },
    setMovingAverageVisibility: (state, action) => {
      state.visibility = action.payload;
    },


     setShowIndicator : (state, action) => {
      state.singleMAVisibility = action.payload
     },
  

    setShowSingleIndicator: (state, action) => {
      const name = action.payload;
      Object.values(state.names).forEach((item) => {
        item.visibility = item.name === name;
      });
    },
    setVisibility: (state, action) => {
      state.visibility = action.payload;
    },
    setActiveIndicator: (state, action) => {
      state.activeIndicator = action.payload;
    },
    resetIndicators: (state) => {
      return initialState
    }
  },
});

export const {

  setMovingAverageVisibility,
  setActiveMovingAverage,

  setShowSingleIndicator,
  setVisibility,
  setActiveIndicator,
  setShowIndicator,

  resetIndicators,
  setIndicatorAsNewChart
} = indicatorsSlice.actions;

export default indicatorsSlice.reducer;
