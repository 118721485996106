import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  modeRefresh: false,
  walletRefresh: false,
  watchlistRefresh: false,
  chartRefresh: false,
  appRefresh: false,
  showInstantOrder: false,
};

const appSlice = createSlice({
  name: "app",
  initialState: initialState,
  reducers: {
    setShowInstantOrder: (state, action) => {
      if (action.payload === true) {
        state.showInstantOrder = true;
      } else if (action.payload === false) {
        state.showInstantOrder = false;
      } else {
        state.showInstantOrder = !state.showInstantOrder;
      }
    },
    refreshMode: (state) => {
      state.modeRefresh = !state.modeRefresh;
    },
    refreshWallet: (state) => {
      state.walletRefresh = !state.walletRefresh;
    },
    refreshWatchlist: (state) => {
      state.watchlistRefresh = !state.watchlistRefresh;
    },
    refreshChart: (state) => {
      state.chartRefresh = !state.chartRefresh;
    },
    refreshApp: (state) => {
      state.appRefresh = !state.appRefresh;
    },
    resetApp: (state) => {
      return initialState;
    },
  },
});

export const {
  refreshChart,
  refreshMode,
  refreshWallet,
  refreshWatchlist,
  refreshApp,
  setShowInstantOrder,
  resetApp,
} = appSlice.actions;

export default appSlice.reducer;
