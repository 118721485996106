import React, { useCallback, useState } from "react";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { deleteSymbol } from "../../../api/queryFunctions";
import { MdDelete, MdArrowBack } from "react-icons/md";
import { Link } from "react-router-dom";

const SymbolDelete = () => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [deleteMode, setDeleteMode] = useState(false);
  const queryClient = useQueryClient();
  const watchlistData = queryClient.getQueryData(["getWatchlistData"]);

  const { mutate: deleteS } = useMutation({
    mutationFn: deleteSymbol,
    onSuccess: (data) => {
      if (data?.data?.valid) {
        queryClient.setQueryData(["getWatchlistData"], (oldData) => 
          oldData.filter((item) => item.symbol !== data?.data?.message?.symbol)
        );
      }
    }
  });

  const handleDelete = useCallback(() => {
    if (deleteMode) {
      selectedItems.forEach((item) => deleteS({ symbol: item }));
      setSelectedItems([]);
    }
    setDeleteMode(!deleteMode);
  }, [deleteS, deleteMode, selectedItems]);

  const handleSelectItem = useCallback((symbol) => {
    setSelectedItems((prev) =>
      prev.includes(symbol)
        ? prev.filter((item) => item !== symbol)
        : [...prev, symbol]
    );
  }, []);

  return (
    <div className="p-4">
      <div className="flex justify-between items-center mb-4">
        <Link
          to="/"
          className="flex items-center gap-1 text-gray-600 hover:text-gray-800 transition-colors duration-200 font-semibold"
        >
          {" "}
          <MdArrowBack size={24} /> <span className="text-base">Back</span>
        </Link>

        <button
          onClick={handleDelete}
          className={`p-2 rounded transition-colors duration-200 ${
            deleteMode
              ? "text-red-500 hover:text-red-700"
              : "text-gray-500 hover:text-gray-700"
          }`}
        >
          <MdDelete size={20} />
        </button>
      </div>

      <div className="space-y-2">
        {watchlistData?.map((res) => (
          <div
            key={res.symbol}
            className={`flex items-center justify-between p-3 border rounded-md border-blue-200 transition-all duration-200 ${
              deleteMode ? "bg-gray-50" : "bg-white"
            }`}
          >
            <div>
              <h3 className="font-medium">{res.symbol}</h3>
              <p className="text-sm text-gray-500">{res.description}</p>
            </div>
            {deleteMode && (
              <input
                type="checkbox"
                checked={selectedItems.includes(res.symbol)}
                onChange={() => handleSelectItem(res.symbol)}
                className="w-4 h-4 text-blue-600 transition-opacity duration-200"
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SymbolDelete;
