import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filterSymbolData: [],
  filterName: null,
  OrderSorting: null,
  customDate: null,
  dateType: "Today",
  activeMode: null,
  filterDealData: [],
  filterOrderData: [],
  filterData: [],
  symbolDeal: "All Symbols",
  symbolOrder: "All Symbols",
  symbolPosition: "All Symbols",
  sortingRp: [],
  sortingPp: [],
  symbolList: [],
};

const FilterDataSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    filterSymbol: (state, action) => {
      state.filterSymbolData = action.payload;
    },
    filterDealSymbol: (state, action) => {
      state.filterDealData = action.payload;
    },
    filterOrderSymbol: (state, action) => {
      state.filterOrderData = action.payload;
    },
    filterNameSelect: (state, action) => {
      state.filterName = action.payload;
    },
    filterSortRunPen: (state, action) => {
      state.sortingRp = action.payload;
    },
    filterSortPenPos: (state, action) => {
      state.sortingPp = action.payload;
    },
    customDateFilter: (state, action) => {
      state.customDate = action.payload;
    },
    dateFilterOtherType: (state, action) => {
      state.dateType = action.payload;
    },
    historyActiveTab: (state, action) => {
      state.activeMode = action.payload;
    },
    setSymbolDeal: (state, action) => {
      state.symbolDeal = action.payload;
    },
    setSymbolOrder: (state, action) => {
      state.symbolOrder = action.payload;
    },
    setSymbolPosition: (state, action) => {
      state.symbolPosition = action.payload;
    },
    setSymbolList: (state, action) => {
      state.symbolList = action.payload;
    },
  },
});

export const {
  filterSymbol,
  filterNameSelect,
  filterSortRunPen,
  filterSortPenPos,
  customDateFilter,
  dateFilterOtherType,
  historyActiveTab,
  filterDealSymbol,
  filterOrderSymbol,
  setSymbolDeal,
  setSymbolOrder,
  setSymbolPosition,
  setSymbolList,
} = FilterDataSlice.actions;

export default FilterDataSlice.reducer;
