import React, { useEffect, useState } from "react";

const TradeCard = ({ data }) => {
  const [smallDevice, setSmallDevice] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setSmallDevice(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {smallDevice > 768 ? (
        <div className="p-4 flex flex-col bg-zinc-100 rounded-md mb-5">
          {Object.entries(data).map(([key, value], index) => (
            <div className="flex justify-between items-center p-2" key={index}>
              <div className="font-medium text-gray-700">{key}:</div>
              <div className="text-gray-900">{value}</div>
            </div>
          ))}
        </div>
      ) : (
        <div className="p-4 flex flex-col bg-white-100 rounded-md mb-5 text-base">
          {Object.entries(data).map(([key, value], index) => (
            <div className="flex justify-between items-center p-2" key={index}>
              <div className=" text-black-700 font-semibold">{key}:</div>
              <div className="text-gray-900">{value}</div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default TradeCard;
