import React, { useState, useMemo, useCallback } from "react";
import {
  IoIosAdd,
  IoIosRemoveCircleOutline,
  IoIosClose,
  IoIosSearch,
  IoIosArrowForward,
} from "react-icons/io";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import {
  deleteSymbol,
  getSymbols,
  addSymbol,
} from "../../../api/queryFunctions";
import { useSelector } from "react-redux";

const SearchBar = () => {
  const queryClient = useQueryClient();
  const watchlistData = queryClient.getQueryData(["getWatchlistData"]);

  const symbolsData = useSelector(state => state.symbols);
  const addMutation = useMutation({
    mutationFn: addSymbol,
    onSuccess: (data) => {
      if (data?.data?.valid) {
        queryClient.setQueryData(["getWatchlistData"], (oldData) => 
          [...oldData, data?.data?.message ]
        );
      }
    }
  });
  
  const deleteMutation = useMutation({
    mutationFn: deleteSymbol,
    onSuccess: (data) => {
      if (data?.data?.valid) {
        queryClient.setQueryData(["getWatchlistData"], (oldData) => 
          oldData.filter((item) => item.symbol !== data?.data?.message?.symbol)
        );
      }
    }

  });

  const [showDropdown, setShowDropdown] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);

  const handleInputChange = useCallback((e) => {
    const value = e.target.value;
    setSearchQuery(value);
    setShowDropdown(value !== "");
    setSelectedCategory(null);
  }, []);

  const handleAddItemToWatchList = useCallback(
    (symbol) => {
      addMutation.mutate({ symbol });
    },
    [addMutation]
  );

  const handleDeleteItemFromWatchList = useCallback(
    (symbol) => {
      deleteMutation.mutate({ symbol });
    },
    [deleteMutation]
  );

  const resetSearch = useCallback(() => {
    setSearchQuery("");
    setSelectedCategory(null);
    setShowDropdown(false);
  }, []);

  const groupedResults = useMemo(() => {
    if (!symbolsData) return {};
    const grouped = symbolsData.reduce((acc, s) => {
      const category = s.category || "Uncategorized";
      if (!acc[category]) acc[category] = [];
      acc[category].push({
        symbol: s.Symbol,
        description: s.description,
        inWatchlist: watchlistData?.some(
          (watchlistItem) => watchlistItem.symbol === s.Symbol
        ),
      });
      return acc;
    }, {});

    // Filter based on search query
    Object.keys(grouped).forEach((category) => {
      grouped[category] = grouped[category].filter(
        (item) =>
          item.symbol.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.description.toLowerCase().includes(searchQuery.toLowerCase())
      );
      if (grouped[category].length === 0) delete grouped[category];
    });

    return grouped;
  }, [symbolsData, searchQuery, watchlistData, addMutation.isSuccess, deleteMutation.isSuccess]);

  const flatResults = useMemo(() => {
    if (!symbolsData) return [];
    return symbolsData
      .map((s) => ({
        symbol: s.Symbol,
        description: s.description,
        inWatchlist: watchlistData?.some(
          (watchlistItem) => watchlistItem.symbol === s.Symbol
        ),
      }))
      .filter(
        (item) =>
          item.symbol.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.description.toLowerCase().includes(searchQuery.toLowerCase())
      );
  }, [symbolsData, searchQuery, watchlistData, addMutation.isSuccess, deleteMutation.isSuccess]);

  const renderSearchInput = () => (
    <div className="flex bg-[#f2f2f2] rounded-md h-10  items-center px-2 mx-3 md:text-sm">
      <IoIosSearch size={20} className="text-gray-500 mr-2" />
      <input
        type="text"
        placeholder="Search Symbol..."
        value={searchQuery}
        onChange={handleInputChange}
        className="flex-1 bg-transparent outline-none"
      />
      {searchQuery && (
        <IoIosClose
          size={24}
          className="text-red-500 cursor-pointer"
          onClick={resetSearch}
        />
      )}
    </div>
  );

  const renderDropdownItem = useCallback(
    (item) => (
      <li
        key={item.symbol}
        className="p-3 hover:bg-gray-100 transition-colors duration-150 ease-in-out"
      >
        <div className="flex justify-between items-center w-full">
          <span className="font-semibold">{item.symbol}</span>
          <span className="text-xs text-gray-500">{item.description}</span>

          {item.inWatchlist ? (
            <button
              className="text-red-500 hover:text-red-700"
              onClick={() => handleDeleteItemFromWatchList(item.symbol)}
              disabled={deleteMutation.isPending}
            >
              <IoIosRemoveCircleOutline size={24} />
            </button>
          ) : (
            <button
              className="text-green-500 hover:text-green-700"
              onClick={() => handleAddItemToWatchList(item.symbol)}
              disabled={addMutation.isPending}
            >
              <IoIosAdd size={24} />
            </button>
          )}
        </div>
      </li>
    ),
    [
      handleDeleteItemFromWatchList,
      handleAddItemToWatchList,
      deleteMutation.isPending,
      addMutation.isPending,
    ]
  );

  const renderDropdown = () => (
    <div className="mt-2 bg-white rounded-md shadow-lg overflow-y-auto pb-20 md:pb-0 md:max-h-96 md:text-sm">
      <ul className="divide-y divide-gray-200">
        {Object.keys(groupedResults).length > 0 &&
          (selectedCategory ? (
            <>
              <li
                className="p-3 bg-gray-100 font-semibold flex items-center cursor-pointer"
                onClick={() => setSelectedCategory(null)}
              >
                <IoIosArrowForward
                  size={20}
                  className="transform rotate-180 mr-2"
                />
                {selectedCategory}
              </li>
              {groupedResults[selectedCategory].map(renderDropdownItem)}
            </>
          ) : (
            Object.entries(groupedResults).map(([category, items]) => (
              <li
                key={category}
                className="p-3 hover:bg-gray-100 transition-colors duration-150 ease-in-out cursor-pointer flex justify-between items-center"
                onClick={() => setSelectedCategory(category)}
              >
                <span className="font-semibold">{category}</span>
                <div className="flex items-center">
                  <span className="text-xsm text-gray-500 mr-2">
                    {items.length} items
                  </span>
                  <IoIosArrowForward size={20} />
                </div>
              </li>
            ))
          ))}

        {flatResults.length > 0 && !selectedCategory && (
          <>
            <li className="p-3 bg-gray-100 font-semibold flex items-center">
              All Matching Results
            </li>
            {flatResults.map(renderDropdownItem)}
          </>
        )}
      </ul>
    </div>
  );

  return (
    <div className="relative">
      {renderSearchInput()}
      <div
        className={`md:absolute  md:w-full ${
          showDropdown ? "md:block" : "md:hidden"
        } block mt-2 md:z-50 `}
      >
        {renderDropdown()}
      </div>
    </div>
  );
};

export default React.memo(SearchBar);
