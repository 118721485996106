import React from "react";

const MobileView = ({
  currency,
  handleContextMenu,
  setSelectedItem,
  handleMobOptions,
  index,
 
}) => {
  
  return (
    <div
      className="grid grid-cols-4 p-2 cursor-pointer hover:bg-slate-100 text-sm md:text-xs md:border-b border-gray-100"
      onContextMenu={(e) => handleContextMenu(e, currency)}
      onMouseOver={() => setSelectedItem(currency)}
      onClick={() => handleMobOptions(index)}
    >
      <span className="font-semibold text-zinc-800 ml-[-10px]">
        <div className="flex items-center pl-2 text-base">
          {currency.symbol}
        </div>
      </span>
      <span
        className={
          currency.changePercent >= 0 ? "text-green-700" : "text-red-500"
        }
      ></span>
      <span
        className={
          currency.bidChange === "rising"
            ? "text-green-700 text-base"
            : "text-red-500 text-base"
        }
      >
        <span> {currency.bid.toFixed(Number(currency.digits))}</span>
      </span>
      <span
        className={
          currency.askChange === "rising"
            ? "text-green-700 text-base"
            : "text-red-500 text-base"
        }
      >
        <span> {currency.ask.toFixed(Number(currency.digits))}</span>
      </span>
    </div>
  );
};

export default MobileView;
