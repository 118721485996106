import { io } from "socket.io-client";
import { socketClient } from "../api/apiClient";


const client_socket = io(socketClient, {
  transports: ["websocket"],
  withCredentials: false,
  reconnection: true,
});


export default client_socket;


