import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userData: {},
  positions: null,
}

const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUser: (state, action) => {
      state.userData = action.payload
    },
    setPositions:(state, action) => {
      state.positions = action.payload
    },
    updateBalance: (state, action) => {
      state.userData.balance = action.payload
      state.userData.equity = action.payload
      state.userData.freeMargin = action.payload

    },
    resetState: () => {
      return initialState
    }
  },
});

export const { updateUser, setPositions, updateBalance, resetState: resetUserState } = UserSlice.actions;

export default UserSlice.reducer;
