import React, { useEffect, useState } from "react";
import ExitPositionBox from "../closedPositions/ExitPositionBox";
import OrderBox from "../orders/OrderBox";
import DealsBox from "../deals/DealsBox";
import { changeMode } from "../../../store/modeSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  filterDealSymbol,
  filterOrderSymbol,
  filterSymbol,
  setSymbolList,
} from "../../../store/filterDataMobScreenSlice/FilterHistoryScreen";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getTransactions } from "../../../api/queryFunctions";

const MobileHistory = () => {
  const queryClient = useQueryClient();
  const user = queryClient.getQueryData(["user"]);
  const { data: transactions } = useQuery({
    queryKey: ["transactions"],
    queryFn: () => getTransactions(user?.user?._id),
    enabled: !!user?.user?._id,
  });
  const mode = useSelector((state) => state.mode.mode);
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("closedPosition");
  const closedpositions = queryClient.getQueryData(["closedPositions"]);
  const deals = queryClient.getQueryData(["deals"]);
  const orders = queryClient.getQueryData(["orders"]);

  const handleTabClick = (newMode) => {
    setActiveTab(newMode);
    dispatch(changeMode(newMode));
  };

  useEffect(() => {
    dispatch(changeMode("closedPosition"));
  }, [dispatch]);

 

  useEffect(() => {
    dispatch(filterSymbol(closedpositions));
    dispatch(filterDealSymbol(deals));
    dispatch(filterOrderSymbol(orders));
  }, [dispatch, closedpositions, orders, deals]);

  useEffect(() => {
    switch (mode) {
      case "closedPosition":
        dispatch(
          setSymbolList(
            Array.from(new Set(closedpositions?.map((item) => item.symbol)))
          )
        );
        break;
      case "deals":
        dispatch(
          setSymbolList(Array.from(new Set(deals.map((item) => item.symbol))))
        );
        break;
      case "orders":
        dispatch(
          setSymbolList(Array.from(new Set(orders.map((item) => item.symbol))))
        );
        break;
      default:
        dispatch(setSymbolList([]));
    }
  }, [mode, dispatch, closedpositions, orders, deals]);
  return (
    <div>
      <div className="">
        <div className="border-b border-gray-200 dark:border-gray-700 pt-3 mb-4">
          <ul className="grid grid-cols-3 place-item-center text-base font-medium text-center ">
            <li className="mr-2">
              <span
                className={`inline-flex items-center justify-center p-4 border-b-2 ${
                  activeTab === "closedPosition"
                    ? "hover:text-black-600  border-blue-600"
                    : "text-gray-600 border-transparent hover:text-gray-600 hover:border-gray-300"
                } group`}
                onClick={() => handleTabClick("closedPosition")}
              >
                <svg
                  className="w-4 h-4 mr-2 text-black-600 group-hover:text-black-500"
                  aria-hidden="true"
                  fill="black"
                  viewBox="0 0 20 20"
                ></svg>
                Positions
              </span>
            </li>
            <li className="mr-2">
              <span
                className={`inline-flex items-center justify-center p-4 border-b-2 ${
                  activeTab === "deals"
                    ? "hover:text-black-600  border-blue-600"
                    : "text-gray-600 border-transparent hover:text-gray-600 hover:border-gray-300"
                } group`}
                onClick={() => handleTabClick("deals")}
              >
                <svg
                  className="w-4 h-4 mr-2 text-blue-600 dark:text-blue-500"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 18 18"
                ></svg>
                Deals
              </span>
            </li>
            <li className="mr-2">
              <span
                className={`inline-flex items-center justify-center p-4 border-b-2 ${
                  activeTab === "orders"
                    ? "hover:text-black-600  border-blue-600"
                    : "text-gray-600 border-transparent hover:text-gray-600 hover:border-gray-300"
                } group`}
                onClick={() => handleTabClick("orders")}
              >
                <svg
                  className="w-4 h-4 mr-2 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                ></svg>
                Order
              </span>
            </li>
          </ul>
        </div>
      </div>

      <div>
        {mode === "closedPosition" && <ExitPositionBox />}
        {mode === "deals" && <DealsBox />}
        {mode === "orders" && <OrderBox />}
      </div>
    </div>
  );
};

export default MobileHistory;
