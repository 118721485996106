import React, { useState } from "react";
import styles from "../closedPositions/ExitPosition.module.css";
import { mapTypeToString, formatTimeToSeconds } from "../../../helpers";
import TradeCard from "../tradeCard/TradeCard";
import { useSelector } from "react-redux";

const OrderBox = () => {
  const [showSubContainerIndex, setShowSubContainerIndex] = useState(null);

  const { filterOrderData } = useSelector((state) => state.filterDataScreen);

  const handleOnClick = (index) => {
    setShowSubContainerIndex(index === showSubContainerIndex ? null : index);
  };

  return (
    <div>
      {!filterOrderData && <p className={styles.error}>Loading...</p>}
      {filterOrderData && (
        <TradeCard data={calcOrdersAggregate(filterOrderData)} />
      )}

      {filterOrderData?.length < 1 && (
        <p className={styles.error}>No Orders available!</p>
      )}

      <div className={styles.scroll}>
        {filterOrderData?.map((data, index) => (
          <div className={styles.container} key={data._id}>
            <div className={styles.main} onClick={() => handleOnClick(index)}>
              <div className={styles.flexBox}>
                <div className={styles.gap}>
                  <span>{data.symbol}</span>
                  <span
                    className={data.type === 1 ? styles.rising : styles.falling}
                  >
                    {mapTypeToString(data.type)},
                  </span>
                </div>
              </div>
              <div className={styles.flexBox}>
                <div className={styles.gap}>
                  <span>{data.currentVolume}</span>
                  <span>/</span>
                  <span>{data.initialVolume}</span>
                  <span>at</span>
                  <span>{data.price}</span>
                </div>
                <div>
                  <span style={{ color: "gray" }}>
                    {data.status === 1 ? "Filled" : "Cancelled"}
                  </span>
                </div>
              </div>
            </div>
            {showSubContainerIndex === index && (
              <div className={styles.subContainer}>
                <div className={styles.flexBox}>
                  <div className={styles.gap}>
                    <span>{formatTimeToSeconds(data.createdAt)}</span>
                  </div>
                </div>
                <div className={styles.flexBox}>
                  <div className={styles.gap}>
                    <span>S/L:</span>
                    <span>{data.stopLoss}</span>
                  </div>
                </div>
                <div className={styles.flexBox}>
                  <div className={styles.gap}>
                    <span>T/P:</span>
                    <span>{data.takeProfit}</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default OrderBox;

const calcOrdersAggregate = (data) => {
  let filled = 0;
  let cancelled = 0;
  for (let i = 0; i < data.length; i++) {
    filled += data[i].status ? 1 : 0;
    cancelled += data[i].status ? 0 : 1;
  }
  return {
    Filled: filled,
    Cancelled: cancelled,
    Total: filled + cancelled,
  };
};
