import React from "react";
import { BiArrowBack } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { changeTab } from "../../store/mobileViewSlice";
import { SERVER_NAME,SEVER_SUPPORT_EMAIL } from "../../constants/constants";

const AboutSection = () => {
  const dispatch = useDispatch();
  return (
    <div className="bg-white min-h-screen ">
      <header className="ps-2 py-4  border border-gray-300">
        <Link to="/" className=" font-semibold flex gap-1 items-center">
          <BiArrowBack
            size={24}
            onClick={() => dispatch(changeTab("watchlist"))}
          />{" "}
          <span className="text-sm"> Back to Home</span>
        </Link>
      </header>
      <div className=" max-w-md  mx-auto  rounded-xl overflow-hidden md:max-w-2xl">
        <div className="p-8">
          <h2 className="uppercase tracking-wide text-sm text-indigo-500 font-semibold">
            About
          </h2>
          <div className="flex justify-between items-center mt-2">
            <div>
              <h1 className="text-lg font-medium text-black">
               {SERVER_NAME}
              </h1>
              <p className="text-gray-500">Version 1.0.0 (Build 1234)</p>
            </div>
            <img
              src="/icon.png"
              width={50}
              height={50}
              alt="App Icon"
              className="p-2"
            />
          </div>

          <Section title="Features">
            <ul className="list-disc list-inside text-gray-600">
              <li>Real-time market data</li>
              <li>Advanced charting tools</li>
              <li>Multiple order types</li>
              <li>Automated trading strategies</li>
              <li>Risk management tools</li>
            </ul>
          </Section>

          <Section title="Support">
            <p className="text-gray-600">
              For assistance, please contact our support team:
              <br />
              Email: {SEVER_SUPPORT_EMAIL}
              <br />
              Phone: +1 (555) 123-4567
            </p>
          </Section>

          <Section title="Legal">
            <p className="text-gray-600">
              © 2024 {SERVER_NAME} Inc. All rights reserved.
              <br />
              <a href="#" className="text-blue-500">
                Terms of Service
              </a>{" "}
              |{" "}
              <a href="#" className="text-blue-500">
                Privacy Policy
              </a>
            </p>
          </Section>
        </div>
      </div>
    </div>
  );
};

const Section = ({ title, children }) => (
  <div className="mt-4">
    <h2 className="text-md font-semibold">{title}:</h2>
    <div className="mt-2">{children}</div>
  </div>
);

export default AboutSection;
