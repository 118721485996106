import React, { useState } from "react";

const Transactions = ({ transactions }) => {
  const [showTransactionIndex, setShowTransactionIndex] = useState(null);

  const handleTransactionClick = (index) => {
    setShowTransactionIndex(index === showTransactionIndex ? null : index);
  };

  return (
    <div>
      {transactions && transactions.message && (
        <div>
          {transactions.message?.map((t, index) => (
            <div
              key={t._id}
              className="p-4 border-b border-gray-200 cursor-pointer"
              onClick={() => handleTransactionClick(index)}
            >
              <div className="flex justify-between items-center">
                <span className="font-semibold">{t.comment}</span>
                <span className="text-gray-500">{t.date.slice(0, 10)}</span>
              </div>
              {showTransactionIndex === index && (
                <div className="mt-2">
                  <p className="text-gray-700 flex justify-between">
                    <span className="font-semibold">Amount:</span>
                    <span>{t.amount}</span>
                  </p>
                  <p className="text-gray-700 flex justify-between">
                    <span className="font-semibold">Type:</span>
                    <span>{t.type}</span>
                  </p>
                  <p className="text-gray-700 flex justify-between">
                    <span className="font-semibold">Comment:</span>
                    <span>{t.comment}</span>
                  </p>
                  <p className="text-gray-700 flex justify-between">
                    <span className="font-semibold">Date:</span>
                    <span>{t.date.slice(0, 25)}</span>
                  </p>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Transactions;
