import React, { useState } from "react";
import styles from "./ExitPosition.module.css";
import { formatTimeToSeconds, mapTypeToString } from "../../../helpers";
import TradeCard from "../tradeCard/TradeCard";
import { useSelector } from "react-redux";

import { useQueryClient } from "@tanstack/react-query";
import Transactions from "./Transactions";
import { RiArrowDropDownLine } from "react-icons/ri";
import { RiArrowDropUpLine } from "react-icons/ri";

const ExitPositionBox = () => {
  const queryClient = useQueryClient();
  const [showSubContainerIndex, setShowSubContainerIndex] = useState([]);

  const [showT, setShowT] = useState(false);

  const transactions = queryClient.getQueryData(["transactions"]);
  const user = queryClient.getQueryData(["user"]);

  const { filterSymbolData } = useSelector((state) => state.filterDataScreen);

  const handleShowT = () => {
    setShowT((prev) => !prev);
  };

  const deposit =
    transactions?.message
      ?.filter((t) => Number(t.amount) > 0 && t.type === "Balance")
      ?.reduce((sum, t) => sum + Number(t.amount), 0) || 0;

  const bonus =
    transactions?.message
      ?.filter((t) => Number(t.amount) > 0 && t.type !== "Balance")
      ?.reduce((sum, t) => sum + Number(t.amount), 0) || 0;

  const withdrawal =
    transactions?.message
      ?.filter((t) => Number(t.amount) < 0)
      ?.reduce((sum, t) => sum + Number(t.amount), 0) || 0;

  const handleOnClick = (index) => {
    setShowSubContainerIndex(index === showSubContainerIndex ? null : index);
  };

  return (
    <div>
      <TradeCard
        data={calculatePositionAggregate(
          filterSymbolData,
          deposit,
          bonus,
          withdrawal,
          user
        )}
      />
      {!filterSymbolData && <p className={styles.error}>Loading...</p>}
      <div className="p-2 border-b   border-gray-200 cursor pointer font-semibold ">
        <div className="flex justify-between" onClick={handleShowT}>
          <p className="text-base">Transactions</p>
          {showT ? (
            <RiArrowDropUpLine size={20} />
          ) : (
            <RiArrowDropDownLine size={20} />
          )}
        </div>
        {showT && <Transactions transactions={transactions} />}
      </div>
      {filterSymbolData?.length < 1 && (
        <p className={styles.error}>No closed position available!</p>
      )}

      <div className={styles.scroll}>
        {filterSymbolData?.map((data, index) => (
          <div className={styles.container} key={data._id}>
            <div className={styles.main} onClick={() => handleOnClick(index)}>
              <div className={styles.flexBox}>
                <div className={styles.gap}>
                  <span>{data.symbol}</span>
                  <span
                    className={data.type === 1 ? styles.rising : styles.falling}
                  >
                    {mapTypeToString(data.type)},
                  </span>
                  <span
                    className={data.type === 1 ? styles.rising : styles.falling}
                  >
                    {data.volume}
                  </span>
                </div>
                <div>{formatTimeToSeconds(data?.closeTime)}</div>
              </div>
              <div className={styles.flexBox}>
                <div className={styles.gap}>
                  <span>{data.price}</span>
                  <span>---&gt;</span>
                  <span>{data.closePrice}</span>
                </div>
                <div
                  className={data.profit > 0 ? styles.rising : styles.falling}
                >
                  {data.profit?.toFixed(2)}
                </div>
              </div>
            </div>
            {showSubContainerIndex === index && (
              <div className={styles.subContainer}>
                <div className={styles.flexBox}>
                  <div className={styles.gap}>
                    <span>S/L:</span>
                    <span>{data.stopLoss}</span>
                  </div>
                  <div className={styles.gap}>
                    <span>T/P:</span>
                    <span>{data.takeProfit}</span>
                  </div>
                </div>
                <div className={styles.flexBox}>
                  <div className={styles.gap}>
                    <span>Open:</span>
                    <span>{formatTimeToSeconds(data.createdAt)}</span>
                  </div>
                  <div className={styles.gap}>
                    <span>Swap:</span>
                    <span>{data?.swap ? data.swap.toFixed(2) : data.swap}</span>
                  </div>
                </div>
                <div className={styles.flexBox}>
                  <div className={styles.gap}>
                    <span>Id:</span>
                    <span>#{data.ticket}</span>
                  </div>
                  <div className={styles.gap}>
                    <span>Commission:</span>
                    <span>
                      {data.comission
                        ? -data.comission.toFixed(2)
                        : -data.comission}
                    </span>
                  </div>
                </div>
                <div className={styles.flexBox}>
                  <div className={styles.gap}>
                    <span>Comment:</span>
                    <span>{data.comment}</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ExitPositionBox;

const calculatePositionAggregate = (data, deposit, bonus, withdrawal, user) => {
  let profit = 0;
  let swap = 0;
  let commission = 0;

  for (let i = 0; i < data?.length; i++) {
    profit += Number(data[i]?.profit || 0);
    swap += Number(data[i]?.swap || 0);
    commission -= Number(data[i]?.comission || 0);
  }

  const depositAmount = Number(deposit || 0);
  const withdrawalAmount = Number(withdrawal || 0);
  

  return {
    Profit: profit.toFixed(2),
    Deposit: depositAmount,
    Bonus: bonus,
    Swap: swap.toFixed(2),
    Commission:  commission.toFixed(2),
    Balance: (parseFloat(user?.user?.balance)).toFixed(2),
    Withdrawal: withdrawalAmount,
  };
};
