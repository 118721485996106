import { useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate, useLocation } from "react-router-dom";
import { getServerDetailsByGroup } from "../components/ATVC/helpers";

const DepositPage = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const group = queryClient.getQueryData(["user"])?.group;
  const [serverDetails, setServerDetails] = useState({
    DEPOSIT_LINK: "https://betatrader11.com/client/deposit.php",
  });

  const handleBack = () => {
    if (location.state?.previousPage) {
      navigate(location.state.previousPage);
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    if (group?.Name) {
      const details = getServerDetailsByGroup(group?.Name);
      setServerDetails(details);
    }
  }, [group]);

  return (
    <div className="flex flex-col h-screen">
      <div className="p-3 flex items-center gap-2">
        <span className="hover:bg-gray-200 cursor-pointer p-2 rounded-md">
          <BiArrowBack onClick={handleBack} size={20} />
        </span>
        <span className="font-semibold text-base">Deposit</span>
      </div>
      <div className="flex-grow">
        <iframe
          src={serverDetails.DEPOSIT_LINK}
          title="Deposit"
          className="w-full h-full border-none"
        />
      </div>
    </div>
  );
};

export default DepositPage;
