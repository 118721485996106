import { useEffect, useState } from "react";
import client_socket from "./client_socket";
const useNewMessageSocket = () => {
  const [newMessageData, setNewMessageData] = useState(null);
  const [isConnected, setIsConnected] = useState(client_socket.connected);

  useEffect(() => {
    const handleNewMessage = (data) => {
      setNewMessageData({ ...data });
    };

    const handleDisconnect = () => {
      setIsConnected(false);
      client_socket.off("newMessage", handleNewMessage);
      client_socket.connect()
    };

    client_socket.on("connect", () => setIsConnected(true));
    client_socket.on("disconnect", handleDisconnect);
    client_socket.on("newMessage", handleNewMessage);

    return () => {
      client_socket.off("connect");
      client_socket.off("disconnect", handleDisconnect);
      client_socket.off("newMessage", handleNewMessage);
    };
  }, []);



  return { newMessageData, isConnected };
};

export default useNewMessageSocket;