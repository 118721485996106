import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  
};

const activeIndicatorSlice = createSlice({
  name: "activeIndicator",
  initialState,
  reducers: {
    
    setIndicator: (state, action) => {
      const indicatorName = action.payload;
      const updatedState = {};
      Object.keys(state).forEach((indicator) => {
        updatedState[indicator] = indicator === indicatorName;
      });
    
      return {
        ...state,
        ...updatedState,
      };
    },
    resetActiveIndicator : (state) => {
      return initialState
    }
    
  },
});

export const { setIndicator, resetActiveIndicator } = activeIndicatorSlice.actions;

export default activeIndicatorSlice.reducer;
