import React from "react";
import DataGrid from "../components/journal/journal";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getJournal } from "../api/queryFunctions";
import { IoArrowBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const JournalPage = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const userId = queryClient.getQueryData(["user"])?.user?._id;

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["journal"],
    queryFn: () => getJournal(userId),
    enabled: !!userId, // Only run the query if userId is available
  });

  return (
    <div className="min-h-screen bg-gray-100 py-2 ">
      <div className="max-w-7xl mx-auto">
        <div className="text-2xl font-bold text-gray-900 pb-2 flex items-center gap-5 border-b border-gray-300 px-2 py-2">
          <IoArrowBack size={24} onClick={() => navigate("/")} />
          <span> Journal Entries</span>
        </div>

        {isLoading && (
          <div className="text-center">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900 mx-auto"></div>
            <p className="mt-4 text-gray-600">Loading journal entries...</p>
          </div>
        )}

        {isError && (
          <div
            className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-6"
            role="alert"
          >
            <p className="font-bold">Error</p>
            <p>
              {error.message ||
                "An error occurred while fetching journal entries."}
            </p>
          </div>
        )}

        {!isLoading && !isError && (!data || data.message?.length === 0) && (
          <p className="text-gray-600 text-center">No journal entries found.</p>
        )}

        {!isLoading &&
          !isError &&
          data &&
          data.message &&
          data.message.length > 0 && <DataGrid data={data.message} />}
      </div>
    </div>
  );
};

export default JournalPage;
