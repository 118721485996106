// Makes requests to CryptoCompare API
export async function makeApiRequest(path) {
  try {
    const response = await fetch(`https://min-api.cryptocompare.com/${path}`);
    return response.json();
  } catch (error) {
    throw new Error(`CryptoCompare request error: ${error.status}`);
  }
}

// Generates a symbol ID from a pair of the coins
export function generateSymbol(exchange, fromSymbol, toSymbol) {
  const short = `${fromSymbol}/${toSymbol}`;
  return {
    short,
    full: `${exchange}:${short}`,
  };
}

// Returns all parts of the symbol
export function parseFullSymbol(fullSymbol) {
  const match = fullSymbol.match(/^(\w+):(\w+)\/(\w+)$/);
  if (!match) {
    return null;
  }
  return { exchange: match[1], fromSymbol: match[2], toSymbol: match[3] };
}

export const getServerDetailsByGroup = (groupName) => {
  if (/ARENAS/i.test(groupName)) {
    return {
      SERVER_NAME: "Arenas Capitals Trader",
      SEVER_SUPPORT_EMAIL: "support@arenascapitals.com",
      SEVER_SUPPORT_PHONE: "+91 8888888888",
      ICON: "arena_icon.png",
      DEPOSIT_LINK: "https://arenascapitals.com/client/deposit.php",
      WITHDRAWAL_LINK: "https://arenascapitals.com/client/withdrawal.php"
    };
  } else if (/SKYCAP/i.test(groupName)) {
    return {
      SERVER_NAME: "SKYCAP Trader",
      SEVER_SUPPORT_EMAIL: "support@skycapmarket.com",
      SEVER_SUPPORT_PHONE: "+91 7777777777",
      ICON: "skycap_icon.png",
      DEPOSIT_LINK: "https://skycapmarket.com/qr_pay.php",
      WITHDRAWAL_LINK: "https://skycapmarket.com/direct_withdrawal.php"
    };
  } else if (/MMM/i.test(groupName)) {
    return {
      SERVER_NAME: "MMM Trader",
      SEVER_SUPPORT_EMAIL: "support@mmm.com",
      SEVER_SUPPORT_PHONE: "+91 7777777777",
      ICON: "million_icon.png",
      DEPOSIT_LINK: "https://moneymillionmarket.com/client/deposit.php",
      WITHDRAWAL_LINK: "https://moneymillionmarket.com/client/withdrawal.php"
    };
  } else if (/FFS/i.test(groupName)) {
    return {
      SERVER_NAME: "FFS Trader",
      SEVER_SUPPORT_EMAIL: "support@ffs.com",
      SEVER_SUPPORT_PHONE: "+91 6666666666",
      ICON: "forex_funding_icon.png",
      DEPOSIT_LINK: "https://forexfundingsolution.com/client/deposit.php",
      WITHDRAWAL_LINK: "https://forexfundingsolution.com/client/withdrawal.php"
    };
  }

  // Default values for other groups or if groupName is undefined
  return {
    SERVER_NAME: "Beta Trader (BT11)",
    SEVER_SUPPORT_EMAIL: "support@betatrader11.com",
    SEVER_SUPPORT_PHONE: "+91 9999999999",
    ICON: "icon.png",
    DEPOSIT_LINK: "https://betatrader11.com/client/deposit.php",
    WITHDRAWAL_LINK: "https://betatrader11.com/client/withdrawal.php"
  };
};
