import React, { useEffect, useState } from "react";
import DesktopView from "./DesktopView";
import MobileView from "./MobileView";
import MobileOptions from "./MobileOptions";
import client_socket from "../../../socket/client_socket";

const WatchListItem = ({
  currency: currencyItem,
  index,
  handleContextMenu,
  handleMobOptions,
  showMobOpt,
  setSelectedItem,
  handleSpecification,
  handleNewChart,
  handleHide,
  handleNewOrder,
}) => {
  const [currency, setCurrency] = useState(currencyItem);
 

  useEffect(() => {
    const handleNewMessage = (newMessageData) => {
      if (newMessageData?.newMessage.symbol === currency.symbol) {
        setCurrency((prevCurrency) => ({
          ...prevCurrency,
          ...newMessageData.newMessage,
        }));
      }
    };

    client_socket.on("newMessage", handleNewMessage);
    return () => client_socket.off("newMessage", handleNewMessage);
  }, [currency.symbol]);

  const sharedProps = {
    currency,
    handleContextMenu,
    setSelectedItem,
    handleMobOptions,
    index,
  };

  // console.log(currency);

  return (
    <div key={currency.symbol} className="relative overflow-hidden">
      <div className="transition-all duration-300 ease-in-out">
        {window.innerWidth <= 768 ? (
          <MobileView {...sharedProps} />
        ) : (
          <DesktopView {...sharedProps}  />
        )}
      </div>
      <div
        className={`fixed inset-0 z-50 bg-black transition-opacity duration-300 ease-in-out ${
          showMobOpt ? "opacity-50" : "opacity-0 pointer-events-none"
        }`}
        onClick={() => handleMobOptions(index)}
      ></div>
      <div
        className={`fixed inset-x-0 bottom-0 z-50 transition-transform duration-300 ease-in-out ${
          showMobOpt ? "translate-y-0" : "translate-y-full"
        }`}
      >
        {showMobOpt && (
          <MobileOptions
            currency={currency}
            handleMobOptions={() => handleMobOptions(index)}
            handleNewOrder={handleNewOrder}
            handleNewChart={handleNewChart}
            handleHide={handleHide}
            handleSpecification={handleSpecification}
          />
        )}
      </div>
    </div>
  );
};
export default WatchListItem;
