import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getAuthToken } from "../../helpers";
import {
  getClosedPositions,
  getPositions,
  getUser,
  getUserDeals,
  getUserOrders,
  getWatchlistData,
} from "../../api/queryFunctions";
import { fetchInitialData, setupSocketListeners } from "./dataFetchers";
import DesktopLayout from "./DesktopLayout";
import MobileLayout from "./MobileLayout";

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const { status, data: user } = useQuery({
    queryKey: ["user"],
    queryFn: getUser,
  });

  const { data: watchlistData } = useQuery({
    queryKey: ["getWatchlistData"],
    queryFn: getWatchlistData,
    refetchOnWindowFocus: false,
  });

  const { data: positions } = useQuery({
    queryKey: ["positions"],
    queryFn: () => getPositions(),
    refetchOnWindowFocus: false,
  });

  const { data: closedPositions } = useQuery({
    queryKey: ["closedPositions"],
    queryFn: () => getClosedPositions(),
    refetchOnWindowFocus: false,
  });

  const { data: orders } = useQuery({
    queryKey: ["orders"],
    queryFn: () => getUserOrders(),
    refetchOnWindowFocus: false,
  });

  const { data: deals } = useQuery({
    queryKey: ["deals"],
    queryFn: () => getUserDeals(),
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    const updateFaviconAndTitle = () => {
      const favicon = document.getElementById("favicon");
      if (!favicon) return;
      const groupName = user?.group?.Name;
      if (/ARENAS/i.test(groupName)) {
        document.title = "Arenas Capitals Trader";
        favicon.href = `./arena_icon.png`;
      } else if (/SKYCAP/i.test(groupName)) {
        document.title = "SKYCAP Trader";
        favicon.href = `./skycap_icon.png`;
      } else if (/MMM/i.test(groupName)) {
        document.title = "MMM Trader";
        favicon.href = `./million_icon.png`;
      } else if (/FFS/i.test(groupName)) {
        document.title = "FFS Trader";
        favicon.href = `./forex_funding_icon.png`;
      }
    };
    updateFaviconAndTitle();
  }, [user]);

  useEffect(() => {
    const authToken = getAuthToken();
    if (authToken) {
      fetchInitialData(dispatch);
    }
  }, [dispatch]);

  useEffect(() => {
    if (!getAuthToken() || status === "error") {
      navigate("/login");
    }
  }, [status, navigate]);

  useEffect(() => {
    setupSocketListeners(dispatch);
  }, [dispatch]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="transition-all duration-300 ease-in-out">
      {isMobile ? <MobileLayout /> : <DesktopLayout />}
    </div>
  );
};

export default Home;
