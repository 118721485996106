import React from "react";
import SymbolDelete from "../components/watchlist_search/symbolDelete/SymbolDelete";

const RemoveSymbolsPage = () => {
  return (
    <div>
      <SymbolDelete />
    </div>
  );
};

export default RemoveSymbolsPage;
