import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../api/apiClient";

const initialState = {
  userData: null,

  allSymbols: null,
  loop: true,
};

export const fetchUserData = createAsyncThunk(
  "onLoad/fetchUserData",
  async () => {
    const response = await apiClient.get("user");
    return response.data;
  }
);

export const fetchSymbols = createAsyncThunk(
  "onLoad/fetchSymbols",
  async () => {
    const response = await apiClient.get("get-group-symbols");
    return response.data.symbolData;
  }
);

const onLoadSlice = createSlice({
  name: "onLoad",
  initialState,
  reducers: {
    resetState: (state) => {
      state = {
        userData: null,

        allSymbols: null,
      };
    },

    resetOnLoad: (state) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserData.fulfilled, (state, action) => {
        state.userData = action.payload;
      })

      .addCase(fetchSymbols.fulfilled, (state, action) => {
        state.allSymbols = action.payload;
      });
  },
});

export const { resetState: resetOnLoadState } = onLoadSlice.actions;

export default onLoadSlice.reducer;
