import React, { useState } from "react";
import { useSelector } from "react-redux";
import MobileHeader from "./MobileHeader";
import MobileContent from "./MobileContent";
import BottomTab from "../navbars/bottomTab/BottomTab";
import SidebarMenu from "../navbars/menuSidebar/SideBarMob";

const MobileLayout = () => {
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const [dropdownStates, setDropdownStates] = useState({
    isDropdownOpen: false,
    isSortingOpen: false,
    isDateSortingOpen: false,
  });

  const showComponent = useSelector((state) => state.mobileView);

  const toggleDropdown = (dropdownType) => {
    setDropdownStates((prevState) => {
      const newState = {
        isDropdownOpen: false,
        isSortingOpen: false,
        isDateSortingOpen: false,
      };
      newState[dropdownType] = !prevState[dropdownType];
      return newState;
    });
  };

  const handleSideMenuBar = () => {
    setIsSidebarVisible((prev) => !prev);
  };

  return (
    <div className="flex flex-col h-screen">
    <div className="flex flex-col h-full pb-20">
    <SidebarMenu
        isVisible={isSidebarVisible}
        setIsVisible={setIsSidebarVisible}
      />
      <MobileHeader
        showComponent={showComponent}
        handleSideMenuBar={handleSideMenuBar}
        toggleDropdown={() => toggleDropdown("isDropdownOpen")}
        toggleSortingDropDown={() => toggleDropdown("isSortingOpen")}
        toggleSortingDateDropDown={() => toggleDropdown("isDateSortingOpen")}
        {...dropdownStates}
      />
      <MobileContent
        showComponent={showComponent}
        isDropdownOpen={dropdownStates.isDropdownOpen}
      />
    </div>
      <BottomTab />
    </div>
  );
};

export default MobileLayout;
