import React, { useState, useCallback, useMemo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  dateFilterOtherType,
  filterDealSymbol,
  filterNameSelect,
  filterOrderSymbol,
  filterSymbol,
  customDateFilter,
} from "../../../store/filterDataMobScreenSlice/FilterHistoryScreen";
import { MdOutlineCalendarMonth, MdEditCalendar } from "react-icons/md";
import { IoCalendarNumberOutline, IoCheckmark } from "react-icons/io5";
import { FaCalendar } from "react-icons/fa";
import { LiaCalendarWeekSolid } from "react-icons/lia";

import { filterDataByDate } from "../../../helpers/SortingAndFiltering";
import { getCurrentDateFormatted } from "../../../helpers";
import { useQueryClient } from "@tanstack/react-query";

const DateFilterSort = ({ isDateSortingOpen, handleClose }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { dateType } = useSelector((state) => state.filterDataScreen);
  const userClosedPositions = queryClient.getQueryData(["closedPositions"]);
  const userDeals = queryClient.getQueryData(["deals"]);
  const userOrders = queryClient.getQueryData(["orders"]);
  const mode = useSelector((state) => state.mode.mode);
  const [typeNameDate, setTypeNameDate] = useState(dateType);
  const [customDates, setCustomDates] = useState({
    startDate: null,
    endDate: null,
  });

  const calcNewRangeData = useCallback(
    (startDate = null, endDate = null) => {
      const dataMap = {
        closedPosition: { data: userClosedPositions, action: filterSymbol },
        deals: { data: userDeals, action: filterDealSymbol },
        orders: { data: userOrders, action: filterOrderSymbol },
      };

      const { data, action } = dataMap[mode] || {};
      if (data && action) {
        dispatch(
          action(filterDataByDate(data, startDate, endDate, typeNameDate))
        );
      }
    },
    [mode, userClosedPositions, userDeals, userOrders, typeNameDate, dispatch]
  );

  useEffect(() => {
    calcNewRangeData(customDates.startDate, customDates.endDate);
  }, [typeNameDate, customDates, calcNewRangeData]);

  const handleDateFilter = useCallback(
    (typeName) => {
      setTypeNameDate(typeName);
      dispatch(dateFilterOtherType(typeName));
      dispatch(filterNameSelect(typeName));
      if (typeName !== "custom") {
        calcNewRangeData();
      }
    },
    [dispatch, calcNewRangeData]
  );

  const handleCustomDateChange = useCallback(
    (type, value) => {
      setCustomDates((prev) => {
        const newDates = { ...prev, [type]: value };
        dispatch(customDateFilter(newDates));
        return newDates;
      });
    },
    [dispatch]
  );

  const dateTypes = useMemo(
    () => [
      { type: "Today", icon: <MdOutlineCalendarMonth size={25} /> },
      { type: "lastWeek", icon: <LiaCalendarWeekSolid size={25} /> },
      { type: "lastMonth", icon: <FaCalendar size={25} /> },
      { type: "last3month", icon: <IoCalendarNumberOutline size={25} /> },
      { type: "custom", icon: <MdEditCalendar size={25} /> },
    ],
    []
  );

  if (!isDateSortingOpen) return null;

  return (
    <div className="absolute right-10 mt-2 z-10 bg-white divide-y divide-gray-600 rounded-lg shadow w-64 dark:bg-gray-700 dark:divide-gray-600">
      <ul className="py-2 text-sm text-gray-700 dark:text-gray-200">
        {dateTypes.map(({ type, icon }) => (
          <li
            key={type}
            className="border-b border-gray-300 last:border-b-0 dark:border-gray-600"
          >
            <button
              onClick={() => handleDateFilter(type)}
              className={`w-full text-left px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white flex flex-row items-center ${
                typeNameDate === type ? "bg-gray-100 dark:bg-gray-600" : ""
              }`}
            >
              <span>{icon}</span>
              <span className="ml-2">
                {type === "custom"
                  ? "Custom period"
                  : type.replace(/([A-Z])/g, " $1").trim()}
              </span>
              {typeNameDate === type && (
                <IoCheckmark size={25} className="ml-auto text-blue-600" />
              )}
            </button>
            {typeNameDate === "custom" && type === "custom" && (
              <div className="px-4 py-2 bg-gray-50 dark:bg-gray-600">
                <div className="flex flex-col space-y-2">
                  {["startDate", "endDate"].map((dateType) => (
                    <div key={dateType} className="flex items-center">
                      <label
                        htmlFor={dateType}
                        className="w-20 text-xs font-medium text-gray-700 dark:text-gray-300"
                      >
                        {dateType === "startDate" ? "Start" : "End"}
                      </label>
                      <input
                        id={dateType}
                        type="date"
                        onChange={(e) =>
                          handleCustomDateChange(dateType, e.target.value)
                        }
                        defaultValue={
                          dateType === "startDate"
                            ? "2020-01-01"
                            : getCurrentDateFormatted()
                        }
                        className="flex-1 p-1 text-xs border rounded focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default React.memo(DateFilterSort);
