const InstantOrderSkeleton = () => {
    return (
      <div className="bg-gray-200/20 border border-gray-100 rounded-md p-2 w-full flex md:block items-center justify-center text-sm animate-pulse">
        <div className="flex items-center space-x-2">
          <div className="h-6 w-20 bg-gray-300 rounded-md hidden md:block"></div>
          <div className="h-8 w-28 md:w-32 bg-gray-300 rounded-md"></div>
          <div className="h-8 w-24 bg-gray-300 rounded-md"></div>
          <div className="h-8 w-28 md:w-32 bg-gray-300 rounded-md"></div>
        </div>
      </div>
    );
  };
  
  export default InstantOrderSkeleton;
  