
import React, { useState } from "react";
import { setShowBulkOperations } from "../../store/mobileViewSlice";
import { useDispatch } from "react-redux";
import { RiCloseFill } from "react-icons/ri";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import apiClient from "../../api/apiClient";
import toast, { Toaster } from "react-hot-toast";

const BulkOperations = () => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async (data) => {
      const res = await apiClient.post(`/${data.action}`);
      return res;
    },
    onError: (error) => {
      toast.error(error.message);
    },
    onSettled: (data) => {
      dispatch(setShowBulkOperations(false));
      toast.success(data?.data?.message);
      queryClient.invalidateQueries(["positions","orders", "deals", "closedPositions"]);
     
   
    },
  });

  const items = [
    "Close All Positions",
    "Close Profit Positions",
    "Close Loss Positions",
  ];

  const handleItemClick = (index) => {
    setSelectedItem(index);
    setShowConfirmation(true);
  };

  const handleConfirm = () => {
    switch (selectedItem) {
      case 0:
        mutation.mutate({ action: "exit-all-positions" });
        break;
      case 1:
        mutation.mutate({ action: "exit-profit-positions" });
        break;
      case 2:
        mutation.mutate({ action: "exit-loss-positions" });
        break;

      default:
        console.log("Invalid selection");
    }
    setShowConfirmation(false);
  };

  const handleCancel = () => {
    setSelectedItem(null);
    setShowConfirmation(false);
  };

  return (
    <div className="p-4 md:p-1  w-full md:w-56  h-full rounded-md absolute z-10 bg-white border-2 border-gray-200">
      <div className="flex justify-between items-center mb-4 bg-blue-100 p-2 rounded-md">
        <h2 className="text-xl font-semibold text-blue-500 md:text-sm ">
          Bulk Close Operations
        </h2>

        <RiCloseFill
          onClick={() => dispatch(setShowBulkOperations())}
          size={24}
          className="text-red-500 hover:bg-blue-200 rounded-md"
        />
      </div>
      <ul className="space-y-2">
        {items.map((item, index) => (
          <li
            key={index}
            className={` uppercase cursor-pointer p-2 rounded text-sm md:text-xs text-gray-700 font-semibold border-b border-gray-200 hover:bg-blue-500 hover:text-white ${
              selectedItem === index ? "bg-blue-500 text-white" : ""
            }`}
            onClick={() => handleItemClick(index)}
          >
            {item}
          </li>
        ))}
      </ul>

      {showConfirmation && (
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center rounded-md">
          <div className="bg-white p-4 rounded-md shadow-lg">
            <p className="mb-4">
              Are you sure you want to {items[selectedItem]}?
            </p>
            <div className="flex justify-end space-x-2">
              <button
                className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
                onClick={handleCancel}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                onClick={handleConfirm}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}

      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          className: "",
          duration: 5000,
          style: {
            background: "#363636",
            color: "#fff",
          },
          success: {
            duration: 1000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />
    </div>
  );
};

export default BulkOperations;
