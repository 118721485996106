import React, { useState, useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IoIosArrowRoundUp, IoIosArrowRoundDown } from "react-icons/io";
import {
  filterSymbol,
  filterDealSymbol,
  filterOrderSymbol,
  filterSortRunPen,
  filterSortPenPos,
} from "../../../store/filterDataMobScreenSlice/FilterHistoryScreen";
import { sortFilterData } from "../../../helpers/SortingAndFiltering";
import { useQueryClient } from "@tanstack/react-query";

const COLUMN_HEADERS = {
  closedPosition: [
    "symbol",
    "ticket",
    "type",
    "volume",
    "openTime",
    "closeTime",
    "profit",
  ],
  deals: ["symbol", "deal", "type", "volume", "time", "profit"],
  orders: [
    "symbol",
    "order",
    "type",
    "volume",
    "openTime",
    "closeTime",
    "state",
  ],
  running: ["symbol", "order", "Time", "profit"],
  pending: ["symbol", "order", "Time", "profit"],
};

const Sorting = ({ setSortingOpen }) => {
  const [sortBy, setSortBy] = useState("symbol");
  const [sortDirection, setSortDirection] = useState("asc");
  const queryClient = useQueryClient();
  const positions = queryClient.getQueryData(["positions"])?.positions;

  const dispatch = useDispatch();
  const mode = useSelector((state) => state.mode.mode);

  const { filterSymbolData, filterDealData, filterOrderData } = useSelector(
    (state) => state.filterDataScreen
  );

  const handleSort = useCallback(
    (field) => {
      setSortBy((prevSortBy) => {
        const newDirection =
          prevSortBy === field && sortDirection === "asc" ? "desc" : "asc";
        setSortDirection(newDirection);
        return field;
      });
    },
    [sortDirection]
  );

  const sortedData = useMemo(() => {
    const dataMap = {
      closedPosition: filterSymbolData,
      deals: filterDealData,
      orders: filterOrderData,
      running: positions.filter((p) => p.closed === 0),
      pending: positions.filter((p) => p.closed === 1),
    };

    return sortFilterData(dataMap[mode] || [], sortBy, sortDirection);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, sortBy, sortDirection, positions]);

  useMemo(() => {
    const actionMap = {
      closedPosition: filterSymbol,
      deals: filterDealSymbol,
      orders: filterOrderSymbol,
      running: filterSortRunPen,
      pending: filterSortPenPos,
    };

    const action = actionMap[mode];
    if (action) {
      dispatch(action(sortedData));
    }
  }, [dispatch, mode, sortedData]);

  const arrayData = useMemo(() => COLUMN_HEADERS[mode] || [], [mode]);

  return (
    <div className="relative">
      <div
        id="dropdownDivider"
        className="absolute right-0 mt-2 z-10 bg-white divide-y divide-gray-200 rounded-lg shadow-lg w-32 dark:bg-gray-800 dark:divide-gray-700"
      >
        {arrayData.length ? (
          <ul className="py-1">
            {arrayData.map((field) => (
              <li
                key={field}
                onClick={() => handleSort(field)}
                className="px-4 py-2 text-base text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 cursor-pointer flex justify-between items-center"
              >
                <span className="capitalize">{field}</span>
                {sortBy === field &&
                  (sortDirection === "asc" ? (
                    <IoIosArrowRoundUp className="text-blue-600" size={20} />
                  ) : (
                    <IoIosArrowRoundDown className="text-blue-600" size={20} />
                  ))}
              </li>
            ))}
          </ul>
        ) : (
          <div className="py-2 px-4 text-sm text-gray-700 dark:text-gray-200">
            No symbols found
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(Sorting);
