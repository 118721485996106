import apiClient from "../api/apiClient";
import { updateUser } from "./userSlice";

export const fetchInitialWalletInfo = () => async (dispatch) => {
    try {
        const { data } = await apiClient.get("user");
            const { _id, name, email, balance, credit, margin } = data?.user;

            const wallet = {
                id: _id,
                name,
                email,
                balance: balance?.toFixed(2),
                equity: balance?.toFixed(2),
                credit,
                margin, 
                freeMargin: balance?.toFixed(2),
                level: 0,
                pl: 0
            };

        dispatch(updateUser(wallet));
    } catch (error) {
       
        throw error;
    }
};
