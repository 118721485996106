export const sortData = (data, sortBy) => {
  return data?.slice().sort((a, b) => {
    if (sortBy === "symbol") {
      return a?.symbol?.localeCompare(b.symbol);
    } else if (sortBy === "ticket") {
      return a.ticket - b.ticket;
    } else if (sortBy === "type") {
      return a.type - b.type;
    } else if (sortBy === "volume") {
      return a.volume - b.volume;
    } else if (sortBy === "openTime") {
      return new Date(a.createdAt) - new Date(b.createdAt);
    } else if (sortBy === "closeTime") {
      return new Date(a.closeTime) - new Date(b.closeTime);
    } else if (sortBy === "profit") {
      return a.profit - b.profit;
    }
    return 0;
  });
};

export const sortFilterData = (data, sortBy, direction = "asc") => {
  return data?.slice().sort((a, b) => {
    let result = 0;

    if (sortBy === "symbol") {
      result = a?.symbol?.localeCompare(b.symbol);
    } else if (sortBy === "ticket" || sortBy === "deal" || sortBy === "order") {
      result = a.ticket - b.ticket;
    } else if (sortBy === "type") {
      result = a.type - b.type;
    } else if (sortBy === "volume") {
      result = a.volume - b.volume;
    } else if (sortBy === "openTime") {
      result = new Date(a.createdAt) - new Date(b.createdAt);
    } else if (sortBy === "closeTime") {
      result = new Date(a.closeTime) - new Date(b.closeTime);
    } else if (sortBy === "profit") {
      result = a.profit - b.profit;
    } else if (sortBy === "time") {
      result = new Date(a.updatedAt) - new Date(b.updatedAt);
    } else if (sortBy === "state") {
      if (a.status === b.status) {
        result = 0;
      } else if (a.status === 1) {
        result = -1; // status 1 should come first
      } else if (b.status === 1) {
        result = 1; // status 1 should come first
      } else {
        result = a.status - b.status; // default sorting for other status values
      }
    }

    return direction === "asc" ? result : -result;
  });
};

export const filterDataByDate = (data, startDate, endDate, dateType) => {
  const getDateRange = (start, end) => {
    return data?.filter((item) => {
      const itemDate = new Date(item.closeTime || item.createdAt);
      return itemDate >= start && itemDate <= end;
    });
  };

  const today = new Date();
  const endOfDay = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate(),
    23,
    59,
    59
  );

  switch (dateType) {
    case "Today":
      return getDateRange(new Date(today.setHours(0, 0, 0, 0)), endOfDay);
    case "lastWeek":
      return getDateRange(
        new Date(today.setDate(today.getDate() - 7)),
        endOfDay
      );
    case "lastMonth":
      return getDateRange(
        new Date(today.setMonth(today.getMonth() - 1)),
        endOfDay
      );
    case "last3month":
      return getDateRange(
        new Date(today.setMonth(today.getMonth() - 3)),
        endOfDay
      );
    default:
      if (!startDate && !endDate) {
        const threeMonthsAgo = new Date(today.setMonth(today.getMonth() - 3));
        return getDateRange(threeMonthsAgo, endOfDay);
      }
      const filterEndDate = new Date(endDate);
      filterEndDate.setHours(23, 59, 59);
      return getDateRange(new Date(startDate), filterEndDate);
  }
};

const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};
