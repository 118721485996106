import React from "react";
import { AiOutlineWallet, AiOutlineUser } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { changeTab } from "../../../store/mobileViewSlice";
import { LuArrowDownUp } from "react-icons/lu";
import { RiHistoryLine } from "react-icons/ri";
import { BiCandles } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { changeMode } from "../../../store/modeSlice";

const BottomTab = () => {
  const dispatch = useDispatch();
  const activeTab = useSelector((state) => state.mobileView);
  const navigate = useNavigate();

  const tabs = [
    {
      name: "watchlist",
      icon: <LuArrowDownUp size={24} />,
      label: "Quotes",
      link: "watchlist",
    },
    {
      name: "chart",
      icon: <BiCandles size={24} />,
      label: "Chart",
      link: "terminal",
    },

    {
      name: "wallet",
      icon: <AiOutlineWallet size={24} />,
      label: "Trade",
      link: "trades",
    },
    {
      name: "detail",
      icon: <RiHistoryLine size={24} />,
      label: "History",
      link: "history",
    },
    {
      name: "account",
      icon: <AiOutlineUser size={24} />,
      label: "Account",
      link: "account",
    },
  ];

  return (
    <div className="fixed bottom-0 w-full h-16 bg-white border-t-2 border-gray-300 shadow-lg flex justify-around items-center z-50 rounded-t-lg">
      {tabs.map((tab) => (
        <div
          key={tab.name}
          className={`flex flex-col items-center justify-center transition-colors ease-in-out duration-300 ${
            activeTab[tab.name]
              ? "text-blue-500"
              : "text-gray-700 hover:text-blue-500"
          }`}
          onClick={() => {
            dispatch(changeTab(tab.name));
            if (tab.name === "detail") {
              dispatch(changeMode("closedPosition"));
            }
          }}
        >
          {tab.icon}
          <span className="text-sm">{tab.label}</span>
        </div>
      ))}
    </div>
  );
};

export default BottomTab;
