import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  show: true,
  showOptions: false,
  showTradeForm: false,
};

const watchlistSlice = createSlice({
  name: "watchlist",
  initialState: initialState,
  reducers: {
    toggleWatchList: (state) => {
      state.show = !state.show;
    },
    changeShowOptions: (state, action) => {
      state.showOptions = action.payload;
    },
    changeShowTradeForm: (state, action) => {
      if (action.payload === true) {
        state.showTradeForm = action.payload;
      } else if (action.payload === false) {
        state.showTradeForm = action.payload;
      } else {
        state.showTradeForm = !state.showTradeForm;
      }
    },
    updateWatchListData: (state, action) => {
      state.data = action.payload;
    },

    resetState: () => {
      return initialState;
    },
  },
});

export const {
  toggleWatchList,
  changeShowOptions,
  updateWatchListData,
  changeShowTradeForm,
  resetState: resetWatchlistState,
} = watchlistSlice.actions;

export default watchlistSlice.reducer;
