import React, { useEffect, useState } from "react";
import SearchBar from "./search/SearchBar";
import WatchList from "./watchlist/WatchList";
import { useSelector } from "react-redux";
import Menu from "../navbars/menu/Menu";
import { PiUserCircleThin } from "react-icons/pi";
import { useQueryClient } from "@tanstack/react-query";
import { getServerDetailsByGroup } from "../ATVC/helpers";

const SearchWatchListSection = () => {
  const queryClient = useQueryClient();
  const isDetailVisible = useSelector((state) => state.mode.show);
  const chartHeight = useSelector((state) => state.resizable.chartHeight);
  const [show, setShow] = useState(false);
  const data = queryClient.getQueryData(["user"]);

  const [serverDetails, setServerDetails] = useState({
    DEPOSIT_LINK: "https://betatrader11.com/client/deposit.php",
    WITHDRAWAL_LINK: "https://betatrader11.com/client/withdrawal.php",
  });

  useEffect(() => {
    if (data?.group?.Name) {
      const details = getServerDetailsByGroup(data?.group?.Name);
      setServerDetails(details);
    }
  }, [data]);

  const renderDepositWithdrawLinks = () => {
    if (data?.user && data?.user.name !== "DEMO USER") {
      return (
        <>
          <span className="font-bold text-gray-600 p-1 rounded-sm  cursor-pointer hover:bg-gray-200">
            <a
              href={serverDetails.DEPOSIT_LINK}
              target="_blank"
              rel="noreferrer"
            >
              Deposit
            </a>
          </span>
          <span className="font-bold text-gray-600 p-1 rounded-sm cursor-pointer hover:bg-gray-200">
            <a
              href={serverDetails.WITHDRAWAL_LINK}
              target="_blank"
              rel="noreferrer"
            >
              Withdraw
            </a>
          </span>
        </>
      );
    }
    return null;
  };

  return (
    <div>
      <div
        className="relative mb-2"
        style={{
          height:
            isDetailVisible && window.innerWidth > 800
              ? `${chartHeight}dvh`
              : "88vh",
        }}
      >
        <div className="border-b-[5px] border-gray-200 mb-2 p-1 flex w-full justify-end items-center gap-3 text-sm">
          {renderDepositWithdrawLinks()}
          <div onClick={() => setShow(!show)}>
            <PiUserCircleThin
              size={30}
              className="cursor-pointer text-red-600"
            />
          </div>
        </div>
        <SearchBar />
        <WatchList />
        {show && <Menu />}
      </div>
    </div>
  );
};

export default SearchWatchListSection;
