import React, { useState, useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";
import { changeSymbol } from "../../../store/chartSlice";

const AddSymbol = () => {
  const dispatch = useDispatch();
  const showComponent = useSelector((state) => state.mobileView.tradeForm);
  const queryClient = useQueryClient();
  const watchlistData = queryClient.getQueryData(["getWatchlistData"]) || [];

  const handleSymbolFilter = useCallback(
    (symbol) => {
      dispatch(changeSymbol(symbol));
    },
    [dispatch]
  );

  const symbolList = useMemo(
    () =>
      watchlistData.map((sym) => (
        <li
          key={sym.symbol}
          className="border-b border-gray-300 last:border-b-0 dark:border-gray-600"
        >
          <button
            onClick={() => handleSymbolFilter(sym.symbol)}
            className="w-full text-left px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
          >
            {sym.symbol}
          </button>
        </li>
      )),
    [watchlistData, handleSymbolFilter]
  );

  if (!showComponent) return null;

  return (
    <div>
      <div
        id="dropdownDivider"
        className="absolute right-0 mt-2 z-10 bg-white divide-y divide-gray-600 rounded-lg shadow w-32 dark:bg-gray-700 dark:divide-gray-600 mr-10"
      >
        {watchlistData.length ? (
          <ul
            className="py-2 text-sm text-gray-700 dark:text-gray-200"
            aria-labelledby="dropdownDividerButton"
          >
            {symbolList}
          </ul>
        ) : (
          <div className="py-2 px-4 text-sm text-gray-700 dark:text-gray-200">
            No symbols found
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(AddSymbol);
