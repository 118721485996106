// import React, { useEffect, useState } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import { useMutation, useQueryClient } from "@tanstack/react-query";
// import { setAuthCred, setAuthToken, setUserChangeTo } from "../../../helpers";
// import { signUp } from "../../../api/queryFunctions";
// import { set } from "lodash";

// const SignUp = () => {
//   const [isProceedVisible, setIsProceedVisible] = useState(false);
//   const [error, setError] = useState(null);
//   const [disabled, setDisabled] = useState(false);

//   const {
//     data: response,
//     isSuccess,
//     mutate: post,
//     isError,
//   } = useMutation({
//     mutationKey: "signup",
//     mutationFn: signUp,
//     onSuccess: (data) => {
//        const res = data?.data
//       setAuthCred(res);
//       setUserChangeTo({
//         userId: res?.user?.userId,
//         password: res?.user?.password,
//         name: res?.user?.name,
//       });
//       setIsProceedVisible(true);
//     },
//     onError: () => {
//       setError("Error Occurred, try again");
//     }
//   });

//   const navigate = useNavigate();
//   const queryClient = useQueryClient();

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     setDisabled(true);
//     post();
//   };

//   const handleProceed = () => {
//     const queryKeys = [
//       "user",
//       "positions",
//       "closedPositions",
//       "orders",
//       "deals",
//     ];
//     queryClient.invalidateQueries(queryKeys);
//     navigate("/");
//   };

//   return (
//     <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-blue-300 to-blue-700 text-white">
//       <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md fade-in">
//         <h2 className="text-2xl font-bold mb-8 text-center text-blue-700">
//           Sign Up
//         </h2>
//         <form onSubmit={handleSubmit}>
//           {isError && (
//             <p className="text-red-500 text-center mb-4">
//               {error}
//             </p>
//           )}
//           {isProceedVisible ? (
//             <div className="mb-4 p-4 rounded-lg border-2 border-gray-100 bg-blue-400">
//               <div className="flex flex-col gap-2">
//                 <div className="flex justify-between">
//                   <p className="font-semibold">User ID:</p>
//                   <span>{response?.data?.user?.userId}</span>
//                 </div>
//                 <div className="flex justify-between">
//                   <p className="font-semibold">Password:</p>
//                   <span>{response?.data?.user?.password}</span>
//                 </div>
//                 <div className="flex justify-between">
//                   <p className="font-semibold">Balance:</p>
//                   <span>{response?.data?.user?.balance}</span>
//                 </div>
//               </div>
//             </div>
//           ) : (
//             <button
//               type="submit"
//               className={`w-full py-3 rounded-lg transition duration-300 ease-in-out ${
//                 disabled
//                   ? "bg-gray-400 cursor-not-allowed"
//                   : "bg-gradient-to-r from-green-400 to-blue-500 text-white"
//               }`}
//               id="signup"
//               disabled={disabled}
//             >
//               Open A Demo Account
//             </button>
//           )}
//           {isProceedVisible && (
//             <button
//               type="button"
//               className="w-full mt-4 bg-green-500 text-white py-3 rounded-lg hover:bg-green-600 transition duration-300 ease-in-out"
//               onClick={handleProceed}
//             >
//               Proceed To Login
//             </button>
//           )}
//         </form>
//         <div className="text-center mt-4">
//           <Link
//             className="text-blue-500 hover:text-blue-700 transition duration-300 ease-in-out"
//             to="/login"
//           >
//             Login Here
//           </Link>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default SignUp;

"use client";

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { setAuthCred, setUserChangeTo } from "../../../helpers";
import { signUp } from "../../../api/queryFunctions";

export default function SignUp() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobileNumber: "",
    password: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({});
  const [isProceedVisible, setIsProceedVisible] = useState(false);
  const [error, setError] = useState(null);
  const [disabled, setDisabled] = useState(false);

  const {
    data: response,
    isSuccess,
    mutate: post,
    isError,
  } = useMutation({
    mutationKey: "signup",
    mutationFn: (data) => signUp(data),
    onSuccess: (data) => {
      
      const res = data?.data;
      console.log(res, "data")
      setAuthCred(res);
      setUserChangeTo({
        userId: res?.user?.userId,
        password: res?.user?.password,
        name: res?.user?.name,
        type: res?.user?.type,
      });
      setIsProceedVisible(true);
    },
    onError: () => {
      setError("Error Occurred, try again");
    },
  });

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name.trim()) newErrors.name = "Name is required";
    if (!formData.email.trim()) newErrors.email = "Email is required";
    else if (!/\S+@\S+\.\S+/.test(formData.email))
      newErrors.email = "Email is invalid";
    if (!formData.password) newErrors.password = "Password is required";
    else if (formData.password.length < 6)
      newErrors.password = "Password must be at least 6 characters";
    if (formData.password !== formData.confirmPassword)
      newErrors.confirmPassword = "Passwords do not match";
    if (!formData.mobileNumber.trim())
      newErrors.mobileNumber = "Mobile number is required";
    else if (!/^\+?[1-9]\d{1,14}$/.test(formData.mobileNumber))
      newErrors.mobileNumber = "Invalid mobile number format";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setDisabled(true);
      post({ tradePassword: formData.password , type: 0, name: formData.name, userEmail: formData.email, mobile: formData.mobileNumber});
    }
  };

  const handleProceed = () => {
    const queryKeys = [
      "user",
      "positions",
      "closedPositions",
      "orders",
      "deals",
    ];
    queryClient.invalidateQueries(queryKeys);
    navigate("/");
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-blue-300 to-blue-700">
      <div className="w-full max-w-md bg-white rounded-lg shadow-lg p-8">
        <h2 className="text-2xl font-bold text-center text-blue-700 mb-6">
          Sign Up
        </h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          {isError && (
            <div
              className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
              role="alert"
            >
              <strong className="font-bold">Error!</strong>
              <span className="block sm:inline"> {error}</span>
            </div>
          )}
          {!isProceedVisible ? (
            <>
              <div>
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Name
                </label>
                <input
                  id="name"
                  name="name"
                  type="text"
                  required
                  className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md text-sm  placeholder-gray-400
                    focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Enter your name"
                />
                {errors.name && (
                  <p className="mt-1 text-sm text-red-600">{errors.name}</p>
                )}
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  required
                  className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md text-sm  placeholder-gray-400
                    focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Enter your email"
                />
                {errors.email && (
                  <p className="mt-1 text-sm text-red-600">{errors.email}</p>
                )}
              </div>
              <div>
                <label
                  htmlFor="mobileNumber"
                  className="block text-sm font-medium text-gray-700"
                >
                  Mobile Number
                </label>
                <input
                  id="mobileNumber"
                  name="mobileNumber"
                  type="tel"
                  required
                  className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md text-sm placeholder-gray-400
      focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                  value={formData.mobileNumber}
                  onChange={handleChange}
                  placeholder="Enter your mobile number"
                />
                {errors.mobileNumber && (
                  <p className="mt-1 text-sm text-red-600">
                    {errors.mobileNumber}
                  </p>
                )}
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  required
                  className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md text-sm  placeholder-gray-400
                    focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                  value={formData.password}
                  onChange={handleChange}
                  placeholder="Enter your password"
                />
                {errors.password && (
                  <p className="mt-1 text-sm text-red-600">{errors.password}</p>
                )}
              </div>
              <div>
                <label
                  htmlFor="confirmPassword"
                  className="block text-sm font-medium text-gray-700"
                >
                  Confirm Password
                </label>
                <input
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  required
                  className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md text-sm  placeholder-gray-400
                    focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  placeholder="Confirm your password"
                />
                {errors.confirmPassword && (
                  <p className="mt-1 text-sm text-red-600">
                    {errors.confirmPassword}
                  </p>
                )}
              </div>
              <button
                type="submit"
                className={`w-full py-2 px-4 border border-transparent rounded-md  text-sm font-medium text-white 
                  ${
                    disabled
                      ? "bg-gray-400 cursor-not-allowed"
                      : "bg-gradient-to-r from-green-400 to-blue-500 text-white"
                  }`}
                disabled={disabled}
              >
                Open A Demo Account
              </button>
            </>
          ) : (
            <div className="space-y-4">
              <div className="bg-blue-50 border border-blue-200 rounded-lg p-4">
                <div className="space-y-2">
                  <div className="flex justify-between">
                    <p className="font-semibold">User ID:</p>
                    <span>{response?.data?.user?.userId}</span>
                  </div>
                  <div className="flex justify-between">
                    <p className="font-semibold">Password:</p>
                    <span>{response?.data?.user?.password}</span>
                  </div>
                  <div className="flex justify-between">
                    <p className="font-semibold">Balance:</p>
                    <span>{response?.data?.user?.balance}</span>
                  </div>
                </div>
              </div>
              <button
                onClick={handleProceed}
                className="w-full py-2 px-4 border border-transparent rounded-md  text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              >
                Proceed To Login
              </button>
            </div>
          )}
        </form>
        <div className="mt-6 text-center">
          <Link className="text-sm text-blue-600 hover:underline" to="/login">
            Already have an account? Login here
          </Link>
        </div>
      </div>
    </div>
  );
}
