import React, { useCallback, useMemo, useState } from "react";
import { IoMdMenu, IoIosArrowRoundBack } from "react-icons/io";
import { RiArrowUpDownLine, RiExchangeDollarLine } from "react-icons/ri";
import { SlCalender } from "react-icons/sl";
import { MdOutlineNoteAdd, MdOutlineModeEditOutline } from "react-icons/md";
import { AiOutlinePlus } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { changeTab } from "../../store/mobileViewSlice";
import { Link, useNavigate } from "react-router-dom";
import FilterSymbol from "../mobileDeviceIconToggle/history/FilterSymbol";
import Sorting from "../mobileDeviceIconToggle/history/Sorting";
import DateFilterSort from "../mobileDeviceIconToggle/history/DateFilterSort";
import { IoAdd } from "react-icons/io5";
import { changeMode } from "../../store/modeSlice";
import { LiaMailBulkSolid } from "react-icons/lia";
import BulkOperations from "../BulkOperations/BulkOperations";

const IconToggle = React.memo(({ icon: Icon, onClick, isOpen, children }) => (
  <div className="relative">
    <button
      className="p-2 rounded-full hover:bg-gray-200 transition-colors duration-200"
      onClick={onClick}
    >
      <Icon size={24} />
    </button>
    {isOpen && children}
  </div>
));

const HeaderButton = React.memo(({ icon: Icon, onClick, text }) => (
  <button
    className="flex items-center space-x-2 p-2 rounded-lg hover:bg-gray-200 transition-colors duration-200"
    onClick={onClick}
  >
    <Icon size={24} />
    {text && <span className="text-sm font-medium">{text}</span>}
  </button>
));

const MobileHeader = ({
  showComponent,
  handleSideMenuBar,
  toggleDropdown,
  toggleSortingDropDown,
  toggleSortingDateDropDown,
  isDropdownOpen,
  isSortingOpen,
  isDateSortingOpen,
}) => {
  const dispatch = useDispatch();
  const { symbolPosition, symbolDeal, symbolOrder } = useSelector(
    (state) => state.filterDataScreen
  );
  const mode = useSelector((state) => state.mode.mode);
  const navigate = useNavigate();
  const handleChangeTab = useCallback(
    (tab) => () => dispatch(changeTab(tab)),
    [dispatch]
  );

  const renderHeaderContent = useMemo(() => {
    if (showComponent.wallet) {
      return (
        <>
          <HeaderButton
            icon={IoMdMenu}
            onClick={handleSideMenuBar}
            text="Trade"
          />
          <div className="flex items-center space-x-2">
            <IconToggle
              icon={RiArrowUpDownLine}
              onClick={toggleSortingDropDown}
              isOpen={isSortingOpen}
            >
              <Sorting />
            </IconToggle>
            <Link to="/trade">
              <MdOutlineNoteAdd size={24} />
            </Link>
          </div>
        </>
      );
    }
    if (showComponent.calender) {
      return (
        <HeaderButton
          icon={IoMdMenu}
          onClick={handleSideMenuBar}
          text="Calendar"
        />
      );
    }
    if (showComponent.account) {
      return (
        <div className="flex justify-between w-full items-center">
          <HeaderButton
            icon={IoMdMenu}
            onClick={handleSideMenuBar}
            text="Account"
          />
          <IoAdd size={24} onClick={() => navigate("/users")} />
        </div>
      );
    }
    if (showComponent.detail) {
      const symbolText =
        mode === "closedPosition"
          ? symbolPosition
          : mode === "deals"
          ? symbolDeal
          : symbolOrder;

      return (
        <>
          <div className="flex items-center space-x-2">
            <HeaderButton icon={IoMdMenu} onClick={handleSideMenuBar} />
            <div>
              <div className="text-sm font-medium">History</div>
              <div className="text-xs text-gray-600">{symbolText}</div>
            </div>
          </div>
          <div className="flex space-x-2">
            <IconToggle
              icon={RiExchangeDollarLine}
              onClick={toggleDropdown}
              isOpen={isDropdownOpen}
            >
              <FilterSymbol togglehandler={toggleDropdown} />
            </IconToggle>
            <IconToggle
              icon={RiArrowUpDownLine}
              onClick={toggleSortingDropDown}
              isOpen={isSortingOpen}
            >
              <Sorting />
            </IconToggle>
            <IconToggle
              icon={SlCalender}
              onClick={toggleSortingDateDropDown}
              isOpen={isDateSortingOpen}
            >
              <DateFilterSort
                handleClose={toggleSortingDateDropDown}
                isDateSortingOpen={isDateSortingOpen}
              />
            </IconToggle>
          </div>
        </>
      );
    }
    if (showComponent.watchlist) {
      return (
        <>
          <HeaderButton
            icon={IoMdMenu}
            onClick={handleSideMenuBar}
            text="Quotes"
          />
          <div className="flex space-x-2">
            <Link to="/search">
              <HeaderButton icon={AiOutlinePlus} />
            </Link>
            <Link to="/remove-symbols">
              <HeaderButton icon={MdOutlineModeEditOutline} />{" "}
            </Link>
          </div>
        </>
      );
    }
    if (showComponent.tradeForm) {
      return (
        <>
          <div className="flex items-center space-x-2">
            <HeaderButton
              icon={IoIosArrowRoundBack}
              onClick={handleChangeTab("wallet")}
            />
            <span className="text-sm font-medium">Trade Form</span>
          </div>
          <IconToggle
            icon={RiExchangeDollarLine}
            onClick={toggleDropdown}
            isOpen={isDropdownOpen}
          >
            <FilterSymbol togglehandler={toggleDropdown} />
          </IconToggle>
        </>
      );
    }

    if (showComponent.news) {
      return (
        <div className="flex items-center space-x-2">
          <HeaderButton
            icon={IoIosArrowRoundBack}
            onClick={handleChangeTab("watchlist")}
          />
          <span className="text-sm font-medium">News</span>
        </div>
      );
    }
    return null;
  }, [
    showComponent,
    handleSideMenuBar,
    toggleDropdown,
    toggleSortingDropDown,
    toggleSortingDateDropDown,
    isDropdownOpen,
    isSortingOpen,
    isDateSortingOpen,
    mode,
    symbolPosition,
    symbolDeal,
    symbolOrder,
    handleChangeTab,
    navigate,
  ]);

  return (
    <header className=" w-full bg-white z-10 shadow-sm">
      <div
        className={`flex justify-between items-center ${
          showComponent.chart ? "py-0" : "py-4"
        } px-4`}
      >
        {renderHeaderContent}
      </div>
    </header>
  );
};

export default React.memo(MobileHeader);
