import React, { useEffect } from "react";
import WalletInfo from "../../account/WalletInfo";
import PositionBox from "../../history/positions/PositionBox";
import { useDispatch, useSelector } from "react-redux";
import {
  filterSortPenPos,
  filterSortRunPen,
} from "../../../store/filterDataMobScreenSlice/FilterHistoryScreen";
import { useQueryClient } from "@tanstack/react-query";
import BulkOperations from "../../BulkOperations/BulkOperations";

const MobileTrade = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const bulkOperations = useSelector(
    (state) => state.mobileView.bulkOperations
  );
  const positions = queryClient.getQueryData(["positions"])?.positions || [];
  const rp = positions.filter((position) => position.status === 1);
  const pp = positions.filter((position) => position.status === 0);

  useEffect(() => {
    dispatch(filterSortRunPen(rp));
    dispatch(filterSortPenPos(pp));
  }, [dispatch, pp, rp]);

  return (
    <div className="">
      {bulkOperations && <BulkOperations />}
      <WalletInfo />
      <PositionBox />
    </div>
  );
};

export default MobileTrade;
