import React, { useEffect, useState, useCallback, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteUserPendingPosition } from "../../../api/queryFunctions";
import { changeMode } from "../../../store/modeSlice";
import { setShowBulkOperations } from "../../../store/mobileViewSlice";
import useLivePriceSocket from "../../../socket/livePriceSocket";
import socket from "../../../socket";
import toast, { Toaster } from "react-hot-toast";
import { BiEditAlt } from "react-icons/bi";
import { FaBackspace } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import { TbDotsVertical } from "react-icons/tb";
import styles from "../closedPositions/ExitPosition.module.css";
import {  mapTypeToString, formatTimeToSeconds } from "../../../helpers";
import ModifyPositionForm from "../../trade/modifyPosition/ModifyPositionForm";
import PPC from "../../trade/partialPositionClose/PPC";
import ModalPortal from "../../modal/Modal";

const PositionBox = React.memo(() => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { abcData } = useLivePriceSocket();
  const userId = queryClient.getQueryData(["user"])?.user?._id;

  const { sortingRp: rp, sortingPp: pp } = useSelector((state) => state.filterDataScreen);

  const [runningPositions, setRunningPositions] = useState(rp);
  const [pendingPositions, setPendingPositions] = useState(pp);
  const [showSubContainerIndex, setShowSubContainerIndex] = useState(null);
  const [showModifyPositionFormIndex, setShowModifyPositionFormIndex] = useState(null);
  const [modifyPositionData, setmodifyPositionData] = useState({
    positionId: null,
    symbol: null,
    type: null,
    ticket: null,
    price: null,
    stopLoss: null,
    takeProfit: null,
  });
  const [activeTab, setActiveTab] = useState("running");
  const [showPPC, setShowPPC] = useState(false);
  const itemRef = useRef(null);

  const { mutate: delPP } = useMutation({
    mutationKey: ["delpendingposition"],
    mutationFn: deleteUserPendingPosition,
    onSuccess: () => {
      queryClient.invalidateQueries(["positions", "orders"]);
      toast.success("Deleted");
    },
  });

  const handleOnClick = useCallback((index) => {
    setShowSubContainerIndex((prev) => (prev === index ? null : index));
  }, []);

  const handleModifyPosition = useCallback((index, item) => {
    setmodifyPositionData({
      positionId: item._id,
      symbol: item.symbol,
      type: mapTypeToString(item.type),
      ticket: item.ticket,
      price: item.price,
      stopLoss: item.stopLoss,
      takeProfit: item.takeProfit,
    });
    setShowModifyPositionFormIndex(index);
  }, []);

  const handleExit = useCallback((item) => {
    itemRef.current = item;
    setShowPPC(true);
    setShowSubContainerIndex(null);
  }, []);

  const handleDeletePendingPosition = useCallback((item) => {
    itemRef.current = item;
    delPP(item._id);
    setShowSubContainerIndex(null);
  }, [delPP]);

  useEffect(() => {
    setRunningPositions(rp);
    setPendingPositions(pp);
  }, [rp, pp]);

  useEffect(() => {
    if (userId) {
      socket.emit("userpositions", JSON.stringify({ userId }));
    }
  }, [userId]);

  useEffect(() => {
    const handlePosProfit = (updatedData) => {
      setRunningPositions((prevData) => 
        prevData?.map((data) => 
          data._id === updatedData.positionId
            ? {
                ...data,
                profit: updatedData.status === 1 ? updatedData.profit?.toFixed(2) : data.profit,
                currentPrice: updatedData.currentPrice,
              }
            : data
        )
      );
    };

    socket.on("posProfit", handlePosProfit);
    // socket.once("closePosition", () => {
    //   queryClient.invalidateQueries(["user"]);
    // });

    return () => {
      socket.off("posProfit", handlePosProfit);
      // socket.off("closePosition");
    };
  }, [queryClient]);

  useEffect(() => {
    setPendingPositions((prevData) =>
      prevData?.map((data) =>
        data.symbol === abcData?.newMessage.symbol
          ? {
              ...data,
              currentPrice: [1, 2, 4, 6].includes(data.type)
                ? abcData?.newMessage.bid
                : abcData?.newMessage.ask,
            }
          : data
      )
    );
  }, [abcData]);

  useEffect(() => {
    dispatch(changeMode(activeTab === "running" ? "runningPositions" : "pendingPositions"));
  }, [activeTab, dispatch]);

  const renderPositions = useMemo(() => {
    const positions = activeTab === "running" ? runningPositions : pendingPositions;
    return positions.map((item, index) => (
      <div className={styles.container} key={item._id}>
        <div
          className={styles.main}
          onClick={() => handleOnClick(index)}
          role="button"
          tabIndex={0}
          aria-expanded={showSubContainerIndex === index}
        >
          <div className={styles.flexBox}>
            <div className={styles.gap}>
              <span>{item.symbol}</span>
              <span className={item.type === 1 ? styles.rising : styles.falling}>
                {mapTypeToString(item.type)},
              </span>
              <span className={item.type === 1 ? styles.rising : styles.falling}>
                {item.volume}
              </span>
            </div>
            <div className={item.profit > 0 ? styles.rising : styles.falling}>
              {item.status === 1 ? Number(item?.profit).toFixed(2) : "Placed"}
            </div>
          </div>
          <div className={styles.flexBox}>
            <div className={styles.gap}>
              <span>{item.price}</span>
              <span>---&gt;</span>
              <span>{item.currentPrice || item.lastPrice}</span>
            </div>
          </div>
        </div>
        {showSubContainerIndex === index && (
          <div className={styles.subContainer}>
            <div className={styles.flexBox}>
              <div className={styles.gap}>
                <span>{formatTimeToSeconds(item.createdAt)}</span>
              </div>
            </div>
            <div className={styles.flexBox}>
              <div className={styles.gap}>
                <span>SL:</span>
                <span>{item.stopLoss}</span>
              </div>
              <div className={styles.gap}>
                <span>TP:</span>
                <span>{item.takeProfit}</span>
              </div>
            </div>
            <div className={styles.flexBox}>
              <div className={styles.gap}>
                <span>Swap:</span>
                <span>{activeTab === "running" ? item?.swap?.toFixed(2) : item.swap}</span>
              </div>
              <div className={styles.gap}>
                <span>Ticket:</span>
                <span>#{item.ticket}</span>
              </div>
            </div>
            <div className={styles.buttonCenter}>
              {activeTab === "running" ? (
                <>
                  <div className={styles.iconWithText}>
                    <FaBackspace color="red" size={18} />
                    <button
                      className={styles.closePosition}
                      onClick={() => handleExit(item)}
                    >
                      Close
                    </button>
                  </div>
                  <div className={styles.iconWithText}>
                    <button
                      className={`${styles.closePosition} ${styles.modifyPosition}`}
                      onClick={() => handleModifyPosition(index, item)}
                    >
                      Modify
                    </button>
                    <BiEditAlt color="green" size={18} />
                  </div>
                </>
              ) : (
                <>
                  <button
                    className={`${styles.closePosition} flex items-center justify-center hover:bg-gray-200`}
                    onClick={() => handleDeletePendingPosition(item)}
                  >
                    <MdDeleteOutline size={14} /> <span>Delete</span>
                  </button>
                  <button
                    className={`${styles.closePosition} ${styles.modifyPosition} hover:bg-gray-200`}
                    onClick={() => handleModifyPosition(index, item)}
                  >
                    Modify
                  </button>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    ));
  }, [activeTab, runningPositions, pendingPositions, showSubContainerIndex, handleOnClick, handleModifyPosition, handleExit, handleDeletePendingPosition]);

  return (
    <div className="relative">
      <div>
        <div className={`${styles.tabContainer} flex justify-center font-bold items-center py-2 w-full`}>
          <div className={styles.tabs}>
            <span
              className={activeTab === "running" ? styles.tabClicked : ""}
              onClick={() => setActiveTab("running")}
              role="button"
              tabIndex={0}
            >
              Running
            </span>
            <span
              className={activeTab === "pending" ? styles.tabClicked : ""}
              onClick={() => setActiveTab("pending")}
              role="button"
              tabIndex={0}
            >
              Pending
            </span>
          </div>
          <TbDotsVertical
            size={24}
            onClick={() => dispatch(setShowBulkOperations())}
            role="button"
            tabIndex={0}
            aria-label="Show bulk operations"
          />
        </div>
      </div>
      <div className={styles.scroll}>{renderPositions}</div>
      {showModifyPositionFormIndex !== null && (
        <ModalPortal>
          <div className={styles.modifyForm}>
            <div className={styles.centerDiv}>
              <ModifyPositionForm
                posData={modifyPositionData}
                closeHandler={() => setShowModifyPositionFormIndex(null)}
              />
            </div>
          </div>
        </ModalPortal>
      )}
      {showPPC && itemRef.current && (
        <ModalPortal>
          <div className={styles.modifyForm}>
            <div className={styles.centerDiv}>
              <PPC data={itemRef.current} onClose={() => setShowPPC(false)} />
            </div>
          </div>
        </ModalPortal>
      )}
      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          className: "",
          duration: 5000,
          style: {
            background: "#363636",
            color: "#fff",
          },
          success: {
            duration: 1000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />
    </div>
  );
});

export default PositionBox;