import socket from "../../socket";
import { refreshWallet } from "../../store/appSlice";
import { fetchInitialWalletInfo } from "../../store/userWalletAction";

export const fetchInitialData = async (dispatch) => {
  await Promise.all([dispatch(fetchInitialWalletInfo())]);
};

export const setupSocketListeners = (dispatch) => {
  socket.on("disconnect", () => {
    dispatch(fetchInitialWalletInfo());
    dispatch(refreshWallet());
  });

  return () => {
    socket.off("disconnect");
  };
};
