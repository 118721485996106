import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  crossHair: false,
  drawLine: false,
  channel: false,
  fibonacciR: false,
  manageDrawings: false,
  chartLocked: false,
  showObjects: true,
  drawRegressionChannel: false,
};

const chartSettings = createSlice({
  name: "chartSettings",
  initialState,
  reducers: {
    toggleCrossHair: (state, action) => {
      state.crossHair = !state.crossHair;
    },
    toggleDrawLine: (state, action) => {
      if (action.payload === true || action.payload === false) {
        state.drawLine = action.payload;
      } else {
        state.drawLine = !state.drawLine;
      }
    },
    toggleChannel: (state, action) => {
      if (action.payload === true || action.payload === false) {
        state.channel = action.payload;
      } else {
        state.channel = !state.channel;
      }
    },
    toggleDrawRegressionChannel: (state, action) => {
      if (action.payload === true || action.payload === false) {
        state.drawRegressionChannel = action.payload;
      } else {
        state.drawRegressionChannel = !state.drawRegressionChannel;
      }
    },
    toggleFibonacciR: (state, action) => {
      if (action.payload === true || action.payload === false) {
        state.fibonacciR = action.payload;
      } else {
        state.fibonacciR = !state.fibonacciR;
      }
    },
    toggleManageDrawings: (state) => {
      state.manageDrawings = !state.manageDrawings;
    },
    toggleChartLock: (state) => {
      state.chartLocked = !state.chartLocked;
    },

    toggleShowObjects: (state, action) => {
      if (action.payload === true || action.payload === false) {
        state.showObjects = action.payload;
      } else {
        state.showObjects = !state.showObjects;
      }
    },
    resetChartSettings: () => initialState,
  },
});

export const {
  toggleCrossHair,
  toggleDrawLine,
  toggleChannel,
  toggleFibonacciR,
  toggleManageDrawings,
  toggleChartLock,
  toggleDrawRegressionChannel,
  toggleShowObjects,
  resetChartSettings,
} = chartSettings.actions;

export default chartSettings.reducer;
