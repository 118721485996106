import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  lazy,
  Suspense,
} from "react";
import { widget } from "../../main/assets/charting_library";
import datafeed from "./Datafeed";
import { useDispatch, useSelector } from "react-redux";
import {
  changeShowTradeForm,
  toggleWatchList,
} from "../../store/watchlistSlice";
import { setShowInstantOrder } from "../../store/appSlice";
import { changeVisibility } from "../../store/modeSlice";

import { LocalStorageSaveLoadAdapter } from "./LocalStorageSaveLoadAdapter";
import { useQueryClient } from "@tanstack/react-query";
import { mapTypeToString } from "../../helpers";
import InstantOrderSkeleton from "../trade/instantOrder/InstantOrderSkeleton";

const TradeForm = lazy(() => import("../trade/tradeForm/TradeForm"));
const InstantOrder = lazy(() => import("../trade/instantOrder/InstantOrder"));

const TradingViewChart = () => {
  const queryClient = useQueryClient();
  const chartContainerRef = useRef(null);
  const widgetRef = useRef(null);
  const dispatch = useDispatch();
  const symbol = useSelector((state) => state.chart.symbol);
  const showTradeForm = useSelector((state) => state.watchlist.showTradeForm);
  const showInstantOrder = useSelector((state) => state.app.showInstantOrder);
  const [isChartReady, setIsChartReady] = useState(false);

  const addHorizontalLines = useCallback(
    (chart, currentSymbol) => {
      const positions = queryClient.getQueryData(["positions"])?.positions;
      if (!positions || !Array.isArray(positions)) return;

      const symbolPositions = positions.filter(
        (position) => position.symbol === currentSymbol
      );
      if (symbolPositions.length === 0) return;

      const colors = ["red", "blue", "green", "orange"];
      const visibleRange = chart.getVisibleRange();
      if (!visibleRange) return;

      symbolPositions.forEach((position, index) => {
        const color = colors[index % colors.length];
        const levels = [
          { price: position.price, label: mapTypeToString(position.type) },
          { price: position.stopLoss, label: "SL" },
          { price: position.takeProfit, label: "TP" },
        ];

        levels.forEach(({ price, label }) => {
          if (price !== undefined && price !== null) {
            chart.createShape(
              { time: visibleRange.from, price: price },
              {
                shape: "horizontal_line",
                lock: true,
                disableSelection: true,
                disableSave: true,
                disableUndo: true,
                overrides: {
                  linecolor: color,
                  linestyle: label === "SL" || label === "TP" ? 3 : 2,
                  linewidth: 1,
                  showLabel: true,
                  textcolor: color,
                  fontSize: 12,
                  text: `${label} ${price.toFixed(2)}`,
                },
                zOrder: "top",
              }
            );
          }
        });
      });
    },
    [queryClient]
  );

  const addCustomButtons = useCallback(() => {
    if (!widgetRef.current) return;

    const createButton = (title, icon, onClick) => {
      const button = widgetRef.current.createButton();
      button.setAttribute("title", title);
      button.classList.add("apply-common-tooltip");
      button.addEventListener("click", onClick);
      button.innerHTML = icon;
      return button;
    };

    createButton(
      "Trade Form",
      '<svg viewBox="0 0 24 24" width="24" height="24"><path fill="green" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z"></path></svg>',
      () => {
        dispatch(changeShowTradeForm());
        dispatch(setShowInstantOrder(false));
      }
    );

    createButton(
      "Instant Order",
      '<svg viewBox="0 0 24 24" width="24" height="24"><path fill="green" d="M17 12c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zm1.65 7.35L16.5 17.2V14h1v2.79l1.85 1.85-.7.71zM18 3h-3.18C14.4 1.84 13.3 1 12 1s-2.4.84-2.82 2H6c-1.1 0-2 .9-2 2v15c0 1.1.9 2 2 2h6.11c-.59-.57-1.07-1.25-1.42-2H6V5h2v3h8V5h2v5.08c.71.1 1.38.31 2 .6V5c0-1.1-.9-2-2-2zm-6 2c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z"></path></svg>',
      () => {
        dispatch(setShowInstantOrder());
        dispatch(changeShowTradeForm(false));
      }
    );

    if (window.innerWidth > 768) {
      createButton(
        "Toggle Watchlist",
        '<svg width="24" height="24" viewBox="0 0 24 24"><path fill="green" d="M15 7H7v2h8V7ZM7 15h5v2H7v-2ZM17 11H7v2h10v-2Z"></path><path fill="green" fillRule="evenodd" clipRule="evenodd" d="M19 3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14Zm-1 2a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h12Z"></path></svg>',
        () => dispatch(toggleWatchList())
      );

      createButton(
        "Toggle Detail",
        '<svg viewBox="0 0 24 24" width="24" height="24"><path fill="green" d="M9.29 15.88L13.17 12 9.29 8.12c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0l4.59 4.59c.39.39.39 1.02 0 1.41L10.7 17.3c-.39.39-1.02.39-1.41 0-.38-.39-.39-1.03 0-1.42z"></path></svg>',
        () => dispatch(changeVisibility())
      );
    }
  }, [dispatch]);

  useEffect(() => {
    if (chartContainerRef.current && !widgetRef.current) {
      const widgetOptions = {
        symbol: symbol || "BTCUSD",
        theme: "light",
        datafeed: datafeed,
        interval: "1",
        container: chartContainerRef.current,
        library_path: "/charting_library/",
        locale: "en",
        fullscreen: false,
        autosize: true,
        studies_overrides: {},
        disabled_features: [
          "header_compare",
          "header_fullscreen_button",
          "time_frames",
          "show_logo_on_all_charts",
          "caption_buttons_text_if_possible",
          "header_saveload",
          "timeframes_toolbar",
          "go_to_date",
        ],
        saved_data: JSON.parse(localStorage.getItem("chartState") || "null"),
        save_load_adapter: new LocalStorageSaveLoadAdapter(),
        enabled_features: [
          "study_templates",
          "saveload_separate_drawings_storage",
          "use_localstorage_for_settings",
        ],
        custom_formatters: {
          priceFormatterFactory: (symbolInfo) => ({
            format: (price) => {
              if (!symbolInfo) return null;
              return Number(price).toFixed(symbolInfo.digits);
            },
          }),
        },
      };

      widgetRef.current = new widget(widgetOptions);

      widgetRef.current.onChartReady(() => {
        setIsChartReady(true);
        addCustomButtons();

        const chart = widgetRef.current.activeChart();
        chart.setTimezone("Asia/Kolkata");

        addHorizontalLines(chart, symbol);

        const saveChartState = () => {
          widgetRef.current?.save((state) => {
            localStorage.setItem("chartState", JSON.stringify(state));
          });
        };

        chart.onDataLoaded().subscribe(null, saveChartState);
        chart.onIntervalChanged().subscribe(null, saveChartState);
        const intervalId = setInterval(saveChartState, 5000);

        return () => clearInterval(intervalId); // Cleanup interval on component unmount
      });
    }

    return () => {
      if (widgetRef.current) {
        widgetRef.current.remove();
        widgetRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (isChartReady && widgetRef.current && symbol) {
      const chart = widgetRef.current.chart();
      if (chart && typeof chart.setSymbol === "function") {
        chart.setSymbol(symbol, "1");
      }
    }
  }, [symbol, isChartReady]);

  return (
    <>
      <div
        ref={chartContainerRef}
        className="md:h-full md:pb-0 h-screen pb-16 w-full"
      />
      <div className="absolute top-24 md:top-16 md:left-1/3 left-0 z-50 w-full md:w-auto">
        <Suspense fallback={<InstantOrderSkeleton/>}>
          {showTradeForm && <TradeForm />}
          {showInstantOrder && <InstantOrder />}
        </Suspense>
      </div>
    </>
  );
};

export default TradingViewChart;
