import React from "react";

const ContextMenu = ({
  position,
  hideContextMenu,
  handleNewOrder,
  handleNewChart,
  handleSpecification,
  handleHide,
}) => {
  return (
    <div
      className="absolute bg-white border border-gray-300 rounded-md shadow-md z-10 transform -translate-x-1/2 -translate-y-1/2"
      style={{ top: `${position.y - 50}px`, left: "100px" }}
      onMouseLeave={hideContextMenu}
    >
      <p
        className="m-0 p-1 cursor-pointer whitespace-nowrap hover:bg-violet-700 hover:text-white"
        onClick={handleNewOrder}
      >
        New Order
      </p>
      <p
        className="m-0 p-1 cursor-pointer whitespace-nowrap hover:bg-violet-700 hover:text-white"
        onClick={handleNewChart}
      >
        Chart
      </p>
      <p
        className="m-0 p-1 cursor-pointer whitespace-nowrap hover:bg-violet-700 hover:text-white"
        onClick={handleSpecification}
      >
        Specification
      </p>
      <p
        className="m-0 p-1 cursor-pointer whitespace-nowrap hover:bg-violet-700 hover:text-white"
        onClick={handleHide}
      >
        Remove
      </p>
      <p className="m-0 p-1 cursor-pointer whitespace-nowrap hover:bg-violet-700 hover:text-white">
        Show Market Depth
      </p>
      <p className="m-0 p-1 cursor-pointer whitespace-nowrap hover:bg-violet-700 hover:text-white">
        Columns
      </p>
    </div>
  );
};

export default ContextMenu;
