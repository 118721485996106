import React, { useEffect } from "react";

const EconomicCalendar = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-events.js";
    script.async = true;
    script.innerHTML = JSON.stringify({
      colorTheme: "light",
      isTransparent: false,
      width: "100%",
      height: "100%",
      locale: "en",
      importanceFilter: "-1,0,1",
      countryFilter:
        "ar,au,br,ca,cn,fr,de,in,id,it,jp,kr,mx,ru,sa,za,tr,gb,us,eu",
    });
    document
      .getElementById("tradingview-widget-container__widget")
      .appendChild(script);
  }, []);

  return (
    <div
      style={{ height: "100vh", width: "100vw", overflow: "hidden" }}
    >
      <div
        className="tradingview-widget-container"
        style={{ height: "100%", width: "100%" }}
      >
        <div
          id="tradingview-widget-container__widget"
          style={{ height: "100%", width: "100%" }}
        ></div>
        <div className="tradingview-widget-copyright"></div>
      </div>
    </div>
  );
};

export default EconomicCalendar;
