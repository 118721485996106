import { createSlice } from "@reduxjs/toolkit";



const initialState = {
  symbol: localStorage.getItem("lastOpenedSymbol") || "AUDCAD",
  description:
    localStorage.getItem("lastOpenedDescription") ||
    "Australian Dollar vs Canadian Dollar",
  from: 0,
  to: 5,

  chartVisibleRange: { from: 0, to: 0 },
  withVolume: false,
  withTickVolume: false,
  type: "candlestick",
  tickVolume: false,
  showGrids: true,
  time: 1,
  ohlcData: [],
  volumeData: [],
  screenShot: false,
  fullWidth: false,
  refetch: false,
  tooltip: false,
  markers: [],
  bidAsk: {
    ask: "",
    bid: "",
  }
};

const chartSlice = createSlice({
  name: "chart",
  initialState: initialState,
  reducers: {
    addMarker: (state, action) => {
      state.markers = [...state.markers, action.payload].sort(
        (a, b) => a.time - b.time
      );
    },
    setRefetchToTrue: (state) => {
      state.refetch = true;
    },

    setChartVisibleRange: (state, action) => {
      const { from, to } = action.payload;
      state.chartVisibleRange = { from: from, to: to };
    },
    changeTo: (state, action) => {
      state.to = action.payload;
    },
    changeFrom: (state, action) => {
      state.from = action.payload;
    },
    toggleShowGrids: (state) => {
      state.showGrids = !state.showGrids;
    },

    changeChartType: (state, action) => {
      state.type = action.payload;
    },

    toggleWithVolume: (state, action) => {
      state.withVolume = action.payload;
    },
    toggleTickVolume: (state, action) => {
      state.tickVolume = action.payload;
    },

    changeSymbol: (state, action) => {
      const newSymbol = action.payload.toUpperCase();
      state.symbol = newSymbol;
      localStorage.setItem("lastOpenedSymbol", newSymbol);
    },

    changeTime: (state, action) => {
      state.time = Number(action.payload);
    },

    changeGetDataParams: (state, action) => {
      const newData = action.payload;
      state.symbol = newData?.symbol;
      state.from = newData?.from || state.from;
      state.to = newData?.to || state.to;
      state.time = newData?.time || state.time;
    },
    changeDescription: (state, action) => {
      const newDescription = action.payload;
      state.description = newDescription;
      localStorage.setItem("lastOpenedDescription", newDescription);
    },
    updateOhlcData: (state, action) => {
      state.ohlcData.push(action.payload);
    },

    updateVolumeData: (state, action) => {
      state.volumeData.push(action.payload);
    },

    toggleScreenShot: (state) => {
      state.screenShot = !state.screenShot;
    },
    setFullWidth: (state) => {
      state.fullWidth = !state.fullWidth;
    },

    setBidAsk: (state, action) => {
      state.bidAsk = action.payload;
    },

    resetChart: (state) => {
      return initialState;
    },
  },

  
});

export const {
  toggleShowGrids,
  toggleWithVolume,
  changeGetDataParams,
  changeChartType,
  changeSymbol,
  toggleTickVolume,
  changeTime,
  changeDescription,
  updateOhlcData,
  updateVolumeData,
  toggleScreenShot,
  setFullWidth,
  changeTo,
  changeFrom,
  setChartVisibleRange,
  setRefetchToTrue,
  setCurrentPage,
  setLimit,
  addMarker,
  setBidAsk
} = chartSlice.actions;

export default chartSlice.reducer;
