export class LocalStorageSaveLoadAdapter {
  constructor() {
    this._charts =
      this._getFromLocalStorage("LocalStorageSaveLoadAdapter_charts") || [];
    this._studyTemplates =
      this._getFromLocalStorage("LocalStorageSaveLoadAdapter_studyTemplates") ||
      [];
    this._drawingTemplates =
      this._getFromLocalStorage(
        "LocalStorageSaveLoadAdapter_drawingTemplates"
      ) || [];
    this._chartTemplates =
      this._getFromLocalStorage("LocalStorageSaveLoadAdapter_chartTemplates") ||
      [];
    this._drawings =
      this._getFromLocalStorage("LocalStorageSaveLoadAdapter_drawings") || {};
    this._isDirty = false;

    setInterval(() => {
      if (this._isDirty) {
        this._saveAllToLocalStorage();
        this._isDirty = false;
      }
    }, 1000);
  }

  getAllCharts() {
    return Promise.resolve(this._charts);
  }

  removeChart(id) {
    const index = this._charts.findIndex((chart) => chart.id === id);
    if (index !== -1) {
      this._charts.splice(index, 1);
      this._isDirty = true;
      return Promise.resolve();
    }
    return Promise.reject(new Error("The chart does not exist"));
  }

  saveChart(chartData) {
    if (!chartData.id) {
      chartData.id = this._generateUniqueChartId();
    } else {
      this.removeChart(chartData.id);
    }
    const savedChartData = {
      ...chartData,
      id: chartData.id,
      timestamp: Math.round(Date.now() / 1000),
    };
    this._charts.push(savedChartData);
    this._isDirty = true;
    return Promise.resolve(savedChartData.id);
  }

  getChartContent(id) {
    const chart = this._charts.find((chart) => chart.id === id);
    if (chart) {
      return Promise.resolve(chart.content);
    }
    return Promise.reject(new Error("The chart does not exist"));
  }

  removeStudyTemplate(studyTemplateData) {
    const index = this._studyTemplates.findIndex(
      (template) => template.name === studyTemplateData.name
    );
    if (index !== -1) {
      this._studyTemplates.splice(index, 1);
      this._isDirty = true;
      return Promise.resolve();
    }
    return Promise.reject(new Error("The study template does not exist"));
  }

  getStudyTemplateContent(studyTemplateData) {
    const template = this._studyTemplates.find(
      (template) => template.name === studyTemplateData.name
    );
    if (template) {
      return Promise.resolve(template.content);
    }
    return Promise.reject(new Error("The study template does not exist"));
  }

  saveStudyTemplate(studyTemplateData) {
    const index = this._studyTemplates.findIndex(
      (template) => template.name === studyTemplateData.name
    );
    if (index !== -1) {
      this._studyTemplates.splice(index, 1);
    }
    this._studyTemplates.push(studyTemplateData);
    this._isDirty = true;
    return Promise.resolve();
  }

  getAllStudyTemplates() {
    return Promise.resolve(this._studyTemplates);
  }

  removeDrawingTemplate(toolName, templateName) {
    const index = this._drawingTemplates.findIndex(
      (template) =>
        template.name === templateName && template.toolName === toolName
    );
    if (index !== -1) {
      this._drawingTemplates.splice(index, 1);
      this._isDirty = true;
      return Promise.resolve();
    }
    return Promise.reject(new Error("The drawing template does not exist"));
  }

  loadDrawingTemplate(toolName, templateName) {
    const template = this._drawingTemplates.find(
      (template) =>
        template.name === templateName && template.toolName === toolName
    );
    if (template) {
      return Promise.resolve(template.content);
    }
    return Promise.reject(new Error("The drawing template does not exist"));
  }

  saveDrawingTemplate(toolName, templateName, content) {
    const index = this._drawingTemplates.findIndex(
      (template) =>
        template.name === templateName && template.toolName === toolName
    );
    if (index !== -1) {
      this._drawingTemplates.splice(index, 1);
    }
    this._drawingTemplates.push({ name: templateName, content, toolName });
    this._isDirty = true;
    return Promise.resolve();
  }

  getDrawingTemplates() {
    return Promise.resolve(
      this._drawingTemplates.map((template) => template.name)
    );
  }

  async getAllChartTemplates() {
    return this._chartTemplates.map((template) => template.name);
  }

  async saveChartTemplate(templateName, content) {
    const template = this._chartTemplates.find((t) => t.name === templateName);
    if (template) {
      template.content = content;
    } else {
      this._chartTemplates.push({ name: templateName, content });
    }
    this._isDirty = true;
  }

  async removeChartTemplate(templateName) {
    this._chartTemplates = this._chartTemplates.filter(
      (t) => t.name !== templateName
    );
    this._isDirty = true;
  }

  async getChartTemplateContent(templateName) {
    const template = this._chartTemplates.find((t) => t.name === templateName);
    return {
      content: JSON.parse(JSON.stringify(template?.content)),
    };
  }

  async saveLineToolsAndGroups(layoutId, chartId, state) {
    // console.log("saveLineToolsAndGroups called", { layoutId, chartId, state });

    const drawings = state.sources;
    // if (!drawings) return;

    const key = this._getDrawingKey(layoutId, chartId);
    // console.log("Saving with key:", key);

    if (!this._drawings[key]) {
      this._drawings[key] = {};
    }
    // console.log("State sources:", state.sources);

    for (let [key, state] of drawings) {
      if (state === null) {
        delete this._drawings[key][key];
      } else {
        this._drawings[key][key] = state;
      }
    }

    // console.log("Full state:", JSON.stringify(state, null, 2));

    this._isDirty = true;
  }

  async loadLineToolsAndGroups(
    layoutId,
    chartId,
    _requestType,
    _requestContext
  ) {
    // console.log("loadLineToolsAndGroups called", { layoutId, chartId });
    // console.log("All drawings:", this._drawings);

    if (!layoutId) return null;
    const key = this._getDrawingKey(layoutId, chartId);
    const rawSources = this._drawings[key];
    // console.log("rawSources:", rawSources);

    if (!rawSources) return null;
    const sources = new Map(Object.entries(rawSources));
    // console.log("Returning sources:", sources);

    return { sources };
  }

  _generateUniqueChartId() {
    const existingIds = this._charts.map((i) => i.id);
    while (true) {
      const uid = Math.random().toString(16).slice(2);
      if (!existingIds.includes(uid)) {
        return uid;
      }
    }
  }

  _getDrawingKey(layoutId, chartId) {
    return `${layoutId}/${chartId}`;
  }

  _getFromLocalStorage(key) {
    const value = localStorage.getItem(key);
    return value ? JSON.parse(value) : null;
  }

  _saveAllToLocalStorage() {
    localStorage.setItem(
      "LocalStorageSaveLoadAdapter_charts",
      JSON.stringify(this._charts)
    );
    localStorage.setItem(
      "LocalStorageSaveLoadAdapter_studyTemplates",
      JSON.stringify(this._studyTemplates)
    );
    localStorage.setItem(
      "LocalStorageSaveLoadAdapter_drawingTemplates",
      JSON.stringify(this._drawingTemplates)
    );
    localStorage.setItem(
      "LocalStorageSaveLoadAdapter_chartTemplates",
      JSON.stringify(this._chartTemplates)
    );
    localStorage.setItem(
      "LocalStorageSaveLoadAdapter_drawings",
      JSON.stringify(this._drawings)
    );
  }
}
