import React, { useCallback, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  getUserCredentials,
  removeAuthToken,
  removeUserCredential,
  setAuthToken,
  setUserChangeTo,
  setUserCredentials,
} from "../../helpers";
import { useNavigate } from "react-router-dom";
import socket from "../../socket";
import { resetMobileViewState } from "../../store/mobileViewSlice";
import { resetSymbols } from "../../store/symbolsSlice";
import { resetUserState } from "../../store/userSlice";
import { resetWatchlistState } from "../../store/watchlistSlice";
import { resetOnLoadState } from "../../store/onLoadSlice";
import { resetMode } from "../../store/modeSlice";
import { resetApp } from "../../store/appSlice";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IoEllipsisVertical } from "react-icons/io5";
import { postLogin } from "../../api/queryFunctions";

const AccountBadge = ({ name, small = false, type }) => (
  <div
    className={`absolute top-2 left-2 text-xs font-bold py-1 px-2 rounded-full text-white ${
     ( type == 0 || name === "DEMO USER" ) ? "bg-yellow-500" : "bg-blue-500"
    } ${small ? "text-[10px] py-0.5 px-1.5" : ""}`}
  >
    {(type == 0 ||name === "DEMO USER"  )? "DEMO" : "LIVE"}
  </div>
);

const ConfirmationDialog = ({ isOpen, onClose, onConfirm, userId }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-xl">
        <h2 className="text-xl font-bold mb-4">Confirm Removal</h2>
        <p className="mb-6">
          Are you sure you want to remove the account for {userId}?
        </p>
        <div className="flex justify-end space-x-4">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
          >
            Remove
          </button>
        </div>
      </div>
    </div>
  );
};

const UserCard = ({ user, handleLogIn, handleRemove }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const onRemove = () => {
    setShowConfirmation(true);
    setShowMenu(false);
  };

  const onConfirmRemove = () => {
    handleRemove(user.userId);
    setShowConfirmation(false);
  };

  return (
    <div className="relative p-4 w-full flex items-center justify-between text-black border border-gray-200 rounded-lg bg-white shadow-sm hover:shadow-md transition-shadow duration-300">
      <AccountBadge name={user.name} type={user.type} small />
      <div className="flex items-center space-x-4">
        <img
          className="rounded-full w-12 h-12 object-cover"
          src="https://cdn.vectorstock.com/i/1000v/08/19/gray-photo-placeholder-icon-design-ui-vector-35850819.avif"
          alt="user"
        />
        <div className="text-gray-800">
          <div className="text-sm font-semibold">{user.userId}</div>
          <div className="text-xs text-gray-500">{user.name}</div>
        </div>
      </div>
      <button
        className="outline-none py-1 px-3 text-sm text-white bg-green-600 rounded-md font-medium transition duration-300 ease-in-out hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
        onClick={() => handleLogIn(user.userId, user.password, user.name, user.type)}
      >
        Login
      </button>
      <div className="relative">
        <button
          onClick={() => setShowMenu(!showMenu)}
          className="p-1 rounded-full hover:bg-gray-200 transition-colors duration-200"
        >
          <IoEllipsisVertical className="text-gray-600" />
        </button>
        {showMenu && (
          <div className="absolute right-0 mb-10 py-2 w-48 bg-white rounded-md shadow-xl z-20">
            <button
              onClick={onRemove}
              className="block px-4 py-2 text-sm text-red-600 hover:bg-gray-100 w-full text-left"
            >
              Remove Account
            </button>
          </div>
        )}
      </div>
      <ConfirmationDialog
        isOpen={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        onConfirm={onConfirmRemove}
        userId={user.userId}
      />
    </div>
  );
};


const Account = () => {
  const queryClient = useQueryClient();
  const [users, setUsers] = useState([]);

  const { email = "", name = "", type = "" } =
    queryClient.getQueryData(["user"])?.user ?? {};
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    mutate: login,
    data: res,
    isLoading,
  } = useMutation({
    mutationKey: "login",
    mutationFn: (data) => postLogin(data),
    onSettled: (data) => {
      if (data) {
        setAuthToken(data?.data?.token);
      }
    },
  });

  useEffect(() => {
    const fetchUsers = () => {
      const allUsers = getUserCredentials() || [];
      setUsers(allUsers.filter((user) => user.userId !== email));
    };

    fetchUsers();
  }, [email]);

  const runOnce = useCallback(async () => {
    dispatch(resetApp());
    dispatch(resetOnLoadState());
    dispatch(resetMobileViewState());
    dispatch(resetMode());
    dispatch(resetWatchlistState());
    dispatch(resetSymbols());
    dispatch(resetUserState());
    removeAuthToken("AUTH-TOKEN");
  }, [dispatch]);

  const handleLogOut = useCallback(async () => {
    await runOnce();
    
    socket.disconnect();

    navigate("/users");
   
  }, [runOnce, navigate]);

  const handleLogIn = useCallback(
    (userId, password, name, type) => {
      runOnce();
      setUserCredentials({ userId: userId, password: password, name: name });
      setUserChangeTo({
        userId: userId,
        password: password,
        name: name,
        type: type,
      });
      if (userId?.trim()?.length > 0 && password?.trim()?.length > 0) {
        login({ userId, password });
      } else {
        console.log("Please enter valid credentials");
      }
    },
    [runOnce, login]
  );

  const handleRemove = (userId) => {
    removeUserCredential(userId);
    setUsers(users.filter((user) => user.userId !== userId));
  };

  useEffect(() => {
    const queryKeys = [
      "user",
      "positions",
      "closedPositions",
      "orders",
      "deals",
    ];
    if (res?.data?.token) {
      setAuthToken(res.data.token);
      queryClient.invalidateQueries(queryKeys);
      navigate("/");
    }
  }, [navigate, res, queryClient]);

  return (
    <div className="bg-gray-100 p-6">
      <div className="max-w-4xl mx-auto mb-12 md:mb-0">
        <div className="flex flex-col md:flex-row gap-6">
          <div className="relative p-6 flex flex-col items-center text-black bg-white rounded-lg shadow-sm md:w-48">
            <AccountBadge name={name} type={type} />
            <img
              className="rounded-full w-24 h-24 object-cover mb-4"
              src="https://cdn.vectorstock.com/i/1000v/08/19/gray-photo-placeholder-icon-design-ui-vector-35850819.avif"
              alt="user"
            />
            <div className="text-base font-semibold text-gray-800">{name}</div>
            <div className="text-sm text-gray-600 mb-4">{email}</div>
            <button
              className="w-full outline-none py-2 px-4 text-white bg-red-600 rounded-md font-medium transition duration-300 ease-in-out hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
              onClick={handleLogOut}
            >
              Logout
            </button>
          </div>
          {users.length > 0 && (
            <div className="flex flex-col gap-4  md:h-96 md:w-96 md:overflow-y-auto">
              <h2 className="text-xl font-semibold text-gray-800 sticky top-0 bg-gray-100 p-2 z-10 rounded-md">
                Other Accounts
              </h2>
              {users.map((user, index) => (
                <UserCard
                  key={index}
                  user={user}
                  handleLogIn={handleLogIn}
                  handleRemove={handleRemove}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Account;
