

import React, { useEffect, useState, useCallback, useMemo } from "react"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { postPartaillyClosePosition } from "../../../api/queryFunctions"
import {  mapTypeToString } from "../../../helpers"
import socket from "../../../socket"
import  toast from "react-hot-toast"

import { IoArrowDown, IoClose } from "react-icons/io5"

const InfoItem = React.memo(({ label, value, classes }) => (
  <div className="flex flex-col">
    <span className="text-xs text-gray-500 mb-1">{label}</span>
    <p className={`${classes} font-semibold text-gray-800`}>{value}</p>
  </div>
))

const PPC = ({ data, onClose }) => {
 
  const queryClient = useQueryClient()
  const [inputValue, setInputValue] = useState(data?.volume)
  const [profit, setProfit] = useState(data?.profit)
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const id = queryClient.getQueryData(["user"])

  const { mutate: closePositionPartially } = useMutation({
    mutationKey: ["PCC"],
    mutationFn: postPartaillyClosePosition,
    onSuccess: () => {
      onClose()
    },
    onSettled: (data) => {
      
      if (data.data?.message === "sucess") {
        toast.success("Position closed successfully")
        queryClient.invalidateQueries({ queryKey: ["user"] })
        queryClient.setQueryData(["positions"], (oldData) => {
          const newPosition = data?.data?.data?.newPosition
          const closedPositionId = data?.data?.data?.exitPosition?._id
        
          if (newPosition) {
            return {
              ...oldData,
              positions: oldData.positions.map((p) =>
                p._id === newPosition._id ? newPosition : p
              )
            }
          } else if (closedPositionId) {
            return {
              ...oldData,
              positions: oldData.positions.filter((p) => p._id !== closedPositionId)
            }
          }
        
          return oldData
        })
        queryClient.setQueryData(["orders"], (oldData) => [
          ...oldData,
          data?.data?.data?.newOrder,
        ])
        queryClient.setQueryData(["deals"], (oldData) => [
          ...oldData,
          data?.data?.data?.newDeal,
        ])
        queryClient.setQueryData(["closedPositions"], (oldData) => [
          ...oldData,
          data?.data?.data?.exitPosition,
        ])
      } else {
        toast.error(data.data?.message)

      }
      setIsButtonLoading(false)
    },
  })

  const handleInputChange = useCallback((e) => {
    setInputValue(Number(e.target.value))
  }, [])

  const isButtonDisabled = useMemo(() => {
    return inputValue > data?.volume || inputValue === 0
  }, [inputValue, data?.volume])

  const handleClosePosition = useCallback(() => {
    setIsButtonLoading(true)
    closePositionPartially({
      positionId: data.id || data._id,
      volume2Close: inputValue,
    })
  }, [closePositionPartially, data.id, data._id, inputValue])

  useEffect(() => {
    if (id) {
      socket.emit("userpositions", JSON.stringify({ userId: id._id }))
    }
    const handlePosProfit = (newData) => {
      if (
        (newData.positionId === data.id || newData.positionId === data._id) &&
        inputValue
      ) {
        setProfit(newData?.profit?.toFixed(2))
      }
    }

    socket.on("posProfit", handlePosProfit)

    return () => {
      socket.off("posProfit", handlePosProfit)
    }
  }, [id, data.id, data._id, inputValue])


  const calculatedProfit = useMemo(() => {
    if (profit && inputValue) {
      return (Number(profit) * (inputValue / data?.volume)).toFixed(2)
    }
    return " "
  }, [profit, inputValue, data?.volume])

  return (
    <div className="w-full max-w-md mx-auto md:w-96 bg-white rounded-lg border-2 border-gray-200 overflow-hidden">
      <div className="bg-white rounded-2xl shadow-2xl w-full max-w-md overflow-hidden">
        <div className="bg-gray-50 px-6 py-4 flex justify-between items-center border-b border-gray-200">
         <div className="flex items-center justify-between w-full">
         <h2 className="text-xl font-bold text-gray-800">Close Position</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700 transition-colors">
            <IoClose size={24} />
          </button>
         </div>
        </div>
        <div className="p-2">
          <div className="bg-white border border-gray-200 rounded-xl shadow-sm mb-6">
            <div className="flex justify-between items-center p-4 border-b border-gray-200">
              <span className="text-lg font-semibold text-gray-800">{data?.symbol}</span>
              <button className="p-2 rounded-full bg-gray-100 hover:bg-gray-200 transition-colors">
                <IoArrowDown size={16} className="text-gray-600" />
              </button>
            </div>
            <div className="grid grid-cols-2 gap-6 p-4">
              <InfoItem
                label="Type"
                value={
                  !isNaN(Number(data?.type)) ? mapTypeToString(Number(data?.type)) : String(data?.type)
                }
              />
              <InfoItem label="Price" value={data?.price.toFixed(2)} />
              <InfoItem label="Volume" value={data?.volume.toFixed(3)} />
              <InfoItem
                label="Profit"
                value={`$${calculatedProfit}`}
                classes={calculatedProfit >= 0 ? "text-green-500" : "text-red-500"}
              />
            </div>
          </div>
          <div className="mb-6">
            <label htmlFor="volume" className="block text-sm font-medium text-gray-700 mb-2">
              Volume to Close
            </label>
            <input
              id="volume"
              type="number"
              value={inputValue}
              onChange={handleInputChange}
              step="0.001"
              min="0.001"
              max={data?.volume}
              className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-shadow outline-none"
            />
          </div>
          <div className="flex flex-col space-y-3 mb-2 ">
            <button
              className={`w-full py-2 px-2 rounded-lg text-white md:text-sm font-semibold transition-colors ${
                isButtonDisabled || isButtonLoading
                  ? 'bg-gray-400 cursor-not-allowed'
                  : 'bg-blue-600 hover:bg-blue-700 active:bg-blue-800'
              }`}
              disabled={isButtonDisabled || isButtonLoading}
              onClick={handleClosePosition}
            >
              {isButtonLoading ?  "Closing..." : "Close Position"}
            </button>
            <button
              className="w-full py-1 px-1 rounded-lg border border-gray-300 text-gray-700 font-semibold hover:bg-gray-50 active:bg-gray-100 transition-colors "
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
     
      
    
      
    </div>
  )
}

export default React.memo(PPC)
